import { useCallback } from 'react';
import { generateId } from 'utils/generateId';
import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { EditableJournal } from 'models/Accounting/JournalEditorModel';

const defaultJournal: EditableJournal = {
  id: 'Default-ID',
  isNew: true,
  mode: GridRowModes.Edit,
  signedAmount: 0,
  absoluteAmount: 0,
  creditOrDebit: '',
  description: '',
  notes: '',
  eedDate: new Date(),
};

const JournalGroupEditorToolbar = (props: any) => {
  const { setRows, setRowModesModel, eedDate } = props;

  const handleClick = useCallback(
    (creditOrDebit: string) => () => {
      const id = generateId();
      setRows((oldRows) => [
        ...oldRows,
        { ...defaultJournal, id, creditOrDebit, eedDate, isNew: true },
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' },
      }));
    },
    [eedDate, setRowModesModel, setRows]
  );

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick('credit')}>
        Add Credit
      </Button>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick('debit')}>
        Add Debit
      </Button>
    </GridToolbarContainer>
  );
};

export default JournalGroupEditorToolbar;
