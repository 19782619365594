import React, { ReactNode, useCallback, useMemo, useState } from 'react';
// services
import BookmarksService from 'services/BookmarksService';
// models
import BookmarkContextType from 'models/Bookmark/BookmarkContextType';
import Bookmark from 'models/Bookmark/Bookmark';

export const BookmarkContext = React.createContext<BookmarkContextType | null>(null);
const bookmarkService = BookmarksService();

export const BookmarkProvider = ({ children }: { children: ReactNode }) => {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(bookmarkService.getBookmarks());

  const writeBookmark = useCallback((id: string, applicationId: string, companyName: string) => {
    bookmarkService.writeBookmark(id, applicationId, companyName);
    setBookmarks(bookmarkService.getBookmarks());
  }, []);

  const removeBookmark = useCallback((id: string) => {
    bookmarkService.removeBookmark(id);
    setBookmarks(bookmarkService.getBookmarks());
  }, []);

  const clearBookmarks = useCallback(() => {
    bookmarkService.clearBookmarks();
    setBookmarks(bookmarkService.getBookmarks());
  }, []);

  const contextValues = useMemo(
    () => ({
      bookmarks,
      writeBookmark,
      removeBookmark,
      clearBookmarks,
    }),
    [bookmarks, writeBookmark, removeBookmark, clearBookmarks]
  );
  return <BookmarkContext.Provider value={contextValues}>{children}</BookmarkContext.Provider>;
};
