import fmsServices from 'services/fmsServices';
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import JournalSearch from 'models/Accounting/JournalSearch';
import Journal from 'models/Accounting/Journal';
import JournalType from 'models/Accounting/JournalType';
import JournalEntityType from 'models/Accounting/JournalEntityType';
import JournalGroupUpsertRequest from 'models/Accounting/JournalGroupUpsertRequest';
import JournalTypeCodeType from 'models/Accounting/JournalTypeCodeType';

/** 
This service provides data for "Journals" which are accounting entries to characterize
the underlying flow of money in the transactions resulting from funding
*/
const JournalService = () => {
  const search = async (search: JournalSearch) => {
    const response = await fmsServices().post<JournalSearch, ApiResponsePaged<Journal[]>>(
      `/journals/search`,
      search
    );
    return response;
  };

  const report = async (search: JournalSearch, report: string) => {
    const response = await fmsServices().post<JournalSearch, ApiResponsePaged<any[]>>(
      `/journals/reports?report=${report}`,
      search
    );
    return response;
  };

  const getEntityTypes = async () => {
    const journalEntityTypes: JournalEntityType[] = [
      {
        entityTypeName: 'Company',
        entityTypeValue: 'company',
      },
      {
        entityTypeName: 'Funder',
        entityTypeValue: 'funder',
      },
      {
        entityTypeName: 'Participant',
        entityTypeValue: 'participant',
      },
      {
        entityTypeName: 'Investment Company',
        entityTypeValue: 'investment-company',
      },
    ];
    return journalEntityTypes;
  };
  const getJournalTypes = async () => {
    const response = await fmsServices().post<any, ApiResponse>(`/journals/types`);
    return response;
  };

  const upsertJournalType = async (data: JournalType) =>
    fmsServices().post<JournalType, ApiResponse>(`/journals/types/upsert`, data);

  const getJournalActionGroups = async () =>
    fmsServices().post<any, ApiResponse>(`/journals/action-groups`);

  const upsert = async (data: Journal) => {
    const response = await fmsServices().post<Journal, ApiResponse>(`/journals/upsert`, data);
    return response;
  };

  const getJournalGroup = async (journalGroupId: string) =>
    fmsServices().post<any, ApiResponse>(`/journals/group`, { journalGroupId });

  const upsertJournalGroupWithJournals = async (data: JournalGroupUpsertRequest) => {
    const response = await fmsServices().post<JournalGroupUpsertRequest, ApiResponse>(
      `/journals/group/upsert`,
      data
    );
    return response;
  };

  const deleteJournalGroup = async (journalGroupId: string) =>
    fmsServices().post<any, ApiResponse>(`/journals/group/delete`, { journalGroupId });

  const getJournalTypeCodes = async (): Promise<ApiResponse<JournalTypeCodeType[]>> => {
    const response = await fmsServices().get<null, ApiResponse<JournalTypeCodeType[]>>(
      '/journals/search/type-codes'
    );

    return response;
  };

  return {
    search,
    report,
    getEntityTypes,
    getJournalTypes,
    getJournalActionGroups,
    upsert,
    getJournalGroup,
    upsertJournalGroupWithJournals,
    deleteJournalGroup,
    upsertJournalType,
    getJournalTypeCodes,
  };
};

export default JournalService;
