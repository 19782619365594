import React from 'react';
import Dialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { appPrimaryDark } from 'styling/theming';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogProps extends MUIDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  actions?: any;
  children: any;
  width?: number;
  backdropProps?: boolean;
  noPadding?: boolean;
}

const Modal = (props: DialogProps) => {
  const {
    children,
    onClose,
    open,
    title,
    actions,
    width,
    backdropProps,
    noPadding,
    ...dialogProps
  } = props;
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{ invisible: backdropProps ? true : false }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: `${width}px! important`,
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" sx={{ color: appPrimaryDark }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton title="Close this modal dialog" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box />
      </DialogTitle>
      <DialogContent dividers sx={{ padding: noPadding ? 0 : null }}>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
