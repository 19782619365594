import { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
// components shared
import Modal from 'components/Shared/Modal';

const createPromise = (): [Promise<boolean>, (value: boolean | PromiseLike<boolean>) => void] => {
  let resolver: any;
  return [
    new Promise<boolean>((resolve, reject) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

const useConfirm = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [resolver, setResolver] = useState<any>({ resolver: null });
  const [label, setLabel] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const getConfirmation = useCallback(async (title: string, text: string) => {
    setLabel(text);
    setTitle(title);
    setOpen(true);
    const [promise, resolve] = await createPromise();
    setResolver({ resolve });
    return promise;
  }, []);

  const onClick = async (status: boolean) => {
    setOpen(false);
    resolver.resolve(status);
  };

  const ConfirmationModal = () => (
    <Modal
      open={open}
      onClose={() => onClick(false)}
      title={title}
      key="confirm-modal"
      actions={
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            container
            columnGap={2}
            my={1}
          >
            <Button color="primary" variant="contained" onClick={() => onClick(true)}>
              Confirm
            </Button>
            <Button variant="outlined" type="reset" onClick={() => onClick(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="tight" alignItems="center" my={1}>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: label }} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
  return { getConfirmation, ConfirmationModal };
};

export default useConfirm;
