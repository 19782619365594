import { Typography, Skeleton } from '@mui/material';
import { appPrimaryDark } from 'styling/theming';

const PageTitle = ({ title, sx }: { title?: string; sx?: any }): JSX.Element =>
  title ? (
    <Typography variant="h4" noWrap={true} sx={{ mt: 0, ...sx }} color={appPrimaryDark}>
      {title}
    </Typography>
  ) : (
    <Skeleton width={300} height={35} />
  );

export default PageTitle;
