import PersonAddress from 'models/Person/PersonAddress';
import CompanyAddress from 'models/Company/CompanyAddress';

export const PHYSICAL_ADDRESS_TYPE_ID = '631fbf15-d188-410a-b436-5dcca99175f9';
export const MAILING_ADDRESS_TYPE_PHYSICAL = 'physical';
export const MAILING_ADDRESS_TYPE_ID = 'bff90f50-fd71-48e9-9950-2350536ea7a6';
export const MAILING_ADDRESS_TYPE_MAIL = 'mailing';

/**
This service provides data for "Addresses" and is a light weight service 
that manages the types of addresses a given Company or Person or Funder have.
In practice this is either a 'physical' or 'mailing' address in a city.
*/
const AddressesService = () => {
  const initAddress = (addressType: string, personId?: string, companyId?: string) => {
    if (personId) {
      return initPersonAddress(addressType, personId);
    } else if (companyId) {
      return initCompanyAddress(addressType, companyId);
    } else {
      throw new Error('No person ID or company ID provided');
    }
  };
  const initPersonAddress = (addressType: string, personId?: string) => {
    let addressTypeId = PHYSICAL_ADDRESS_TYPE_ID; // default to physical
    if (addressType === MAILING_ADDRESS_TYPE_MAIL) {
      addressTypeId = MAILING_ADDRESS_TYPE_ID;
    }

    const address = {
      personId,
      addressTypeId: addressTypeId,
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    };
    return address as PersonAddress;
  };

  const initCompanyAddress = (addressType: string, companyId: string) => {
    let addressTypeId = PHYSICAL_ADDRESS_TYPE_ID; // default to physical
    if (addressType === MAILING_ADDRESS_TYPE_MAIL) {
      addressTypeId = MAILING_ADDRESS_TYPE_ID;
    }

    const address = {
      companyId,
      addressTypeId: addressTypeId,
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    } as CompanyAddress;
    return address as CompanyAddress;
  };

  return {
    initAddress,
    initPersonAddress,
    initCompanyAddress,
  };
};

export default AddressesService;
