import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import AccountingDashboardMetricsSearch from 'models/Accounting/AccountingDashboardMetricsSearch';

/**
This service provides data for "Accounting Services" which are used in accounting workflows
*/
const AccountingService = () => {
  const getDashboardMetrics = async (
    metricsSearch: AccountingDashboardMetricsSearch
  ): Promise<ApiResponse> =>
    fmsServices().post<AccountingDashboardMetricsSearch, ApiResponse>(
      '/accounting/dashboard/metrics',
      metricsSearch
    );
  const getOverPayments = async () =>
    await fmsServices().get<null, ApiResponse>('/accounting/overpayments');

  return {
    getDashboardMetrics,
    getOverPayments,
  };
};

export default AccountingService;
