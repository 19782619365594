import * as jwt from 'jwt-decode';

export const decodeJwtToken = (payload: any): unknown => {
  const decodedToken = jwt.jwtDecode(payload);
  return decodedToken;
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwt.jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export { isValidToken };
