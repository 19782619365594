import AccountingDashboardMetric from 'models/Accounting/AccountingDashboardMetric';
export default interface AccountingDashboardMetrics {
  transactionsToReview: AccountingDashboardMetric;
  unreconciledInBankIntegration: AccountingDashboardMetric;
  returnsToReview: AccountingDashboardMetric;
  transactionsByStatus: AccountingDashboardMetric;
  applicationsByStatus: AccountingDashboardMetric;
  transactionsBySourceAccount: AccountingDashboardMetric;
  receiveRemittances: AccountingDashboardMetric;
  receiveRemittancesAmount: AccountingDashboardMetric;
  fundingSendPayouts: AccountingDashboardMetric;
  fundingSendPayoutsAmount: AccountingDashboardMetric;
  processingErrors: AccountingDashboardMetric;
}

export const defaultAccountingDashboardMetrics: AccountingDashboardMetrics = {
  transactionsToReview: {
    metricId: 'transactionsToReview',
    metricName: 'transactionsToReview',
    metricValue: '0',
    reviewUrl: '',
  },
  unreconciledInBankIntegration: {
    metricId: 'unreconciledInBankIntegration',
    metricName: 'unreconciledInBankIntegration',
    metricValue: 0,
    reviewUrl: '',
  },
  returnsToReview: {
    metricId: 'returnsToReview',
    metricName: 'returnsToReview',
    metricValue: 0,
    reviewUrl: '',
  },
  transactionsByStatus: {
    metricId: 'transactionsByStatus',
    metricName: 'transactionsByStatus',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  transactionsBySourceAccount: {
    metricId: 'transactionsBySourceAccount',
    metricName: 'transactionsBySourceAccount',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  applicationsByStatus: {
    metricId: 'applicationsByStatus',
    metricName: 'applicationsByStatus',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  receiveRemittances: {
    metricId: 'receiveRemittances',
    metricName: 'receiveRemittances',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  receiveRemittancesAmount: {
    metricId: 'receiveRemittancesAmount',
    metricName: 'receiveRemittancesAmount',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  fundingSendPayouts: {
    metricId: 'fundingSendPayouts',
    metricName: 'fundingSendPayouts',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  fundingSendPayoutsAmount: {
    metricId: 'fundingSendPayoutsAmount',
    metricName: 'fundingSendPayoutsAmount',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
  processingErrors: {
    metricId: 'processingErrors',
    metricName: 'processingErrors',
    metricValue: 0,
    reviewUrl: '',
    data: [],
  },
};
