function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  sessionExpired: path(ROOTS_AUTH, '/expired'),
  resetPassword: path(ROOTS_AUTH, '/request-reset'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_APP = {
  root: '/applications',
  customerService: '/customer-service',
};
