import { PATH_AUTH, PATH_APP } from 'routing/paths';

export const APP_BAR_HEIGHT = 64;

export const XS_HEIGHT_GRID = '320px';
export const MIN_HEIGHT_GRID = '640px';
export const MID_GRID_HEIGHT = 800;
export const DEFAULT_ROWS_PERPAGE = [10, 15, 20, 50, 100];

export const LARGE_ROWS_PERPAGE = [50, 100, 500, 1000, 2000];
/** Smallest page (20) size for grids that have smaller data sets or need to take up less room */
export const SMALL_PAGE_SIZE = 20;
/** Default page (50) size for grids */
export const DEFAULT_PAGE_SIZE = 50;
/** Large page (1000) size to enable display of more records in grids */
export const LARGE_PAGE_SIZE = 1000;
/** Larger page (2000) size for exports of medium sized datasets */
export const LARGER_PAGE_SIZE = 2000;
/** Largest page (5000) size for exports of large data sets and to cap the max allowed rows */
export const LARGEST_PAGE_SIZE = 5000;
export const CALC_HEIGHT_GRID = 'calc(100vh - 195px)';

// snackbar toast duration in milliseconds
export const TOAST_DURATION = 3000;

/** name of session token storage that stores user auth token */
export const AUTH_TOKEN_NAME = 'token-fms';
/** name of session token storage that stores user profile info */
export const USER_PROFILE_KEY = 'profile-fms';
/** Path for the API that gets a users token and authenticates them */
export const AUTH_ENDPOINT = `/auth/token`;
/** Path for the API provides the core data for this application and authenticates a user */
export const REACT_APP_FMS_API_URL = process.env.REACT_APP_FMS_API_URL || 'http://localhost:7500';
/** License Key for Mui-X Data Grid Pro */
export const MUI_X_PRO_LICENSE_KEY = process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY || '';
/** Path for what route to take the user to after authentication */
export const PATH_AFTER_LOGIN = PATH_APP.root;
/** Path for what route to take the user with role ROLE_CODE_CUSTOMER_SERVICE to after authentication */
export const PATH_AFTER_LOGIN_CUSTOMER_SERVICE = PATH_APP.customerService;
/** Path for what route to take the user to so they can authenticate */
export const LOGIN_URL = PATH_AUTH.login;
/** Path for what route to take the user to so they can re-authenticate after session expiration */
export const SESSION_EXPIRED_URL = PATH_AUTH.sessionExpired;
/** Path for the API that refresh a users token and authenticates them */
export const REFRESH_TOKEN_ENDPOINT = `${REACT_APP_FMS_API_URL}/auth/refresh`;
/** Path for the API that logout a user */
export const LOGOUT_ENDPOINT = `/auth/logout`;
