import TransactionType from 'models/Transactions/TransactionType';

export const accountTransactionTypesData: TransactionType[] = [
  {
    transactionTypeId: '209cffb6-9cbf-4109-93e1-c01e0652df3d',
    transactionTypeName: 'ACH Advance Merchant Credit - Initial',
    transactionTypeDescription: 'Initial',
    transactionMethod: 'ACH',
    transactionType: 'Advance',
    targetType: 'Merchant',
    creditOrDebit: 'Credit',
    affectsBalance: '0',
    participantPayout: '0',
  },
  {
    transactionTypeId: 'b8eedcb5-24c1-421a-8bcd-0283d5ec2616',
    transactionTypeName: 'WIRE Advance Merchant Credit - Initial',
    transactionTypeDescription: 'Initial',
    transactionMethod: 'WIRE',
    transactionType: 'Advance',
    targetType: 'Merchant',
    creditOrDebit: 'Credit',
    affectsBalance: '0',
    participantPayout: '0',
  },
];
