import { useTheme } from '@mui/material/styles';
import { Avatar as MUIAvatar } from '@mui/material';

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

const Avatar = ({
  color = 'default',
  onClick,
  children,
  title,
}: {
  color: string | AvatarColor;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  children: any;
  title?: string;
}) => {
  const theme = useTheme();
  return (
    <MUIAvatar
      onClick={onClick}
      title={title ?? 'Your Profile'}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: '#ffffff',
        backgroundColor: color,
        cursor: 'pointer',
      }}
    >
      {children}
    </MUIAvatar>
  );
};

export default Avatar;
