import { format } from 'date-fns';

/** Formats given date to MM/dd/yyyy after removing the offset caused by the timezone
 * NOTE: Only use this when you want to ignore a date's time and timezone.
 */
export const formatDateTimeWithoutTimezone = (date: number | Date | string | undefined): string => {
  if (!date) return '';
  const dateObj = new Date(date);
  const dateObjDateOnly = new Date(dateObj.valueOf() + dateObj.getTimezoneOffset() * 60 * 1000);
  return format(dateObjDateOnly, 'MM/dd/yyyy');
};

export const formatWeekdayWithoutTimezone = (date: number | Date | string | undefined): string => {
  if (!date) return '';
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const dateObj = new Date(date);
  const dateObjDateOnly = new Date(dateObj.valueOf() + dateObj.getTimezoneOffset() * 60 * 1000);
  return weekday[dateObjDateOnly.getDay()];
};
export default formatDateTimeWithoutTimezone;
