import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
// services
import DocumentsService from 'services/DocumentsService';
// models
import DocumentType from 'models/Documents/DocumentType';
import DocumentStatus from 'models/Documents/DocumentStatus';
import DocumentGeneration from 'models/Documents/DocumentGeneration';
// utils
import SystemAdminContextType from 'models/Admin/SystemAdminContextType';

export const SystemAdminContext = React.createContext<SystemAdminContextType | null>(null);

export const SystemAdminProvider = ({ children }: { children: ReactNode }) => {
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [documentStatuses, setDocumentStatuses] = useState<DocumentStatus[]>([]);
  const [documentGeneration, setDocumentGeneration] = useState<DocumentGeneration[]>([]);

  const documentsService = useMemo(DocumentsService, []);

  // Types
  const getDocumentTypes = useCallback(async () => {
    // Note: Document Types are static and only need to be loaded once
    if (documentTypes.length > 0) return;

    const response = await documentsService.getDocumentTypes();
    if (response.data) {
      setDocumentTypes(response.data);
    }
  }, [setDocumentTypes, documentsService, documentTypes]);

  const reloadDocumentTypes = useCallback(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  // Status

  const getDocumentStatuses = useCallback(async () => {
    // Note: Document Statuses are static and only need to be loaded once
    if (documentStatuses.length > 0) return;

    const response = await documentsService.getDocumentStatuses();
    if (response.data) {
      setDocumentStatuses(response.data);
    }
  }, [setDocumentStatuses, documentsService, documentStatuses]);

  const reloadDocumentStatuses = useCallback(() => {
    getDocumentStatuses();
  }, [getDocumentStatuses]);

  useEffect(() => {
    getDocumentStatuses();
  }, [getDocumentStatuses]);

  // Generation
  const getDocumentGeneration = useCallback(async () => {
    // Note: Document Generation Data is static and only need to be loaded once
    if (documentGeneration.length > 0) return;

    const response = await documentsService.getDocumentGeneration();
    if (response.data) {
      setDocumentGeneration(response.data);
    }
  }, [setDocumentGeneration, documentsService, documentGeneration]);

  const reloadDocumentGeneration = useCallback(() => {
    getDocumentGeneration();
  }, [getDocumentGeneration]);

  useEffect(() => {
    getDocumentGeneration();
  }, [getDocumentGeneration]);

  const contextValues = useMemo(
    () => ({
      reloadDocumentTypes,
      documentTypes,
      reloadDocumentStatuses,
      documentStatuses,
      reloadDocumentGeneration,
      documentGeneration,
    }),
    [
      reloadDocumentTypes,
      documentTypes,
      reloadDocumentStatuses,
      documentStatuses,
      documentGeneration,
      reloadDocumentGeneration,
    ]
  );

  return (
    <SystemAdminContext.Provider value={contextValues}>{children}</SystemAdminContext.Provider>
  );
};
