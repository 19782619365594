import useAuth from 'hooks/useAuth';
import NoAccess from 'pages/NoAccess';

type RoleBasedGuardProp = {
  roles: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({ roles, children }: RoleBasedGuardProp) {
  const { user, userIsInRoles } = useAuth();
  const userAllowed = userIsInRoles(user, roles);

  if (userAllowed === false) {
    return <NoAccess />;
  }

  return <>{children}</>;
}
