export const DEFAULT_CHANNEL_BROKER = 'ce76715b-e7b6-4519-a034-b8df3b36b201';
export const DEFAULT_INSTITUTIONAL_CHANNEL_ID = '6e2be815-4f2d-4155-bebe-8321cf616554';

export default interface Channel {
  channelId: string;
  channelName: string;
  channelCode: string;
  channelDescription: string;
  createdBy?: string;
  createdByName?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedByName?: string;
  updatedAt?: Date;
  deletedAt?: Date;
}
