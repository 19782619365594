import CompanyBankStatementAdvanceStatus from 'models/Banking/CompanyBankStatementAdvanceStatus';

export const companyBankStatementAdvanceStatusesData: CompanyBankStatementAdvanceStatus[] = [
  {
    companyBankStatementAdvanceStatusId: '12f4b918-33f0-4656-b4b1-d39cec65af7f',
    companyBankStatementAdvanceStatusName: 'Active',
    companyBankStatementAdvanceStatusDescription: 'Advance is actively being paid',
    displayOrder: 1,
  },
  {
    companyBankStatementAdvanceStatusId: 'b36a1ee8-0631-4ad2-8a95-0562c0e20515',
    companyBankStatementAdvanceStatusName: 'Paid',
    companyBankStatementAdvanceStatusDescription: 'Advance has been off successfully',
    displayOrder: 2,
  },
  {
    companyBankStatementAdvanceStatusId: '26102634-de6c-4584-97b0-c15d281cdf00',
    companyBankStatementAdvanceStatusName: 'Defaulted',
    companyBankStatementAdvanceStatusDescription: 'Advance was not paid off successfully',
    displayOrder: 3,
  },
];
