import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import ApplicationStep from 'models/Application/ApplicationStep';
import DiligenceProfile from 'models/Diligence/DiligenceProfile';
import DiligenceCheck from 'models/Diligence/DiligenceCheck';
import DiligenceCompliance from 'models/Diligence/DiligenceCompliance';
import DiligenceClearanceStatus from 'models/Diligence/DiligenceClearanceStatus';
import DiligenceKYCData from 'models/Diligence/DiligenceKYCData';
import DiligenceCredit from 'models/Diligence/DiligenceCredit';
import DiligenceCompanyRelatedEntity from 'models/Diligence/DiligenceCompanyRelatedEntity';
import DiligenceCreditScoring from 'models/Diligence/DiligenceCreditScoring';
import DiligenceCheckResult from 'models/Diligence/DiligenceCheckResult';
import BusinessPremierProfilesPDFSearch from 'models/Diligence/BusinessPremierProfilesPDFSearch';
import BusinessPremierProfilesPDFResponse from 'models/Diligence/BusinessPremierProfilesPDFResponse';
import DiligenceChecksData from 'models/Diligence/DiligenceChecksData';
import ClearDiligenceCompanyComplianceRequest from 'models/Diligence/ClearDiligenceCompanyComplianceRequest';
import ClearDiligencePersonComplianceRequest from 'models/Diligence/ClearDiligencePersonComplianceRequest';
import DiligenceCreditCalculationTermLoan from 'models/Diligence/DiligenceCreditCalculationTermLoan';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import DiligenceProfileCriteriaSearch from 'models/Diligence/DiligenceProfileCriteriaSearch';
import DiligenceProfileCriteria from 'models/Diligence/DiligenceProfileCriteria';
import ClearDiligenceCompanyRelatedEntityComplianceRequest from 'models/Diligence/ClearDiligenceCompanyRelatedEntityComplianceRequest';

export const CLEAR_DILIGENCE_URL = 'https://clear.thomsonreuters.com/';
export const DILIGENCE_CLEARANCE_STATUS_ID_PASSED = '64c2af2d-34ef-405e-b50a-2c137fdd2330';
export const DILIGENCE_PROVIDER_DATA_ADVERSE_MEDIA_SEARCH_ID =
  '4deb08bd-90d9-4f40-992c-062737c0e82f';
export const CLEAR_DILIGENCE_PROVIDER_ID = '282745a1-164d-483c-a8cf-afa33e4a1926';
export const EXPERIAN_CREDIT_BUSINESS_REPORT_PROVIDER_DATA_ID =
  '974b5c6f-b0ae-4bb4-bd18-c2724034dfe8';
export const EXPERIAN_CREDIT_PERSON_REPORT_PROVIDER_DATA_ID =
  '1ec952f3-a267-45d8-841e-0d992291f144';

export const CLEAR_BUSINESS_REPORT_PROVIDER_DATA_ID = '2fc6edc4-e663-454b-b1a4-6e1729f1fc0e';

export const CLEAR_PERSON_REPORT_PROVIDER_DATA_ID = 'e7e0c7f1-f1ab-4b46-8147-54afcd295509';

export const CLEAR_ADVERSE_MEDIA_PROVIDER_DATA_ID = '4deb08bd-90d9-4f40-992c-062737c0e82f';

export const REQUESTED_CAPITAL_MAX_DILIGENCE_KEY = 'requested_capital_max';
export const MAXIMUM_OFFER_TERM_DILIGENCE_KEY = 'maximum_offer_term';

/**
This service provides data for "Diligence" on applications including
getting and setting the data for a given application and the steps it goes through
and the diligence check an application undergoes as part of processing and underwriting.
*/
const DiligenceService = () => {
  const initCompanyRelatedEntity = (companyId?: string) => {
    const newCompanyRelatedEntity: DiligenceCompanyRelatedEntity = {
      associatedCompanyId: companyId || '',
      diligenceProviderId: CLEAR_DILIGENCE_PROVIDER_ID,
      associatedOwners: [],
      includeOnContract: true,
      legalBusinessName: '',
      dba: '',
      legalEntityType: '',
      fein: '',
      street: '',
      city: '',
      state: '',
      stateOfOrganization: '',
      zipCode: '',
    };
    return newCompanyRelatedEntity;
  };

  const getDiligenceProfiles = async (): Promise<ApiResponse<DiligenceProfile[]>> => {
    const runCheckResponse = await fmsServices().get<any, ApiResponse<DiligenceProfile[]>>(
      `/diligence/profiles/list`
    );
    return runCheckResponse;
  };

  const getApplicationSteps = async (
    applicationId: string
  ): Promise<ApiResponse<ApplicationStep[]>> => {
    const getApplicationStepsResponse = await fmsServices().get<
      any,
      ApiResponse<ApplicationStep[]>
    >(`/diligence/applicationsteps/list/${applicationId}`);
    return getApplicationStepsResponse;
  };

  const updateApplicationStepResult = async (
    applicationId: string,
    applicationStepId: string,
    status: string,
    outcome: string,
    notes?: string
  ): Promise<ApiResponse<ApplicationStep[]>> => {
    let applicationStepResult = {
      applicationStepId,
      applicationId,
      status,
      outcome,
      notes,
    };
    const updateStepResponse = await fmsServices().post<any, ApiResponse<any>>(
      '/diligence/applicationsteps/update-result',
      applicationStepResult
    );
    return updateStepResponse;
  };

  const getApplicationChecks = async (
    applicationId: string
  ): Promise<ApiResponse<DiligenceCheck[]>> => {
    const getApplicationChecksResponse = await fmsServices().get<
      any,
      ApiResponse<DiligenceCheck[]>
    >(`/diligence/check/list/${applicationId}`);
    return getApplicationChecksResponse;
  };

  const updateApplicationDiligenceCheckResult = async (
    applicationId: string,
    applicationStepId: string,
    diligenceCheckId: string,
    status: string,
    outcome: string,
    notes?: string
  ): Promise<ApiResponse<DiligenceCheck[]>> => {
    let diligenceCheck = {
      diligenceCheckId,
      applicationStepId,
      applicationId,
      status,
      outcome,
      notes,
    };
    const updateCheckResponse = await fmsServices().post<any, ApiResponse<any>>(
      '/diligence/applicationchecks/update-result',
      diligenceCheck
    );
    return updateCheckResponse;
  };

  const searchCheckData = async (
    applicationId: string,
    diligenceProviderDataId?: string
  ): Promise<ApiResponse<DiligenceChecksData[]>> => {
    const getApplicationChecksResponse = await fmsServices().post<
      any,
      ApiResponse<DiligenceChecksData[]>
    >(`/diligence/checkdata/search`, { applicationId, diligenceProviderDataId });
    return getApplicationChecksResponse;
  };

  const getCompanyCompliance = async (
    companyId: string
  ): Promise<ApiResponse<DiligenceCompliance>> => {
    const runCheckResponse = await fmsServices().post<any, ApiResponse<DiligenceCompliance>>(
      `/diligence/checkdata/compliance`,
      { companyId }
    );
    return runCheckResponse;
  };
  const getCompanyCreditCompliance = async (
    companyId: string
  ): Promise<ApiResponse<DiligenceCredit>> => {
    const diligenceCreditResponse = await fmsServices().post<any, ApiResponse<DiligenceCredit>>(
      `/diligence/checkdata/credit`,
      { companyId }
    );
    return diligenceCreditResponse;
  };

  const getCompanyKYCData = async (companyId: string): Promise<ApiResponse<DiligenceKYCData>> => {
    const runCheckResponse = await fmsServices().post<any, ApiResponse<DiligenceKYCData>>(
      '/diligence/checkdata/kyc',
      { companyId }
    );
    return runCheckResponse;
  };

  const runApplicationCheck = async (
    applicationId: string,
    checkKey: string
  ): Promise<ApiResponse<any>> => {
    const runCheckResponse = await fmsServices().post<any, ApiResponse<DiligenceCheck[]>>(
      `/diligence/checks/${checkKey}/${applicationId}`,
      {}
    );
    return runCheckResponse;
  };

  const getClearanceStatuses = async (): Promise<ApiResponse<DiligenceClearanceStatus[]>> => {
    const runCheckResponse = await fmsServices().get<any, ApiResponse<DiligenceClearanceStatus[]>>(
      `/diligence/clearancestatus/list`
    );
    return runCheckResponse;
  };

  const getCompanyRelatedEntities = async (
    companyId: string
  ): Promise<ApiResponse<DiligenceCompanyRelatedEntity[]>> => {
    const runCheckResponse = await fmsServices().post<
      { companyId: string },
      ApiResponse<DiligenceCompanyRelatedEntity[]>
    >('/diligence/company-related-entities/list', { companyId });
    return runCheckResponse;
  };

  const searchApplicationRelatedEntities = async (
    applicationId: string
  ): Promise<ApiResponse<DiligenceCompanyRelatedEntity[]>> => {
    const runCheckResponse = await fmsServices().post<
      { applicationId: string },
      ApiResponse<DiligenceCompanyRelatedEntity[]>
    >('/diligence/company-related-entities/search', { applicationId });
    return runCheckResponse;
  };

  const upsertCompanyRelatedEntity = async (
    entity: DiligenceCompanyRelatedEntity
  ): Promise<ApiResponse<DiligenceCompanyRelatedEntity>> => {
    const runCheckResponse = await fmsServices().post<
      DiligenceCompanyRelatedEntity,
      ApiResponse<DiligenceCompanyRelatedEntity>
    >('/diligence/company-related-entities/upsert', entity);
    return runCheckResponse;
  };

  const deleteCompanyRelatedEntity = async (
    diligenceCompanyRelatedEntityId: string
  ): Promise<ApiResponse> => {
    const runCheckResponse = await fmsServices().post<
      { diligenceCompanyRelatedEntityId: string },
      ApiResponse
    >('/diligence/company-related-entities/remove', { diligenceCompanyRelatedEntityId });
    return runCheckResponse;
  };

  const getApplicationCreditScoring = async (
    applicationId: string
  ): Promise<ApiResponse<DiligenceCreditScoring>> => {
    const creditScoringResponse = await fmsServices().post<
      { applicationId: string },
      ApiResponse<DiligenceCreditScoring>
    >(`/diligence/application/credit/${applicationId}`, {
      applicationId,
    });
    return creditScoringResponse;
  };

  const runKYCPerson = async (applicationId: string, personId: string) => {
    const runKYCResponse = await fmsServices().post<any, ApiResponse<DiligenceCheckResult>>(
      `/diligence/kyc/person`,
      { applicationId, personId }
    );
    return runKYCResponse;
  };
  const runKYCCompany = async (applicationId: string, companyId: string) => {
    const runKYCResponse = await fmsServices().post<any, ApiResponse<DiligenceCheckResult>>(
      `/diligence/kyc/company`,
      { applicationId, companyId }
    );
    return runKYCResponse;
  };

  const runOFACPerson = async (applicationId: string, personId: string) => {
    const runKYCResponse = await fmsServices().post<any, ApiResponse<DiligenceCheckResult>>(
      `/diligence/ofac/person`,
      { applicationId, personId }
    );
    return runKYCResponse;
  };
  const runOFACCompany = async (applicationId: string) => {
    const runKYCResponse = await fmsServices().post<any, ApiResponse<DiligenceCheckResult>>(
      `/diligence/ofac/company`,
      { applicationId }
    );
    return runKYCResponse;
  };

  const getBusinessReportPDF = async (search: BusinessPremierProfilesPDFSearch) => {
    const reportPDFResponse = await fmsServices().post<
      BusinessPremierProfilesPDFSearch,
      ApiResponse<BusinessPremierProfilesPDFResponse>
    >('/diligence/experian/business/report/pdf', search);
    return reportPDFResponse;
  };

  const clearCompanyCompliance = async (request: ClearDiligenceCompanyComplianceRequest) => {
    const clearComplianceResponse = await fmsServices().post<
      ClearDiligenceCompanyComplianceRequest,
      ApiResponse<DiligenceCheckResult>
    >(`/diligence/company-compliance/clear`, request);
    return clearComplianceResponse;
  };

  const clearCompanyRelatedEntityCompliance = async (
    request: ClearDiligenceCompanyRelatedEntityComplianceRequest
  ) => {
    const clearComplianceResponse = await fmsServices().post<
      ClearDiligenceCompanyRelatedEntityComplianceRequest,
      ApiResponse<DiligenceCheckResult>
    >(`/diligence/company-related-entity-compliance/clear`, request);
    return clearComplianceResponse;
  };

  const clearPersonCompliance = async (request: ClearDiligencePersonComplianceRequest) => {
    const clearComplianceResponse = await fmsServices().post<
      ClearDiligencePersonComplianceRequest,
      ApiResponse<DiligenceCheckResult>
    >(`/diligence/person-compliance/clear`, request);
    return clearComplianceResponse;
  };

  const getCreditCalculationsTermLoan = async (applicationId: string) => {
    const creditCalculationsResponse = await fmsServices().post<
      any,
      ApiResponse<DiligenceCreditCalculationTermLoan>
    >('/diligence/application/creditcalculation/termloan', { applicationId: applicationId });
    return creditCalculationsResponse;
  };

  const runDiligenceAutomation = async (
    applicationId: string,
    diligenceProfileId?: string,
    diligenceCheckId?: string,
    applicationStepId?: string
  ): Promise<ApiResponse<DiligenceCheckResult>> =>
    fmsServices().post<any, ApiResponse<DiligenceCheckResult>>(
      `/diligence/automation/${applicationId}`,
      {
        applicationId,
        diligenceProfileId,
        diligenceCheckId,
        applicationStepId,
      }
    );

  const searchDiligenceProfileCriteria = async (search: DiligenceProfileCriteriaSearch) => {
    const response = await fmsServices().post<
      DiligenceProfileCriteriaSearch,
      ApiResponsePaged<DiligenceProfileCriteria[]>
    >(`/diligence/profiles/criteria/search`, search);
    return response;
  };

  const upsertDiligenceProfileCriteria = async (data: DiligenceProfileCriteria, mode: string) => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    const response = await fmsServices().post<DiligenceProfileCriteria, ApiResponse>(
      `/diligence/profiles/criteria/upsert`,
      data
    );
    return response;
  };

  const upsertDiligenceProfile = async (data: DiligenceProfile, mode: string) => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    const response = await fmsServices().post<DiligenceProfile, ApiResponse>(
      `/diligence/profiles/upsert`,
      data
    );
    return response;
  };

  return {
    initCompanyRelatedEntity,
    getDiligenceProfiles,
    searchCheckData,
    getApplicationSteps,
    updateApplicationStepResult,
    getApplicationChecks,
    runApplicationCheck,
    updateApplicationDiligenceCheckResult,
    getCompanyCompliance,
    getCompanyCreditCompliance,
    getCompanyKYCData,
    getClearanceStatuses,
    getCompanyRelatedEntities,
    searchApplicationRelatedEntities,
    upsertCompanyRelatedEntity,
    deleteCompanyRelatedEntity,
    getApplicationCreditScoring,
    runKYCPerson,
    runKYCCompany,
    runOFACPerson,
    runOFACCompany,
    getBusinessReportPDF,
    clearCompanyCompliance,
    clearPersonCompliance,
    clearCompanyRelatedEntityCompliance,
    getCreditCalculationsTermLoan,
    runDiligenceAutomation,
    searchDiligenceProfileCriteria,
    upsertDiligenceProfileCriteria,
    upsertDiligenceProfile,
  };
};

export default DiligenceService;
