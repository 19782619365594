import { useContext } from 'react';
import MuiThemeModeContext from 'contexts/MuiThemeModeContext';

export default function useMuiThemeToggle() {
  const context = useContext(MuiThemeModeContext);
  if (!context) {
    throw new Error('useMuiThemeToggle must be used within MuiThemeModeProvider');
  }
  return context;
}
