import fmsServices from 'services/fmsServices';
import PersonsService from 'services/PersonsService';
// models
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ApiResponse from 'models/API/ApiResponse';
import ApplicationException from 'models/Application/ApplicationException';
import ApplicationSearch from 'models/Application/ApplicationSearch';
import Application from 'models/Application/Application';
import ApplicationStatus from 'models/Application/ApplicationStatus';
import ApplicationSubStatus from 'models/Application/ApplicationSubStatus';
import Product from 'models/Application/Product';
import ApprovalRequest from 'models/Application/ApprovalRequest';
import Approval from 'models/Application/Approval';
import ApplicationSubmissionData from 'models/Application/ApplicationSubmissionData';
import ApplicationUpdateRequest from 'models/Application/ApplicationUpdateRequest';
import ApplicationUpdateTaxLiensAndJudgementsRequest from 'models/Application/ApplicationUpdateTaxLiensAndJudgementsRequest';
import OfferPricingRequest from 'models/Application/OfferPricingRequest';
import OfferPricingResponse from 'models/Application/OfferPricingResponse';
import ApplicationStatusSummary from 'models/Applications/ApplicationStatusSummary';
import ApplicationActiveRenewalTool from 'models/Application/ApplicationActiveRenewalTool';
import FundingRenewal from 'models/Application/FundingRenewal';
import { DILIGENCE_PROFILE_LIBERTAS_ID } from 'models/Diligence/DiligenceProfile';
import { DEFAULT_CHANNEL_BROKER } from 'models/Channel/Channel';
import CalculateCreditTierResponse from 'models/Application/CalculateCreditTierResponse';
import ApplicationMissedCondition from 'models/Application/ApplicationMissedCondition';

// constants that are here to enable front end logic - these values are on backend as well
export const PRE_SUBMISSION_APPLICATION_STATUS_ID = 'c15a84ca-e80f-4c43-8302-10ea19be3eb4';
export const SUBMITTED_APPLICATION_STATUS_ID = '943c60e2-0c05-457d-9366-5c31d513d2f4';
export const REJECTED_APPLICATION_STATUS_ID = '27859d87-0fda-410f-872d-ca02b06ca312';
export const RBF_PRODUCT_ID = '055fc406-c25e-4fa2-99be-ac31359f7e67';
export const TERMLOAN_PRODUCT_ID = '07f40272-7db7-4beb-910a-2299afccd5d9';
export const TERMLOAN_MIN_TERM = 12;
export const TERMLOAN_MIN_NUMBER_OF_PREPAYS = 6;
export const APPLICATION_STEP_ID_KYC = '4b65450d-61fc-4cd3-9339-5ee06a8aedb0';
export const APPLICATION_STEP_ID_BANK_CALC = '883e13ad-1ab5-4efb-adfd-8a645e45cebd';
export const APPLICATION_STEP_ID_FINANCIALS = 'e433b778-4b2a-4620-8d80-47e45a0eb26d';
export const APPLICATION_STEP_ID_CREDIT_REVIEW = 'b2f42c3c-fdcc-4c5b-aa7b-926e3927905e';
export const APPLICATION_STEP_ID_OFFERS = '48d09fc1-8c81-4aaf-a08c-13272d192644';
export const APPLICATION_STEP_ID_CONTRACTS = 'f809b4bc-be3b-4d1d-a3d8-1595c8d8b3cc';
export const APPLICATION_STEP_ID_INTERVIEWS = '05befcc1-9483-4380-9cf6-a437f9c0e883';
export const APPLICATION_STEP_ID_STIPS = '3600d0dc-b8c4-4ff5-ad1c-53f6e2a2ca50';
export const APPLICATION_STEP_ID_APPROVALS = 'a84bb1ac-259b-4d90-a723-a58d8280fbfa';
export const APPLICATION_STEP_ID_QA = '73c96dd8-8601-4dab-8e80-ab63a02887bc';
export const APPLICATION_STEP_ID_FUNDING = 'a58432fa-272c-4c10-b226-5f47d0a23cd1';
export const APPLICATION_CASHBASED_ACCOUNTING_METHOD = 'cash-based';
export const FUNDER_ID_LIBERTAS = '974a3a1a-053d-b03d-b790-0f52c0fbf25c';

/** Application Statuses */
export const APPLICATION_STATUS_ID_PRE_SUBMISSION = 'c15a84ca-e80f-4c43-8302-10ea19be3eb4';
export const APPLICATION_STATUS_ID_SUBMITTED = '943c60e2-0c05-457d-9366-5c31d513d2f4';
export const APPLICATION_STATUS_ID_PROCESSING = '8066c710-ecc7-4a5a-9f50-31b1a6fbaedc';
export const APPLICATION_STATUS_ID_UNDERWRITING = '26db7d4f-9ba4-462c-a52d-26eca865cac7';
export const APPLICATION_STATUS_ID_APPROVALS = 'd4406966-79fd-4c5c-ba87-6ac8c8c0c031';
export const APPLICATION_STATUS_ID_CONTRACTS = 'ee4ee59b-4915-47f1-878c-d0a04f50494d';
export const APPLICATION_STATUS_ID_CLOSING = 'a22c035b-1c0c-445a-a131-2fac7b7da243';
export const APPLICATION_STATUS_ID_APPROVED = '3f8aebc5-0cfd-4777-8f2c-ed5d1dbd7fb7';
export const APPLICATION_STATUS_ID_FUNDED = '08adcd4d-eced-42d2-a8e3-161e36ac98ce';
export const APPLICATION_STATUS_ID_REJECTED = '27859d87-0fda-410f-872d-ca02b06ca312';

/**
This service provides data for "Applications" which is one core entity 
within the system that is the main model for the actual data of an application 
to recieve funding made on the part of given Company
*/
const ApplicationsService = () => {
  const personsService = PersonsService();
  const initNewApplication = (companyId: string) => {
    const newApp: Application = {
      companyId,
      requestedAmount: 0,
      useOfFunds: '',
      statusId: PRE_SUBMISSION_APPLICATION_STATUS_ID,
      priority: 'normal',
      partnerId: '',
      channelId: '',
      productId: RBF_PRODUCT_ID,
      applicationType: 'new',
      businessName: '',
      company: {
        companyId: undefined,
        legalBusinessName: '',
        dba: '',
        entityType: '',
        industryId: '',
        netYearlyRevenue: 0,
        stateOfOrganization: '',
        averageMonthlySales: 0,
        federalTaxID: '',
        businessStartDate: undefined,
        businessPhone: '',
        websiteURL: '',
        codatId: undefined,
        isCodatLinked: false,
        acceptsCreditCards: false,
        hasCurrentShortTermFinancing: false,
        publiclyTraded: false,
        createdBy: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        deletedAt: undefined,
      },
    };
    return newApp;
  };

  const initCreateApplication = () => ({
    // Company
    legalBusinessName: '',
    dba: '',
    entityType: '',
    industryId: '',
    industry: '',
    netYearlyRevenue: 0,
    averageMonthlySales: 0,
    federalTaxID: '',
    businessPhone: '',
    websiteURL: '',
    businessStartDate: undefined,
    owners: personsService.initCompanyPersonsItemList(''),
    diligenceProfileId: DILIGENCE_PROFILE_LIBERTAS_ID,
    companyId: '',
    requestedAmount: 0,
    useOfFunds: '',
    useOfFundsDescription: '',
    statusId: PRE_SUBMISSION_APPLICATION_STATUS_ID,
    priority: 'normal',
    partnerId: '',
    channelId: DEFAULT_CHANNEL_BROKER,
    productId: RBF_PRODUCT_ID,
    funderId: FUNDER_ID_LIBERTAS,
    applicationType: 'new',
    publiclyTraded: false,
    acceptedAgreements: true,
    acceptedEsignConsent: true,
    fundingCommissionedUserId: '',
    physicalAddress: {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    mailingAddress: {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  const statusAllowsEdit = (status: string | undefined) =>
    ![APPLICATION_STATUS_ID_FUNDED, APPLICATION_STATUS_ID_REJECTED].includes(status!);

  const submitNewApplication = async (
    application: Application,
    note?: string
  ): Promise<ApiResponse> => {
    delete application.applicationId;
    application.note = note; // add optional note to include alongside status
    const createApplicationResponse = await fmsServices().post<Application, ApiResponse>(
      '/applications/create',
      application
    );
    return createApplicationResponse;
  };

  const getApplication = async (applicationId: string): Promise<ApiResponse<Application>> => {
    const getApplicationResponse = await fmsServices().get<any, ApiResponse<Application>>(
      `/applications/details/${applicationId}`
    );
    return getApplicationResponse;
  };

  const getApplicationSubmission = async (
    applicationId: string
  ): Promise<ApiResponse<ApplicationSubmissionData>> => {
    const getApplicationResponse = await fmsServices().post<
      any,
      ApiResponse<ApplicationSubmissionData>
    >(`/applications/submission/search`, { applicationId: applicationId });
    return getApplicationResponse;
  };

  const createApplicationSubmissionSnapshot = async (
    applicationId: string
  ): Promise<ApiResponse<ApplicationSubmissionData>> => {
    const createApplicationSnapshotResponse = await fmsServices().post<
      any,
      ApiResponse<ApplicationSubmissionData>
    >(`/applications/submission/snapshot`, { applicationId });
    return createApplicationSnapshotResponse;
  };

  const searchApplications = async (
    applicationSearch: ApplicationSearch
  ): Promise<ApiResponsePaged<Application[], any>> => {
    const searchApplicationResponse = await fmsServices().post<
      ApplicationSearch,
      ApiResponsePaged<Application[], any>
    >('/applications/search', applicationSearch);
    return searchApplicationResponse;
  };

  const getActiveApplications = async (companyId: string) => {
    const response = await fmsServices().get<any, ApiResponse<ApplicationActiveRenewalTool[]>>(
      `/applications/active/${companyId}`
    );
    return response;
  };

  const getRenewalsByApplicationId = async (applicationId: string) => {
    const response = await fmsServices().get<any, ApiResponse<FundingRenewal[]>>(
      `/applications/renewals/${applicationId}`
    );
    return response;
  };

  const updateApplications = async (application: Application | ApplicationUpdateRequest) => {
    const updateApplicationResponse = await fmsServices().post<
      Application | ApplicationUpdateRequest,
      ApiResponse<Application>
    >('/applications/update', application);
    return updateApplicationResponse;
  };

  const updateApplicationTaxLiensAndJudgments = async (
    taxLiensAndJudgementsRequest: ApplicationUpdateTaxLiensAndJudgementsRequest
  ): Promise<ApiResponse<Application>> => {
    const updateTaxLiensAndJudgmentsResponse = await fmsServices().post<
      ApplicationUpdateTaxLiensAndJudgementsRequest,
      ApiResponse<Application>
    >('/applications/taxliens/update', taxLiensAndJudgementsRequest);
    return updateTaxLiensAndJudgmentsResponse;
  };

  const updateApplicationStatus = async (
    applicationId: string,
    statusId: string,
    sendExternalEmail: boolean,
    note?: string,
    rejectReasonIds?: string[],
    noteType?: string
  ) => {
    const updateStatusData = {
      applicationId,
      statusId,
      note,
      noteType,
      sendExternalEmail,
      rejectReasonIds,
    };
    const updateStatusResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/update/status',
      updateStatusData
    );
    return updateStatusResponse;
  };

  const getApplicationStatusSummary = async () => {
    const applicationStatusSummaryResponse = await fmsServices().get<
      any,
      ApiResponsePaged<ApplicationStatusSummary[]>
    >('/applications/status/summary');
    return applicationStatusSummaryResponse;
  };

  const getApplicationStatuses = async () => {
    const applicationStatusesResponse = await fmsServices().get<any, ApiResponse>(
      '/applications/statuses'
    );
    return applicationStatusesResponse;
  };

  const getApplicationStatusActivities = async (applicationId: string) => {
    const requestBody = {
      applicationId,
    };

    const applicationStatusSummaryResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/status/search',
      requestBody
    );
    return applicationStatusSummaryResponse;
  };

  const getPreviousApplication = async (
    companyGroupId: string,
    pageNumber: number,
    pageSize: number
  ) => {
    const requestBody = {
      companyGroupId,
      pageNumber,
      pageSize,
    };

    const previousAppResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/search',
      requestBody
    );
    return previousAppResponse;
  };

  const searchTeam = async (applicationId: string) => {
    const searchTeamResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/teammember/search',
      {
        applicationId,
      }
    );
    return searchTeamResponse;
  };

  const extractTeamMembers = (teamData: any) => {
    const underwriters = teamData.filter((t) => t.role.roleName === 'Underwriter');
    const processors = teamData.filter((t) => t.role.roleName === 'Processor');
    const closers = teamData.filter((t) => t.role.roleName === 'Closer');
    return {
      underwriters,
      processors,
      closers,
    };
  };

  const removeTeamMember = async (userId: string, applicationId: string) => {
    const removeTeamResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/teammember/remove',
      {
        userId,
        applicationId,
      }
    );
    return removeTeamResponse;
  };

  const addTeamMember = async (applicationId: string, roleId: string) => {
    const addTeamMemberResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/teammember/assign/role',
      {
        applicationId,
        roleId,
      }
    );
    return addTeamMemberResponse;
  };

  const upsertApplicationTeamMember = async (
    userId: string,
    applicationId: string,
    roleId: string,
    isPrimary: boolean,
    isDoubleCheck: boolean
  ) => {
    const addTeamMemberResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/applications/teammember/upsert',
      {
        userId,
        applicationId,
        roleId,
        isPrimary,
        isDoubleCheck,
      }
    );
    return addTeamMemberResponse;
  };

  const autoAssignMember = async (applicationId: string, roleId: string) => {
    const autoAssignResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/teammember/assign/role',
      {
        applicationId,
        roleId,
      }
    );
    return autoAssignResponse;
  };

  const autoAssignAll = async (applicationId: string) => {
    const autoAssignAllResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/teammember/assign',
      {
        applicationId,
      }
    );
    return autoAssignAllResponse;
  };

  const getApplicationSubStatuses = async (applicationStatusId: string) => {
    const getApplicationSubStatusesResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/substatuses',
      {
        applicationStatusId,
      }
    );
    return getApplicationSubStatusesResponse;
  };

  const updateApplicationSubStatus = async (
    applicationId: string,
    applicationSubStatusId: string
  ) => {
    const updateResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/substatus/update',
      {
        applicationId,
        applicationSubStatusId,
      }
    );
    return updateResponse;
  };

  const getApplicationStatusesStatic = (): ApplicationStatus[] => {
    const data = [
      {
        applicationStatusId: PRE_SUBMISSION_APPLICATION_STATUS_ID,
        applicationStatusName: 'Pre-Submission',
        applicationStatusDescription: 'Application has not been submitted',
        displayOrder: 1,
      },
      {
        applicationStatusId: SUBMITTED_APPLICATION_STATUS_ID,
        applicationStatusName: 'Submitted',
        applicationStatusDescription: 'Application has been submitted',
        displayOrder: 2,
      },
      {
        applicationStatusId: '8066c710-ecc7-4a5a-9f50-31b1a6fbaedc',
        applicationStatusName: 'Processing',
        applicationStatusDescription: 'Application is in the intial processing phase',
        displayOrder: 3,
      },
      {
        applicationStatusId: '26db7d4f-9ba4-462c-a52d-26eca865cac7',
        applicationStatusName: 'Underwriting',
        applicationStatusDescription: 'Application is in the underwriting phase',
        displayOrder: 4,
      },
      {
        applicationStatusId: 'd4406966-79fd-4c5c-ba87-6ac8c8c0c031',
        applicationStatusName: 'Approvals',
        applicationStatusDescription:
          'Application has conditional approvals and is being further evaluated',
        displayOrder: 5,
      },
      {
        applicationStatusId: 'ee4ee59b-4915-47f1-878c-d0a04f50494d',
        applicationStatusName: 'Contracts',
        applicationStatusDescription: 'Contracts have been sent out',
        displayOrder: 6,
      },
      {
        applicationStatusId: 'a22c035b-1c0c-445a-a131-2fac7b7da243',
        applicationStatusName: 'Closing',
        applicationStatusDescription: 'Application is in Closing',
        displayOrder: 7,
      },
      {
        applicationStatusId: '3f8aebc5-0cfd-4777-8f2c-ed5d1dbd7fb7',
        applicationStatusName: 'Approved',
        applicationStatusDescription: 'Application has been approved to fund',
        displayOrder: 8,
      },
      {
        applicationStatusId: '08adcd4d-eced-42d2-a8e3-161e36ac98ce',
        applicationStatusName: 'Listed',
        applicationStatusDescription: 'Application has been funded and listed',
        displayOrder: 9,
      },
      {
        applicationStatusId: REJECTED_APPLICATION_STATUS_ID,
        applicationStatusName: 'Rejected',
        applicationStatusDescription: 'Application was not approved to fund',
        displayOrder: 10,
      },
    ] as ApplicationStatus[];
    return data;
  };

  const getApplicationStatusIdByName = (statusName: string) => {
    const statuses = getApplicationStatusesStatic();
    const matchingStatus = statuses.filter(
      (s) => s.applicationStatusName.toLowerCase() === statusName.toLowerCase()
    );
    if (matchingStatus.length) {
      return matchingStatus[0].applicationStatusId;
    }
  };

  const getApplicationSubStatusesStatic = (applicationStatusId: string): ApplicationSubStatus[] => {
    const data = [
      {
        applicationSubStatusId: '95378d35-6565-4ec0-85f6-f7bead7ab804',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_SUBMITTED,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '4832a701-b5d6-4f31-97c7-b3a63fcf1166',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_PRE_SUBMISSION,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '1aa44944-aaf6-4046-91fb-231155ac09bc',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_APPROVALS,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '69abc0b1-206c-482b-bfde-b9bfc499ba1d',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_CONTRACTS,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: 'd6f160ea-bcbf-4654-a612-a7d350f8982a',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_PROCESSING,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '2fafc0c6-82c6-4e26-b85b-ccf691f6278c',
        applicationSubStatusName: 'Hold',
        applicationSubStatusDescription: 'Application is on hold',
        applicationStatusId: APPLICATION_STATUS_ID_UNDERWRITING,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '90c1efdd-9d1f-41be-a375-38830eb3b42b',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_UNDERWRITING,
        displayOrder: 2,
      },
      {
        applicationSubStatusId: '847bc5ed-346b-452c-ac7d-028bce0fd712',
        applicationSubStatusName: 'Contracts Only',
        applicationSubStatusDescription: 'Contracts Only',
        applicationStatusId: APPLICATION_STATUS_ID_CLOSING,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '13ec24eb-dfbb-4951-88ae-b9e0813bf6b3',
        applicationSubStatusName: 'All Stips Received',
        applicationSubStatusDescription: 'All Stips Received',
        applicationStatusId: APPLICATION_STATUS_ID_CLOSING,
        displayOrder: 2,
      },
      {
        applicationSubStatusId: '8034e7bf-61df-4434-a65f-8802cdaed357',
        applicationSubStatusName: 'Needs Further Review',
        applicationSubStatusDescription: 'Needs Further Review',
        applicationStatusId: APPLICATION_STATUS_ID_CLOSING,
        displayOrder: 3,
      },
      {
        applicationSubStatusId: 'fa049510-38fe-4625-867d-8fd6bda999c1',
        applicationSubStatusName: 'Clear To Close',
        applicationSubStatusDescription: 'Clear To Close',
        applicationStatusId: APPLICATION_STATUS_ID_CLOSING,
        displayOrder: 4,
      },
      {
        applicationSubStatusId: '5a779e51-f161-4138-a1c2-3a269857c36a',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_CLOSING,
        displayOrder: 5,
      },
      {
        applicationSubStatusId: 'd7176c48-67d2-4b04-9306-26d7120aa69d',
        applicationSubStatusName: 'All Stips Cleared',
        applicationSubStatusDescription: 'All Stips Cleared',
        applicationStatusId: APPLICATION_STATUS_ID_APPROVED,
        displayOrder: 1,
      },
      {
        applicationSubStatusId: '5990fb38-7892-4570-ab30-05722dc5d102',
        applicationSubStatusName: 'Ready To Fund',
        applicationSubStatusDescription: 'Ready To Fund',
        applicationStatusId: APPLICATION_STATUS_ID_APPROVED,
        displayOrder: 2,
      },
      {
        applicationSubStatusId: 'f90c050e-f0f6-4871-9187-8d8993b0202c',
        applicationSubStatusName: 'Needs Further Review',
        applicationSubStatusDescription: 'Needs Further Review',
        applicationStatusId: APPLICATION_STATUS_ID_APPROVED,
        displayOrder: 3,
      },
      {
        applicationSubStatusId: 'cd064059-8a0f-46cc-ae6e-74b17956dda5',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_APPROVED,
        displayOrder: 4,
      },
      {
        applicationSubStatusId: '3511f81a-cceb-42a5-a7aa-b5504958642d',
        applicationSubStatusName: 'Pending',
        applicationSubStatusDescription: 'Application is pending',
        applicationStatusId: APPLICATION_STATUS_ID_FUNDED,
        displayOrder: 1,
      },
    ] as ApplicationSubStatus[];
    return data.filter((d) => d.applicationStatusId === applicationStatusId);
  };

  const applicationStatusHasSubStatuses = (applicationStatusId?: string): boolean => {
    if (applicationStatusId) {
      const substatuses = getApplicationSubStatusesStatic(applicationStatusId);
      return substatuses.length > 0;
    } else {
      return false;
    }
  };

  const updateApplicationStepOutcome = async (
    applicationId: string,
    applicationStepId: string,
    status: string,
    outcome: string,
    notes?: string
  ) => {
    const updateStepResponse = await fmsServices().post<any, ApiResponse>(
      '/diligence/applicationsteps/update-result',
      {
        applicationId,
        applicationStepId,
        status: status,
        outcome: outcome,
        notes: notes,
      }
    );
    return updateStepResponse;
  };

  const updateApplicationUseOfFunds = async (
    applicationId: string,
    useOfFunds: string,
    useOfFundsDescription?: string
  ) => {
    const updateUseOfFunds = await fmsServices().post<any, ApiResponse>(
      '/applications/useoffunds/update',
      {
        applicationId,
        useOfFunds,
        useOfFundsDescription,
      }
    );
    return updateUseOfFunds;
  };

  const updateApplicationIndustryEligibilityOverride = async (
    applicationId: string,
    industryEligibilityOveride?: string | null
  ) => {
    const updateIndustryEligibilityOverideResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/industry/eligibility',
      {
        applicationId: applicationId,
        industryEligibilityOveride: industryEligibilityOveride,
      }
    );
    return updateIndustryEligibilityOverideResponse;
  };

  const updateApplicationMaxPTIOverride = async (
    applicationId: string,
    maxPTIOverride?: number | null
  ) => {
    const updateMaxPTIOverrideResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/update/maxpti',
      {
        applicationId: applicationId,
        maxPTIOverride: maxPTIOverride,
      }
    );
    return updateMaxPTIOverrideResponse;
  };

  const updateApplicationMaxPTBOverride = async (
    applicationId: string,
    maxPTBOverride?: number | null
  ) => {
    const updateMaxPTIOverrideResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/update/maxptb',
      {
        applicationId: applicationId,
        maxPTBOverride: maxPTBOverride,
      }
    );
    return updateMaxPTIOverrideResponse;
  };

  const toggleMarkApplicationAsTest = async (applicationId: string, isTestApplication: boolean) => {
    const markApplicationAsTestResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/update/isTestApplication',
      {
        applicationId: applicationId,
        isTestApplication: isTestApplication,
      }
    );
    return markApplicationAsTestResponse;
  };

  const calculateApplicationCreditTier = async (applicationId: string) => {
    const updateAdjustedCreditTierResponse = await fmsServices().post<
      any,
      ApiResponse<CalculateCreditTierResponse>
    >('/applications/credittier/calculate', {
      applicationId: applicationId,
    });
    return updateAdjustedCreditTierResponse;
  };

  const updateApplicationAdjustedCreditTier = async (
    applicationId: string,
    adjustedCreditTierId?: string | null,
    adjustedCreditTierReason?: string | null
  ) => {
    const updateAdjustedCreditTierResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/credittier/adjust',
      {
        applicationId: applicationId,
        adjustedCreditTierId: adjustedCreditTierId,
        adjustedCreditTierReason: adjustedCreditTierReason,
      }
    );
    return updateAdjustedCreditTierResponse;
  };

  const getApplicationRejectReasons = async (productId?: string) => {
    const getApplicationRejectReasonsResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/rejectreasons',
      { productId }
    );
    return getApplicationRejectReasonsResponse;
  };

  const getApplicationRejectReasonValues = async (applicationId: string) => {
    const getApplicationRejectReasonsResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/rejectreasonvalues',
      { applicationId }
    );
    return getApplicationRejectReasonsResponse;
  };

  const getExceptions = async (): Promise<ApiResponse<ApplicationException[]>> => {
    const exceptionsResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/exceptions'
    );
    return exceptionsResponse;
  };

  const getApprovals = async (applicationId: string): Promise<ApiResponse<Approval>> => {
    // eslint-disable-next-line prettier/prettier
    const approvalsResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/approval',
      // eslint-disable-next-line prettier/prettier
      { applicationId }
    );
    return approvalsResponse;
  };

  const upsertApproval = async (approvalRequest: ApprovalRequest) => {
    const upsertResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/approval/upsert',
      approvalRequest
    );
    return upsertResponse;
  };

  const getProductName = (id: string) => {
    switch (id) {
      case RBF_PRODUCT_ID:
        return 'RBF';
      case TERMLOAN_PRODUCT_ID:
        return 'Term Loan';
    }
  };

  const getProducts = (): Product[] => [
    {
      productId: RBF_PRODUCT_ID,
      productName: 'RBF',
      productKey: 'rbf',
      productDescription: 'Revenue Based Financing',
      additionalStips: 'None Needed',
    },
    {
      productId: TERMLOAN_PRODUCT_ID,
      productName: 'Term Loan',
      productKey: 'term-loan',
      productDescription: 'Term Loan Financing',
      additionalStips: 'Financials',
    },
  ];

  const generatePricing = async (generateOfferPricingRequest: OfferPricingRequest) => {
    const generatePricingResponse = await fmsServices().post<
      OfferPricingRequest,
      ApiResponse<OfferPricingResponse>
    >('/applications/pricing/generate', generateOfferPricingRequest);
    return generatePricingResponse;
  };

  const copyApplication = async (applicationId: string) => {
    const response = await fmsServices().post<any, ApiResponse>('/applications/copy', {
      applicationId,
    });

    return response;
  };

  const createApplicationFromPdf = async (file: File) => {
    const uploadForm = new FormData();
    uploadForm.set('application', file);
    return await fmsServices().postFormData<any, ApiResponse>(
      '/applications/create/pdf',
      uploadForm
    );
  };

  const updateFundedInLast30Days = async (applicationId: string, fundedInLast30Days: boolean) => {
    const updateFundedInLast30DaysResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/fundedrecently/update',
      {
        applicationId,
        fundedInLast30Days,
      }
    );
    return updateFundedInLast30DaysResponse;
  };

  const updateAccountingMethod = async (
    applicationId: string,
    accountingMethod: string
  ): Promise<ApiResponse> => {
    const updateResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/accountingmethod/update',
      {
        applicationId,
        accountingMethod,
      }
    );
    return updateResponse;
  };

  const fetchDuplicatesByApplicationId = async (
    applicationId: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<ApiResponse> => {
    const fetchResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/duplicates/fetch',
      {
        applicationId,
        pageSize,
        pageNumber,
      }
    );
    return fetchResponse;
  };

  const fetchDuplicatesCountByApplicationIds = async (
    applicationIds: string[]
  ): Promise<ApiResponse> => {
    const fetchResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/duplicates/count',
      {
        applicationIds,
      }
    );
    return fetchResponse;
  };

  const recalculateDuplicatesByApplicationIds = async (
    applicationIds: string[]
  ): Promise<ApiResponse> => {
    const fetchResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/duplicates/calculate',
      {
        applicationIds,
      }
    );
    return fetchResponse;
  };

  const updatePartnerId = async (applicationId: string, partnerId: string) => {
    const updateStatusData = {
      applicationId,
      partnerId,
    };
    const updatePartnerResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/partner/update',
      updateStatusData
    );
    return updatePartnerResponse;
  };

  const updateSuperb = async (applicationId: string, isSuperb: boolean) => {
    const updateStatusData = {
      applicationId,
      isSuperb,
    };
    const updateSuperbResponse = await fmsServices().post<any, ApiResponse>(
      '/applications/superb/update',
      updateStatusData
    );
    return updateSuperbResponse;
  };

  const updateDiligenceProfile = async (applicationId: string, diligenceProfileId: string) => {
    const updateSuperbResponse = await fmsServices().put<
      {
        applicationId: string;
        diligenceProfileId: string;
      },
      ApiResponse
    >('/applications/diligence-profile', {
      applicationId,
      diligenceProfileId,
    });

    return updateSuperbResponse;
  };

  const getApplicationMissedConditions = async (applicationId: string) => {
    const response = await fmsServices().get<any, ApiResponse<ApplicationMissedCondition[]>>(
      `/applications/missed-conditions/${applicationId}`
    );
    return response;
  };

  return {
    initNewApplication,
    initCreateApplication,
    submitNewApplication,
    statusAllowsEdit,
    getApplication,
    getApplicationSubmission,
    createApplicationSubmissionSnapshot,
    searchApplications,
    updateApplications,
    updateApplicationTaxLiensAndJudgments,
    updateApplicationStatus,
    getApplicationStatuses,
    getApplicationStatusesStatic,
    getApplicationStatusIdByName,
    getApplicationStatusSummary,
    getApplicationStatusActivities,
    getPreviousApplication,
    searchTeam,
    extractTeamMembers,
    removeTeamMember,
    addTeamMember,
    autoAssignMember,
    autoAssignAll,
    upsertApplicationTeamMember,
    getApplicationSubStatuses,
    updateApplicationSubStatus,
    getApplicationSubStatusesStatic,
    applicationStatusHasSubStatuses,
    updateApplicationIndustryEligibilityOverride,
    updateApplicationMaxPTIOverride,
    updateApplicationMaxPTBOverride,
    updateApplicationAdjustedCreditTier,
    updateApplicationStepOutcome,
    updateApplicationUseOfFunds,
    toggleMarkApplicationAsTest,
    getApplicationRejectReasons,
    getApplicationRejectReasonValues,
    getExceptions,
    getApprovals,
    upsertApproval,
    getProductName,
    getProducts,
    generatePricing,
    createApplicationFromPdf,
    updateFundedInLast30Days,
    updateAccountingMethod,
    copyApplication,
    getActiveApplications,
    getRenewalsByApplicationId,
    fetchDuplicatesByApplicationId,
    fetchDuplicatesCountByApplicationIds,
    recalculateDuplicatesByApplicationIds,
    updatePartnerId,
    updateSuperb,
    updateDiligenceProfile,
    calculateApplicationCreditTier,
    getApplicationMissedConditions,
  };
};

export default ApplicationsService;
