// eslint-disable-next-line import/no-duplicates
import { enUS } from 'date-fns/locale';
// eslint-disable-next-line import/no-duplicates
import { format as formatFunction } from 'date-fns';

/** Formats given date in format MM/dd/yyyy hh:mm PM as a string
 * for presentational purposes only.
 * Handles string or Date that can be undefined.
 */
export const formatDate = (
  date?: string | Date | null,
  format: string = 'MM/dd/yyyy hh:mm a',
  locale = enUS
): string => {
  if (date) {
    return formatFunction(new Date(date), format, { locale: locale });
  }
  return '';
};

/** Formats the provided date into a string that includes the time
 * down to seconds to the second before START of day. Used to get the START of a day. */
export const formatDateStart = (date: any, format: string = `MM/dd/yyyy ${'00:00:00'}`): string =>
  formatDate(new Date(date), format);

/** Formats the provided date into a string that includes the time
 * down to seconds to the second before END of day. Used to get the END of a day. */
export const formatDateEnd = (date: any, format: string = `MM/dd/yyyy ${'23:59:59'}`): string =>
  formatDate(new Date(date), format);
