import React from 'react';
import { GetRUM } from 'awsRUM';
import { Box, Typography, Link } from '@mui/material';

interface IErrorBoundary {
  hasError: boolean;
  error?: any;
  children?: any;
}

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false } as IErrorBoundary;

    // Note: We do this to initialize RUM
    // eslint-disable-next-line
    GetRUM();
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const awsRUM = GetRUM();
    if (awsRUM) {
      try {
        awsRUM.recordError(error);
      } catch {
        // Ignore all rum related errors.
      }
    }
  }

  render() {
    const { hasError, error } = this.state as IErrorBoundary;

    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback error={error} />;
    }

    return this.props.children;
  }
}

const ErrorFallback = ({ error }) => (
  <Box sx={{ m: 2, padding: 4, display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h4" gutterBottom>
      Something went wrong
    </Typography>
    {error.message ? <Box sx={{ fontSize: 16, mt: 2 }}>Message: {error.message}</Box> : null}
    <Box sx={{ fontSize: 16, mt: 4 }}>
      <Link href="/">Continue To Home Page</Link>
    </Box>
  </Box>
);

const errorBoundary = (WrappedComponent) =>
  class extends ErrorBoundary {
    render() {
      const { hasError, error } = this.state;

      if (hasError) {
        // You can render any custom fallback UI
        return <ErrorFallback error={error} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };

export { errorBoundary };
