export default function timeAgo(startDate?: Date | string, endDate: Date | string = new Date()) {
  if (!startDate) return '';

  const diff = Math.floor(new Date(endDate).getTime() - new Date(startDate).getTime());
  const secsDiff = Math.floor(diff / 1000);
  const minsDiff = Math.floor(secsDiff / 60);
  const hoursDiff = Math.floor(minsDiff / 60);
  const daysDiff = Math.floor(hoursDiff / 24);
  const monthsDiff = Math.floor(daysDiff / 31);

  const months = Math.floor(monthsDiff % 12);
  const days = Math.floor(daysDiff % 31);
  const hours = Math.floor(hoursDiff % 24);
  const mins = Math.floor(minsDiff % 60);
  const years = Math.floor(monthsDiff / 12);

  if (years > 0) {
    return `${years} years ago`;
  }
  if (months > 0) {
    return `${months} months ago`;
  }
  if (days > 0) {
    return `${days} days ago`;
  }
  if (hours > 0) {
    return `${hours} hours ago`;
  }

  return `${mins} minutes ago`;
}
