import React from 'react';
import { appPrimaryDark } from 'styling/theming';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

/*
Component which encapsulates presenting indication 
that something is loading in the form of a "spinner" 
*/
const LoadingIndicator = ({
  color,
  loading = false,
  margin = 6,
  size = 120,
  thickness = 3,
  mr = 'auto',
}: {
  color?: string;
  loading?: boolean;
  margin?: number;
  size?: string | number;
  thickness?: number;
  mr?: string;
}) => {
  const primaryColorCircularProgress = color ? color : appPrimaryDark;

  if (loading === undefined || loading === false) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        marginRight: mr,
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          margin: margin,
          color: primaryColorCircularProgress,
          animationDuration: '1000ms',
        }}
        size={size}
        thickness={thickness}
      />
    </Box>
  );
};

export default React.memo(LoadingIndicator);
