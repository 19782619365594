//** Formats given value for display purposes adding dollar sign prefix */
export const formatCurrency = (amount?: number, maximumFractionDigits = 2): string => {
  if (amount !== undefined && amount !== null) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: maximumFractionDigits,
    }).format(amount);
  }
  return '';
};
