export function capitalize(value, all = false) {
  if (value && value.length > 0) {
    if (all) {
      return value.toUpperCase();
    } else {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
  return value;
}
