import { io } from 'socket.io-client';
import { REACT_APP_FMS_API_URL } from 'config';
import BroadcastNotificationEvent from 'models/Shared/BroadcastNotificationEvent';

const socket = io(REACT_APP_FMS_API_URL, {
  transports: ['websocket', 'polling', 'webtransport'],
  autoConnect: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 10000,
  reconnectionDelayMax: 15000,
});

const BroadcastNotificationService = () => {
  const publish = (topic: string, e: BroadcastNotificationEvent) => {
    socket.emit(topic, e);
  };

  const subscribe = (topic: string, handler: (e: BroadcastNotificationEvent) => void) => {
    socket.on(topic, handler);
  };

  const subscribeMany = (topics: string[], handler: (e: BroadcastNotificationEvent) => void) => {
    topics.forEach((topic) => {
      socket.on(topic, handler);
    });
  };

  const subscribeAny = (handler: (topic: string, e: BroadcastNotificationEvent) => void) => {
    socket.onAny(handler);
  };

  const unsubscribe = (topic: string, handler: (e: BroadcastNotificationEvent) => void) => {
    socket.off(topic, handler);
  };

  const unsubscribeAny = (handler: (topic: string, e: BroadcastNotificationEvent) => void) => {
    socket.offAny(handler);
  };

  const unsubscribeMany = (topics: string[], handler: (e: BroadcastNotificationEvent) => void) => {
    topics.forEach((topic) => {
      socket.off(topic, handler);
    });
  };

  return {
    publish,
    subscribe,
    subscribeAny,
    subscribeMany,
    unsubscribe,
    unsubscribeAny,
    unsubscribeMany,
  };
};

export default BroadcastNotificationService;
