import LocalCacheService from 'services/LocalCacheService';

const BookmarksService = () => {
  const localCacheService = LocalCacheService();

  const getBookmarks = () => localCacheService.get('bookmarks', []);

  const writeBookmark = (id, applicationId, companyName) => {
    const currentBookmarks = getBookmarks();
    if (bookmarkExists(id)) {
      const newBookmarks = currentBookmarks.filter((b) => b.id !== id);
      localCacheService.set('bookmarks', newBookmarks);
    } else {
      const bookmark = {
        id,
        applicationId,
        companyName,
        createdAt: new Date(),
      };
      const newBookmarks = [...currentBookmarks, bookmark];
      localCacheService.set('bookmarks', newBookmarks);
    }
  };

  const removeBookmark = (id: string) => {
    const currentBookmarks = getBookmarks();
    const newBookmarks = currentBookmarks.filter((bookmark) => bookmark.id !== id);
    localCacheService.set('bookmarks', newBookmarks);
  };

  const clearBookmarks = () => {
    localCacheService.remove('bookmarks');
  };

  const bookmarkExists = (id) => {
    const bookmarks = getBookmarks();
    if (bookmarks.filter((b) => b.id === id).length) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getBookmarks,
    writeBookmark,
    removeBookmark,
    clearBookmarks,
  };
};

export default BookmarksService;
