import { Grid, Typography, Link } from '@mui/material';

const NoAccess = () => (
  <Grid container sx={{ p: 2 }}>
    <Grid item xs={12}>
      <h3>Access Denied</h3>
      <Typography>You are not allowed to access this page</Typography>
    </Grid>
    <Grid item xs={12} sx={{ pt: 2 }}>
      <Link href="/">Continue To Home Page</Link>
    </Grid>
  </Grid>
);

export default NoAccess;
