import { useMemo, useState } from 'react';
import { Box, Button, Link, OutlinedInput, SxProps, Typography } from '@mui/material';
import {
  ArrowForward,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { appBrand, appGrey } from 'styling/colors';
import { Form, Formik } from 'formik';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
//services
import { ROLE_CODE_CUSTOMER_SERVICE } from 'services/RolesService';
import ApplicationsService, { APPLICATION_STATUS_ID_FUNDED } from 'services/ApplicationsService';
//models
import Application from 'models/Application/Application';
// hooks and utils
import useAuth from 'hooks/useAuth';

const styles: Record<string, SxProps> = {
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '20px 0',
  },
  searchInput: {
    width: '375px',
    borderRadius: '50px',
    height: '38px',
    marginRight: '16px',
    '& .MuiSvgIcon-root': {
      color: appGrey[600],
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 30px white inset',
    },
  },
  appearTransition: {
    scale: '0',
    transformOrigin: 'center',
    transition: 'scale 100ms ease-out',
    '&.active': {
      scale: '1',
      transition: 'scale 100ms ease-out',
    },
  },
  resultsContainer: {
    boxShadow: '0px 2px 2px 0px #00000029',
    maxHeight: '600px',
    width: '600px',
    position: 'absolute',
    left: 0,
    top: '70px',
    opacity: '1',
    borderRadius: '6px',
    border: `1px solid ${appGrey[200]}`,
    padding: '24px 12px',
    gap: '12px',
    overflowY: 'auto',
    scale: '1 0',
    transition: 'scale 200ms ease-out',
    transformOrigin: 'top',
    '&.active': {
      scale: '1 1',
      transition: 'scale 300ms ease-out',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    '& .title': {
      fontSize: '16px',
    },
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  close: {
    minWidth: 0,
    padding: 0,
    marginRight: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '26px',
    },
  },
  totalResults: {
    backgroundColor: appBrand[800],
    color: '#FFF',
    padding: '0px 5px',
    borderRadius: '50%',
    marginLeft: '5px',
    fontSize: '12px',
    '&.two-digits': {
      padding: '2px 5px',
    },
    '&.three-digits': {
      padding: '5px',
    },
  },
  resultItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${appGrey[200]}`,
    borderRadius: '6px',
    boxShadow: '0px 2px 4px 0px #00000033',
    padding: '12px',
    marginBottom: '12px',
  },
  resultItemContent: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '12px',

    '& .result-title': {
      fontSize: '16px',
      fontWeight: 700,
      width: '100%',
      marginBottom: '6px',
    },
    '& .status': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '5px',
    },
    '& .subtitle': {
      fontWeight: 700,
      fontSize: '12px',
      margin: 0,
    },
    '& .body': {
      fontSize: '12px',
    },
  },
  link: {
    display: 'inline-flex',
    '& .MuiSvgIcon-root': {
      color: appBrand[800],
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '20px',
    width: '50%',
    margin: '24px 0 0 auto',
    color: appGrey[600],
  },
  pagination: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      fontSize: '26px',
    },
  },
  noResults: {
    margin: '20px auto 10px',
    textAlign: 'center',
    color: appGrey[600],
  },
};

interface GlobalSearchPagination {
  pageSize: number;
  pageNumber: number;
  totalResults: number;
  totalPages: number;
}

const PAGE_SIZE = 5;
const paginationInitialValues = {
  pageSize: PAGE_SIZE,
  pageNumber: 1,
  totalResults: 0,
  totalPages: 0,
};

const GlobalSearch = () => {
  const theme = useTheme();
  const { user, userIsInRole } = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Application[]>([]);
  const [pagination, setPagination] = useState<GlobalSearchPagination>(paginationInitialValues);
  const [openResultsContainer, setOpenResultsContainer] = useState<boolean>(false);
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(true);
  const [previousPageDisabled, setPreviousPageDisabled] = useState<boolean>(true);
  const [hasResults, setHasResults] = useState<boolean>(false);

  const applicationsService = useMemo(ApplicationsService, []);
  const userHasCustomerServiceRole = useMemo(
    () => userIsInRole(user, ROLE_CODE_CUSTOMER_SERVICE),
    [user, userIsInRole]
  );

  const searchApplications = async (
    newSearchTerm: string,
    pageNumber: number = pagination.pageNumber
  ) => {
    setSearchTerm(newSearchTerm);
    const searchResponse = await applicationsService.searchApplications({
      searchTerm: newSearchTerm,
      searchFacet: 'global',
      pageSize: pagination.pageSize,
      pageNumber: pageNumber,
    });

    if (searchResponse.success) {
      setPagination((prev) => ({
        ...prev,
        totalResults: searchResponse.total,
        totalPages: searchResponse.totalPages,
      }));

      setSearchResults(searchResponse.data!);
      if (searchResponse?.data?.length === 0) {
        setHasResults(false);
        return;
      }
      setHasResults(true);
      if (searchResponse.totalPages > 1) {
        setNextPageDisabled(false);
      }
    }
  };

  const resetSearch = () => {
    setSearchResults([]);
    setPreviousPageDisabled(true);
    setNextPageDisabled(true);
    setPagination({ ...paginationInitialValues });
  };

  const handleSubmit = async (values) => {
    if (!values.searchTerm) return;
    resetSearch();
    await searchApplications(values.searchTerm, 1);
    setOpenResultsContainer(true);
  };

  const closeResultsContainer = () => {
    setOpenResultsContainer(false);
    resetSearch();
  };

  const numberOfDigits = (resultsLength: number): string => {
    if (resultsLength > 99) {
      return 'three-digts';
    }
    if (resultsLength > 9) {
      return 'two-digits';
    }
    return '';
  };

  const nextPage = async () => {
    await searchApplications(searchTerm, pagination.pageNumber + 1);
    if (pagination.pageNumber + 1 >= pagination.totalPages) {
      setNextPageDisabled(true);
    }
    setPagination({ ...pagination, pageNumber: pagination.pageNumber + 1 });
    setPreviousPageDisabled(false);
  };

  const previousPage = async () => {
    await searchApplications(searchTerm, pagination.pageNumber - 1);
    if (pagination.pageNumber - 1 <= 1) {
      setPreviousPageDisabled(true);
    }
    setPagination({ ...pagination, pageNumber: pagination.pageNumber - 1 });
    setNextPageDisabled(false);
  };

  const showingNow = useMemo(() => {
    const start = pagination.pageNumber * PAGE_SIZE - PAGE_SIZE + 1;
    const end =
      pagination.pageNumber === pagination.totalPages
        ? pagination.totalResults
        : pagination.pageNumber * PAGE_SIZE;

    return `Showing ${start}-${end}`;
  }, [pagination.pageNumber, pagination.totalPages, pagination.totalResults]);

  return (
    <ClickAwayListener onClickAway={closeResultsContainer}>
      <Box sx={styles.container}>
        <Formik initialValues={{ searchTerm: '' }} enableReinitialize onSubmit={handleSubmit}>
          {({ handleChange, values }) => (
            <Form>
              <OutlinedInput
                id="searchTerm"
                onChange={handleChange}
                sx={styles.searchInput}
                placeholder="Search"
                endAdornment={<Search />}
              />

              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={styles.appearTransition}
                className={values?.searchTerm ? 'active' : ''}
              >
                Search
              </Button>
            </Form>
          )}
        </Formik>

        <Box
          sx={{ ...styles.resultsContainer, backgroundColor: theme.palette.background.paper }}
          className={openResultsContainer ? 'active' : ''}
        >
          <Box sx={styles.header}>
            <Box>
              <Button onClick={closeResultsContainer} sx={styles.close}>
                <Close />
              </Button>
              <Typography variant="h6" sx={{ color: appBrand[800] }}>
                Search Results
              </Typography>
            </Box>
            {hasResults && (
              <Box>
                <Typography variant="body2">{showingNow} of</Typography>
                <Box sx={styles.totalResults} className={numberOfDigits(searchResults.length)}>
                  {pagination.totalResults > 99 ? '99+' : pagination.totalResults}
                </Box>
              </Box>
            )}
          </Box>
          {hasResults ? (
            searchResults.map((result) => (
              <Box sx={styles.resultItem} key={result.applicationId}>
                <Box sx={styles.resultItemContent}>
                  <Typography variant="subtitle1" className="result-title">
                    {result.company?.legalBusinessName}
                  </Typography>
                  <Box className="status">
                    <Typography variant="subtitle2" className="subtitle">
                      ID:
                    </Typography>
                    <Typography variant="body2" className="body">
                      {result?.identifier}
                    </Typography>
                  </Box>
                  <Box className="status">
                    <Typography variant="subtitle2" className="subtitle">
                      Status:
                    </Typography>
                    <Typography variant="body2" className="body">
                      {result.status?.applicationStatusName}
                    </Typography>
                  </Box>
                  <Box className="status">
                    <Typography variant="subtitle2" className="subtitle">
                      Partner:
                    </Typography>
                    <Typography variant="body2" className="body">
                      {result?.partner?.partnerName}
                    </Typography>
                  </Box>
                  <Box className="status">
                    <Typography variant="subtitle2" className="subtitle">
                      Product:
                    </Typography>
                    <Typography variant="body2" className="body">
                      {result?.product?.productName}
                    </Typography>
                  </Box>
                </Box>
                {result.status?.applicationStatusId === APPLICATION_STATUS_ID_FUNDED &&
                userHasCustomerServiceRole ? (
                  <Link href={`/customer-service/summary/${result.applicationId}`} sx={styles.link}>
                    <ArrowForward />
                  </Link>
                ) : (
                  <Link href={`/application/summary/${result.applicationId}`} sx={styles.link}>
                    <ArrowForward />
                  </Link>
                )}
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={styles.noResults}>
              No results found
            </Typography>
          )}
          {hasResults && (
            <Box sx={styles.footer}>
              <Typography variant="body2">
                Page {pagination.pageNumber} of {pagination.totalPages}
              </Typography>
              <Box sx={styles.pagination}>
                <Button onClick={previousPage} disabled={previousPageDisabled}>
                  <KeyboardArrowLeft />
                </Button>
                <Button onClick={nextPage} disabled={nextPageDisabled}>
                  <KeyboardArrowRight />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default GlobalSearch;
