import { GridColDef } from '@mui/x-data-grid-pro';
import { formatCurrency } from 'utils/numbers/formatCurrency';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { appDarkestGrey, appLightestGrey, appMedGrey, appPrimaryVeryLight } from './theming';

export const MUIDataGridProColumnHeaderWrapStyle = {
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
  },
};

export const MUIDataGridProColumnHeaderBoldWrapStyle = {
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    fontWeight: 'bold',
  },
};

export const usdPrice: Partial<GridColDef> = {
  type: 'number',
  width: 130,
  valueFormatter: (value) => formatCurrency(value, 2),
  cellClassName: 'font-tabular-nums',
};

export const BoxGridContainer = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-row.even': {
    backgroundColor: theme.palette.mode === 'light' ? appLightestGrey : appDarkestGrey,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? appPrimaryVeryLight : appMedGrey,
    },
  },
  '& .MuiDataGrid-row.odd': {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? appPrimaryVeryLight : appMedGrey,
    },
  },
}));
