import fmsServices from 'services/fmsServices';
// models
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ApiResponse from 'models/API/ApiResponse';
import RolesSearch from 'models/Users/RolesSearch';
import Role from 'models/Users/Role';

export const ROLE_CODE_ADMIN = 'admin';
export const ROLE_CODE_PROCESSOR = 'processor';
export const ROLE_CODE_EXTERNAL_PROCESSOR = 'external-processor';
export const ROLE_CODE_UNDERWRITER = 'underwriter';
export const ROLE_CODE_CLOSER = 'closer';
export const ROLE_CODE_ACCOUNTING = 'accounting';
export const ROLE_CODE_COMPLIANCE = 'compliance';
export const ROLE_CODE_QUALITY_ASSURANCE = 'quality-assurance';
export const ROLE_CODE_PARTICIPATION_MANAGER = 'participation-manager';
export const ROLE_CODE_PARTICIPATION_ALLOCATION_ADMIN = 'participation-allocation-admin';
export const ROLE_CODE_FUNDING_APPROVER = 'funding-approver';
export const ROLE_CODE_CUSTOMER_SERVICE = 'customer-service';
export const ROLE_CODE_SYSTEM_ADMIN = 'system-admin';
export const ROLE_CODE_SYSTEM_QA = 'system-qa';
export const ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN = 'institutional-commission-admin';
export const ROLE_CODE_INSTITUTIONAL_SALES = 'institutional-sales';
export const ROLE_CODE_BUSINESS_DEVELOPMENT = 'business-development';
export const ROLE_CODE_FUNDER_MANAGER = 'funder-manager';
export const ROLE_CODE_EXECUTIVE = 'executive';
export const ROLE_CODE_SENIOR_MANAGING_INSTITUTIONAL_SALES_DIRECTOR =
  'senior-managing-institutional-sales-director';
export const ROLE_CODE_PARTNER_MANAGER = 'partner-manager';

export const LEGAL_ROLE_CODE = 'legal';
export const AUDITOR_ROLE_CODE = 'auditor';

export const ROLE_NOT_ALLOWED_MESSAGE = 'Your role does not allow you to perform this action';

/** 
This service provides data for "Roles" which is one core entity 
within the system that is the main model for representing 
on of the roles a given user can have in the system
*/
const RolesService = () => {
  const search = async (rolesSearch: RolesSearch): Promise<ApiResponsePaged> => {
    const response = await fmsServices().post<RolesSearch, ApiResponsePaged>(
      '/roles/search',
      rolesSearch
    );
    return response;
  };

  const upsertRole = async (data: Role, mode: string) => {
    if (mode === 'add') {
      delete data.roleId;
    }
    const response = await fmsServices().post<Role, ApiResponse>(`/roles/upsert`, data);
    return response;
  };

  const getRolePrivileges = async (roleId: string): Promise<ApiResponse> => {
    const roleRequest = { roleId };
    const getPrivilegesResponse = await fmsServices().post<any, ApiResponse>(
      '/roles/privileges',
      roleRequest
    );
    return getPrivilegesResponse;
  };

  const upsertRolePrivilege = async (roleId: string, privilegeId: string): Promise<any> => {
    const payload = { roleId, privilegeId };
    const getResponse = await fmsServices().post<any, any>('/roleprivileges/upsert', payload);
    return getResponse;
  };

  const deleteRolePrivilege = async (roleId: string, privilegeId: string): Promise<any> => {
    const payload = { roleId, privilegeId };
    const getResponse = await fmsServices().post<any, any>('/roleprivileges/remove', payload);
    return getResponse;
  };

  return {
    search,
    upsertRole,
    getRolePrivileges,
    upsertRolePrivilege,
    deleteRolePrivilege,
  };
};

export default RolesService;
