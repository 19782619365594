import { createContext, ReactNode, useReducer, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { decodeJwtToken } from 'utils/jwt';
import fmsServices from 'services/fmsServices';
import ApiResponse from 'models/API/ApiResponse';
import ActionMap from 'models/Auth/ActionMap';
import AuthState from 'models/Auth/AuthState';
import AuthUser from 'models/Auth/AuthUser';
import AuthContextType from 'models/Auth/AuthContextType';
import Role from 'models/Users/Role';
import {
  AUTH_ENDPOINT,
  AUTH_TOKEN_NAME,
  LOGIN_URL,
  SESSION_EXPIRED_URL,
  PATH_AFTER_LOGIN,
  USER_PROFILE_KEY,
  PATH_AFTER_LOGIN_CUSTOMER_SERVICE,
  LOGOUT_ENDPOINT,
} from 'config';
import {
  ROLE_CODE_ADMIN,
  ROLE_CODE_CUSTOMER_SERVICE,
  ROLE_CODE_SYSTEM_QA,
} from 'services/RolesService';
import { getFirstCharacter } from 'utils/getFirstCharacter';
import { getAvatarColor } from 'utils/avatar';
import Application from 'models/Application/Application';

const LOGIN_PATH = '/auth/login';
const AUTH_EXPIRED_PATH = '/auth/expired';
const reRoutablePaths = [LOGIN_PATH, AUTH_EXPIRED_PATH];

const isReroutablePath = (path: string) => reRoutablePaths.includes(path);

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  init = 'INITIALIZE',
  login = 'LOGIN',
  logout = 'LOGOUT',
  impersonate = 'IMPERSONATE',
}

type AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.logout]: undefined;
  [Types.impersonate]: {
    user: AuthUser;
  };
};

type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

const reducer = (state: AuthState, action: AuthActions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === Types.impersonate) {
    const { user } = action.payload;
    return { ...state, user };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: false,
      user: null,
    };
  }
  return state;
};

const AuthContextFMS = createContext<AuthContextType | null>(null);

const userIsAdmin = (user: AuthUser): boolean => {
  if (!!user?.impersonatedRoles?.length) {
    return user.impersonatedRoles.some((r) => r.roleCode === ROLE_CODE_ADMIN);
  }

  if (!user) {
    throw new Error('User is required.');
  }
  if (!user.roles) {
    throw new Error('User role is required.');
  }
  return user.roles.some((r) => r.roleCode === ROLE_CODE_ADMIN);
};

const userIsOnApplicationTeam = (user: AuthUser, application?: Application): boolean =>
  application?.team?.some((member) => member.user.userId === user.id) ?? false;

const userIsInRoles = (user: AuthUser, roleCodes: string[]): boolean => {
  if (!!user?.impersonatedRoles?.length) {
    return roleCodes.some((roleCode) =>
      user.impersonatedRoles!.map((userRole) => userRole.roleCode).includes(roleCode)
    );
  }

  if (!user.roles) {
    throw new Error('User role is required.');
  }

  // The System QA role must always be directly assigned. Even the Admin role
  // should NOT have access to System QA unless the user is specifically assigned
  // the System QA role. In the Prod environment, no one is to be assigned the
  // System QA role.
  if (roleCodes.length === 1 && roleCodes.includes(ROLE_CODE_SYSTEM_QA)) {
    return roleCodes.some((roleCode) =>
      user.roles!.map((userRole) => userRole.roleCode).includes(roleCode)
    );
  }

  if (userIsAdmin(user)) return true;
  return roleCodes.some((roleCode) =>
    user.roles!.map((userRole) => userRole.roleCode).includes(roleCode)
  );
};

const userHasPrivilege = (user: AuthUser, privilegeCode: string): boolean => {
  if (userIsAdmin(user)) {
    return true;
  }
  if (!user || !user.roles) {
    throw new Error('User roles are required.');
  }
  let privilegeResult = false;
  user.roles.forEach((r) => {
    if (r.privileges) {
      r.privileges.forEach((p) => {
        if (p.privilegeCode === privilegeCode) {
          privilegeResult = true;
        }
      });
    }
  });
  return privilegeResult;
};

const userPrivilegesContain = (user: AuthUser, privilegeCodes: string[]): boolean => {
  if (privilegeCodes.length === 0) {
    throw new Error('privilegeCodes are required.');
  }
  let privilegeResult = false;
  privilegeCodes.forEach((code) => {
    if (userHasPrivilege(user, code)) {
      privilegeResult = true;
    }
  });
  return privilegeResult;
};

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useMemo(() => {
    if (state.isAuthenticated) {
      return true;
    }
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    if (token) {
      const userString = localStorage.getItem(USER_PROFILE_KEY);
      const user = userString ? JSON.parse(userString) : null;
      dispatch({
        type: Types.init,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
      return true;
    } else {
      return false;
    }
  }, [state.isAuthenticated]);

  const doCheckAuthHasExpired = useCallback(() => {
    let expired = false;
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    if (!token || token.length === 0) {
      expired = true;
    }
    let tokenDecoded = decodeJwtToken(token) as any;
    if (tokenDecoded && (tokenDecoded.exp as any)) {
      const expirationMilliseconds = tokenDecoded.exp * 1000;
      if (expirationMilliseconds < Date.now()) {
        expired = true;
      }
      expired = false;
    }
    return state.isAuthenticated === true && expired === true;
  }, [state.isAuthenticated]);

  const persistTokenAndUser = useCallback(
    (token: string) => {
      const tokenDecoded = decodeJwtToken(token) as any;

      const userName = `${tokenDecoded.firstName ?? ''} ${tokenDecoded.lastName ?? ''}`.trim();

      const user = {
        id: tokenDecoded.userId,
        initials: getFirstCharacter(userName),
        avatarColor: getAvatarColor(),
        username: userName,
        displayName: userName,
        roles: tokenDecoded.roles,
      } as AuthUser;

      localStorage.setItem(AUTH_TOKEN_NAME, token);
      localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));

      dispatch({
        type: Types.init,
        payload: {
          isAuthenticated: true,
          user,
        },
      });

      return user;
    },
    [dispatch]
  );

  const doLogin = useCallback(
    (email: string, password: string) => {
      const { postUnAuthenticated } = fmsServices();
      const validateCredentials = async (email: string, password: string) => {
        const response = await postUnAuthenticated<any, ApiResponse>(AUTH_ENDPOINT, {
          email,
          password,
        });
        const { data } = response;
        if (response.status === 200 && data && data.token) {
          const user = persistTokenAndUser(data.token);

          const userHasCustomerServiceRole = user?.roles?.some(
            (r) => r.roleCode === ROLE_CODE_CUSTOMER_SERVICE
          );

          const roleBasedLoginPath = userHasCustomerServiceRole
            ? PATH_AFTER_LOGIN_CUSTOMER_SERVICE
            : PATH_AFTER_LOGIN;

          // Supporting the ability to be directed to FMS when not logged and prompting to the
          // user to login and then route them to the proper destination.
          navigate(isReroutablePath(location.pathname) ? roleBasedLoginPath : location.pathname);
        } else {
          return response;
        }
      };

      // validate credentials with API
      return validateCredentials(email, password);
    },
    [persistTokenAndUser, navigate, location]
  );

  const resetPassword = async (token: string, password: string) => {
    const { postUnAuthenticated } = fmsServices();
    const response = await postUnAuthenticated<any, ApiResponse>(`/auth/reset-auth`, {
      token,
      password,
    });
    return response;
  };

  const doResetPassword = useCallback(
    (token: string, password: string) => resetPassword(token, password),
    []
  );

  const doRequestResetPassword = useCallback((email: string) => requestResetPassword(email), []);

  const requestResetPassword = async (email: string) => {
    const { postUnAuthenticated } = fmsServices();
    const response = await postUnAuthenticated<any, ApiResponse>(`/auth/request-reset`, {
      emailAddress: email,
    });
    return response;
  };

  const doLogOut = useCallback(
    async (sessionExpired: boolean) => {
      try {
        const token = localStorage.getItem(AUTH_TOKEN_NAME);

        if (token) {
          const tokenDecoded = decodeJwtToken(token) as { userId: string };

          await fmsServices().postUnAuthenticated(LOGOUT_ENDPOINT, { userId: tokenDecoded.userId });
        }
      } catch (error: any) {
        console.error(`Error while request an user logout. Message: ${error.message ?? 'N/A'}`);
      }

      localStorage.removeItem(AUTH_TOKEN_NAME);
      localStorage.removeItem(USER_PROFILE_KEY);
      dispatch({ type: Types.logout });

      if (sessionExpired) {
        navigate(SESSION_EXPIRED_URL);
      } else {
        navigate(LOGIN_URL);
      }
    },
    [dispatch, navigate]
  );

  const checkUserIsAdmin = useCallback((user: AuthUser) => userIsAdmin(user), []);

  const checkUserIsOnApplicationTeam = useCallback(
    (user: AuthUser, application: Application) => userIsOnApplicationTeam(user, application),
    []
  );

  const checkUserIsInRole = useCallback(
    (user: AuthUser, roleCode: string) => userIsInRoles(user, [roleCode]),
    []
  );

  const checkUserIsInRoles = useCallback(
    (user: AuthUser, roleCodes: string[]) => userIsInRoles(user, roleCodes),
    []
  );

  const checkHasPrivilege = useCallback(
    (user: AuthUser, privilegeCode: string) => userHasPrivilege(user, privilegeCode),
    []
  );

  const checkPrivilegesContain = useCallback(
    (user: AuthUser, privilegeCodes: string[]) => userPrivilegesContain(user, privilegeCodes),
    []
  );

  const setImpersonationRoles = useCallback(
    (user: AuthUser, roles: Role[]) => {
      const updatedUser = { ...user, impersonatedRoles: [...roles] };
      dispatch({
        type: Types.impersonate,
        payload: {
          // @ts-ignore
          user: updatedUser,
        },
      });
    },
    [dispatch]
  );

  const authValues = useMemo(
    () => ({
      ...state,
      user: state.user,
      isAuthenticated: isAuthenticated,
      isInitialized: state.isInitialized,
      authHasExpired: doCheckAuthHasExpired,
      login: doLogin,
      logout: doLogOut,
      requestResetPassword: doRequestResetPassword,
      resetPassword: doResetPassword,
      userIsAdmin: checkUserIsAdmin,
      userIsOnApplicationTeam: checkUserIsOnApplicationTeam,
      userIsInRole: checkUserIsInRole,
      userIsInRoles: checkUserIsInRoles,
      userHasPrivilege: checkHasPrivilege,
      userPrivilegesContain: checkPrivilegesContain,
      setImpersonationRoles,
    }),
    [
      state,
      doLogOut,
      doLogin,
      doResetPassword,
      doRequestResetPassword,
      checkUserIsAdmin,
      checkUserIsOnApplicationTeam,
      checkUserIsInRole,
      checkUserIsInRoles,
      checkHasPrivilege,
      checkPrivilegesContain,
      isAuthenticated,
      doCheckAuthHasExpired,
      setImpersonationRoles,
    ]
  );

  return <AuthContextFMS.Provider value={authValues as any}>{children}</AuthContextFMS.Provider>;
}

export { AuthContextFMS, AuthProvider };
