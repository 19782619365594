import fmsServices from 'services/fmsServices';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import TransactionType from 'models/Transactions/TransactionType';
import TransactionSearch from 'models/Transactions/TransactionsSearch';
import Transaction from 'models/Transactions/Transaction';
import TransactionCreateRequest from 'models/Transactions/TransactionCreateRequest';
import ApiResponse from 'models/API/ApiResponse';
import TransactionUpsertRequest from 'models/Transactions/TransactionUpsertRequest';
import TransactionEvent from 'models/Transactions/TransactionEvent';
import TransactionStatus from 'models/Transactions/TransactionStatus';
import TransactionWorkflow from 'models/Transactions/TransactionWorkflow';
import TransactionsReportSearch from 'models/Transactions/TransactionsReportSearch';
import TransactionDealBalanceInfo from 'models/Transactions/TransactionDealBalanceInfo';
import BulkTransactionStatusUpdate from 'models/Transactions/BulkTransactionStatusUpdate';
import TransactionReportItem from 'models/Transactions/TransactionsReportItem';
import TransactionProvider from 'models/Transactions/TransactionProvider';

export const TRANSACTION_DIRECTION_DEBIT = 'debit';
export const TRANSACTION_DIRECTION_CREDIT = 'credit';

export const TRANSACTION_TYPE_ACH = 'ach';
export const TRANSACTION_TYPE_WIRE = 'wire';

export const TRANSACTION_TYPE_ID_ACH_LIBERTAS_ERROR_MERCHANT_CREDIT =
  'b5aec556-b9a5-4503-9043-e86d7d98baad';

// TRANSACTION STATUS IDS
export const TRANSACTION_STATUS_ID_REVIEW_REQUIRED = '461ea16c-cf10-4ef6-8d6a-85f86ffa483b';
export const TRANSACTION_STATUS_ID_READY_TO_SEND = '20c4cdd7-9687-4259-8aec-9e2a744fa4d2';

export const TRANSACTION_STATUS_ID_CANCELLED = '9170307d-3d08-2ee1-7f82-5fc4cb046d7b';

export const TRANSACTION_STATUS_ID_PROCESSING = 'c76bbbd8-c6c0-41d5-a950-bae1c8275c20';
export const TRANSACTION_STATUS_ID_COMPLETED = '627e6c0a-eda5-46d8-a5e6-c962cbd8e233';
export const TRANSACTION_STATUS_ID_PROCESSING_FAILED = '7eea6b6a-4ce1-41b9-b2f3-40bf7aeab16e';

export const TRANSACTION_STATUS_ID_RETURN_OCCURRED = 'db066d56-9808-49ff-aeec-e9ed9fdfcfd2';
export const TRANSACTION_STATUS_ID_COMPLETED_NO_RETURN = '42f9eb9b-1210-4c9d-8780-f77c8f7ad429';

// TRANSACTION TYPES IDS
// Disbursal
export const TRANSACTION_TYPE_ID_ACH_ADVANCE_MERCHANT_CREDIT_INITIAL =
  '209cffb6-9cbf-4109-93e1-c01e0652df3d'; // “ACH Advance Merchant Credit - Initial”

// TRANSACTION EVENT IDS
export const TRANSACTION_EVENT_ID_FUNDING_GATHER_PAYOUTS = '74b0adca-8e52-4f39-acaf-e8ff9a93714c';
export const TRANSACTION_EVENT_ID_FUNDING_SEND_PAYOUTS = '615175b5-53f6-422e-a2a7-64ad670ce550';
export const TRANSACTION_EVENT_ID_SERVICING_RECEIVE_REMITTANCE =
  '311c3811-a7e7-4499-b932-cb6f98cbb471';
export const TRANSACTION_EVENT_ID_SERVICING_SEND_REMITTANCE =
  '79739de6-57e7-4099-8ac7-7fc95de27176';
export const TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT = '9f3da0e0-ee1b-7b4a-909d-896da9b46d8f';

export const TRANSACTION_EVENT_ID_RETURN_RECEIVE_RETURN = '7cabc6f1-2a92-5e69-17e1-0defa2b49d41';

const TransactionService = () => {
  const getTransactionTypes = async (): Promise<ApiResponsePaged<TransactionType[]>> => {
    try {
      const response = await fmsServices().post<
        TransactionType,
        ApiResponsePaged<TransactionType[]>
      >('/transactions/types');
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<TransactionType[]>;
    }
  };

  const getTransactionStatuses = async (): Promise<ApiResponsePaged<TransactionStatus[]>> => {
    try {
      const response = await fmsServices().post<any, ApiResponsePaged<TransactionStatus[]>>(
        '/transactions/statuses'
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<TransactionStatus[]>;
    }
  };

  const getTransactionEvents = async (): Promise<ApiResponsePaged<TransactionEvent[]>> => {
    try {
      const response = await fmsServices().post<
        TransactionEvent,
        ApiResponsePaged<TransactionEvent[]>
      >('/transactions/events');
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<TransactionEvent[]>;
    }
  };

  const searchTransactions = async (
    transactionSearch: TransactionSearch
  ): Promise<ApiResponsePaged<Transaction[]>> => {
    try {
      const response = await fmsServices().post<TransactionSearch, ApiResponsePaged<Transaction[]>>(
        '/transactions/search',
        transactionSearch
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<Transaction[]>;
    }
  };

  const createTransaction = async (
    transaction: TransactionCreateRequest
  ): Promise<ApiResponse<Transaction>> => {
    try {
      const response = await fmsServices().post<TransactionCreateRequest, ApiResponse<Transaction>>(
        '/transactions/create',
        transaction
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<Transaction>;
    }
  };

  const upsertTransaction = async (
    transaction: TransactionUpsertRequest
  ): Promise<ApiResponse<Transaction>> => {
    try {
      const response = await fmsServices().post<TransactionUpsertRequest, ApiResponse<Transaction>>(
        '/transactions/upsert',
        transaction
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<Transaction>;
    }
  };

  const performWorkflow = async (
    workflow: TransactionWorkflow
  ): Promise<ApiResponse<Transaction>> => {
    try {
      return fmsServices().post<TransactionWorkflow, ApiResponse>(
        '/transactions/workflow',
        workflow
      );
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const getTransactionReport = async (
    search: TransactionsReportSearch
  ): Promise<ApiResponsePaged<TransactionReportItem[]>> => {
    try {
      return await fmsServices().post<
        TransactionsReportSearch,
        ApiResponsePaged<TransactionReportItem[]>
      >('/transactions/report', search);
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        TransactionReportItem[]
      >;
    }
  };

  const getDealBalanceInfo = async (fundingId: string) => {
    const response = await fmsServices().get<any, ApiResponse<TransactionDealBalanceInfo[]>>(
      `/transactions/deal-balance-info/${fundingId}`
    );
    return response;
  };

  const bulkUpdateTransactionStatus = async (
    bulkTransactionUpdateRequest: BulkTransactionStatusUpdate
  ) =>
    await fmsServices().post<BulkTransactionStatusUpdate, ApiResponse>(
      '/transactions/bulk-status-update',
      bulkTransactionUpdateRequest
    );

  const getTransactionProviders = async (): Promise<ApiResponse<TransactionProvider[]>> => {
    const response = await fmsServices().get<null, ApiResponse<TransactionProvider[]>>(
      '/transactions/search/providers'
    );

    return response;
  };

  return {
    getTransactionTypes,
    getTransactionStatuses,
    getTransactionEvents,
    searchTransactions,
    createTransaction,
    upsertTransaction,
    performWorkflow,
    getTransactionReport,
    getDealBalanceInfo,
    bulkUpdateTransactionStatus,
    getTransactionProviders,
  };
};

export default TransactionService;
