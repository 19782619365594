import Funding from 'models/Funding/Funding';
import JournalType from 'models/Accounting/JournalType';
import JournalGroup from 'models/Accounting/JournalGroup';
import Transaction from 'models/Transactions/Transaction';

export default interface Journal {
  journalId?: string;
  journalType?: JournalType;
  journalTypeId?: string;
  journalGroupId?: string;
  journalGroup?: JournalGroup;
  fundingId?: string;
  transactionId?: string;
  transaction?: Transaction;
  modelId?: string;
  signedAmount: number;
  absoluteAmount: number;
  creditOrDebit: string;
  description: string;
  notes?: string;
  eedDate?: Date;
  journalMonth?: number;
  journalYear?: number;
  createdBy?: string;
  createdByName?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedByName?: string;
  updatedAt?: Date;
  deletedAt?: Date;
  funding?: Funding;
}

export const defaultJournal: Journal = {
  signedAmount: 0,
  absoluteAmount: 0,
  creditOrDebit: '',
  description: '',
};
