import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { appBrand } from 'styling/colors';
import { appPrimaryDark } from 'styling/theming';

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey.A100 : theme.palette.grey.A400,
  py: 0.5,
  pl: 2,
  borderRadius: '6px',
}));

const TypographyContainer = styled(Typography)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey.A100 : theme.palette.grey.A400,
  py: 0.5,
  pl: 2,
  fontSize: '16px',
  fontWeight: '600',
  color: appBrand[800],
  borderRadius: '6px',
}));

const styles = {
  fullWidthTitle: {
    minWidth: '100%',
    borderRadius: '6px',
    fontSize: '20px',
    fontWeight: '700',
    pl: 2,
    color: appPrimaryDark,
  },
  subtitle: {
    fontSize: '12px',
    pl: 1,
  },
};

const SectionTitle = ({
  title,
  sx,
  fullWidth,
  subTitle,
}: {
  title: string;
  sx?: any;
  fullWidth?: boolean;
  subTitle?: string;
}): JSX.Element =>
  fullWidth ? (
    <BoxContainer sx={{ ...sx }}>
      <Typography display="inline" variant="subtitle1" sx={{ ...styles.fullWidthTitle }}>
        {title}
      </Typography>
      <Typography display="inline" variant="subtitle1" sx={{ ...styles.subtitle }}>
        {subTitle}
      </Typography>
    </BoxContainer>
  ) : (
    <TypographyContainer variant="subtitle1" display="flex" sx={{ ...sx }}>
      {title}
      <Typography variant="subtitle2" display="flex" sx={{ pt: 0.5, ...styles.subtitle }}>
        {subTitle}
      </Typography>
    </TypographyContainer>
  );

export default SectionTitle;
