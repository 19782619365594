/** Possible statuses of a banking accounts (Enum) */
enum AccountStatuses {
  Active = '046a336e-9c8a-4721-9545-48ea4f63c79e',
  Closed = '8f2f75c1-261f-4df0-a9f8-164260db117f',
  Pending = 'ffd2f828-1ec3-40e8-8020-031183da6015',
  Suspended = '1845a334-d15d-4a10-b0f3-ad2b6eb3c29a',
  NotWorking = 'c64452cc-94a2-4725-a9ee-206a28708d01',
  Unknown = 'a51065cb-f1e4-4370-8ab5-232de9c200de',
}

export default AccountStatuses;
