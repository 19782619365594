import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ApiResponse from 'models/API/ApiResponse';
import fmsServices from 'services/fmsServices';
import Funder from 'models/Funder/Funder';
import FunderSearch from 'models/Funder/FunderSearch';

/** 
This service provides data for "Funders" which are core entities that provide
the funds given to Companies as part of funded offers
*/
const FunderService = () => {
  const searchFunders = async (search: FunderSearch): Promise<ApiResponsePaged<Funder[]>> => {
    const response = await fmsServices().post<FunderSearch, ApiResponsePaged<Funder[]>>(
      '/funders/search',
      search
    );
    return response;
  };

  const upsertFunder = async (data: Funder, mode: string): Promise<ApiResponse> => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    data.companyState = data.companyState.toUpperCase();
    data.stateOfIncorporation = data.stateOfIncorporation?.toUpperCase();
    data.url = data.url ? data.url : '';
    data.funderLogo = data.funderLogo ? data.funderLogo : '';
    const response = await fmsServices().post<Funder, ApiResponse>('/funders/upsert', data);
    return response;
  };

  return {
    searchFunders,
    upsertFunder,
  };
};

export default FunderService;
