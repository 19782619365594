import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
// Routes
import PageRouter from 'routing/PageRouter';
// Components
import Navigation from 'components/App/Navigation';
import useAuth from 'hooks/useAuth';
import { BookmarkProvider } from 'contexts/BookmarkContext';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const { logout } = useAuth();

  const selectedMenuItemKey = location.pathname;

  const handleSignOut = () => {
    logout(false);
  };

  if (location.pathname && location.pathname.indexOf('/application/document/preview/') > -1) {
    return <PageRouter />;
  }

  return (
    <>
      <CssBaseline />
      <BookmarkProvider>
        <Navigation selectedMenuItemKey={selectedMenuItemKey} handleSignOut={handleSignOut}>
          <PageRouter />
        </Navigation>
      </BookmarkProvider>
    </>
  );
};

export default AuthenticatedLayout;
