import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui Components
import { useTheme } from '@mui/material/styles';
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Badge,
  Box,
  Button,
  Collapse,
  CSSObject,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  ArrowRight,
  Bookmark as BookmarkIcon,
  ChevronLeft,
  ChevronRight,
  Close as CloseIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
// logos
import appLogoSmall from 'assets/Libertas-icon-small.svg';
import appLogoFullWidth from 'assets/Libertas-Small.svg';
import appLogoFullWidthDarkMode from 'assets/Libertas-Small-DarkMode.svg';
// components
import ErrorBoundary from 'components/ErrorBoundary';
import Avatar from 'components/Shared/Avatar';
import Icon from 'components/Shared/Icon';
import ThemeModeToggle from 'components/ThemeModeToggle';
import GlobalSearch from 'components/App/GlobalSearch';
import AppNotification from 'components/App/AppNotification';
// hooks
import useAuth from 'hooks/useAuth';
import { useBookmarks } from 'hooks/useBookmarks';
// services
import RolesService, {
  LEGAL_ROLE_CODE,
  ROLE_CODE_ACCOUNTING,
  ROLE_CODE_ADMIN,
  ROLE_CODE_BUSINESS_DEVELOPMENT,
  ROLE_CODE_CLOSER,
  ROLE_CODE_COMPLIANCE,
  ROLE_CODE_CUSTOMER_SERVICE,
  ROLE_CODE_EXECUTIVE,
  ROLE_CODE_FUNDER_MANAGER,
  ROLE_CODE_FUNDING_APPROVER,
  ROLE_CODE_INSTITUTIONAL_SALES,
  ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN,
  ROLE_CODE_PARTICIPATION_MANAGER,
  ROLE_CODE_PARTNER_MANAGER,
  ROLE_CODE_PROCESSOR,
  ROLE_CODE_EXTERNAL_PROCESSOR,
  ROLE_CODE_QUALITY_ASSURANCE,
  ROLE_CODE_SENIOR_MANAGING_INSTITUTIONAL_SALES_DIRECTOR,
  ROLE_CODE_SYSTEM_ADMIN,
  ROLE_CODE_SYSTEM_QA,
  ROLE_CODE_UNDERWRITER,
  ROLE_CODE_PARTICIPATION_ALLOCATION_ADMIN,
} from 'services/RolesService';
// models
import NavigationMenuItem from 'models/Navigation/NavigationMenuItem';
import SubMenuItem from 'models/Navigation/NavigationSubMenuItem';
import Bookmark from 'models/Bookmark/Bookmark';
// utils
import formatDateDistance from 'utils/date/formatDateDistance';
import { appPrimaryLight, appPrimaryVeryLight } from 'styling/theming';
import { bookmarkColor } from 'styling/colors';
import Role from 'models/Users/Role';
import RolesSearch from 'models/Users/RolesSearch';

const BoxHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  gap: '20px',
  zIndex: 1002,
  position: 'sticky',
  top: 0,
}));

const BoxBookmarkPopover = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  padding: 1,
  marginTop: 1,
  marginBottom: 1,
  '&:hover': {
    borderRadius: 4,
    backgroundColor:
      theme.palette.mode === 'light' ? `${appPrimaryVeryLight}FF` : `${appPrimaryLight}FF`,
    cursor: 'pointer',
  },
}));

const drawerWidth = 240;
const drawerWidthCollapsed = 55;

//** The menu entry data for the side navigation of the application */
export const SideNavigationMenuItems: NavigationMenuItem[] = [
  // Future use
  // {
  //   menuId: 'home',
  //   menuTitle: 'Home',
  //   href: '/home',
  //   menuIcon: 'Dashboard',
  //   open: false,
  //   roles: [],
  // },
  {
    menuId: 'applications',
    menuTitle: 'Applications',
    href: '/applications',
    menuIcon: 'FormatListBulleted',
    open: false,
    roles: [
      ROLE_CODE_PROCESSOR,
      ROLE_CODE_EXTERNAL_PROCESSOR,
      ROLE_CODE_UNDERWRITER,
      ROLE_CODE_CLOSER,
      ROLE_CODE_QUALITY_ASSURANCE,
      ROLE_CODE_COMPLIANCE,
      ROLE_CODE_CUSTOMER_SERVICE,
      ROLE_CODE_ACCOUNTING,
      ROLE_CODE_INSTITUTIONAL_SALES,
      ROLE_CODE_BUSINESS_DEVELOPMENT,
      ROLE_CODE_FUNDING_APPROVER,
      ROLE_CODE_ADMIN,
      ROLE_CODE_FUNDER_MANAGER,
      ROLE_CODE_SYSTEM_QA,
      ROLE_CODE_SYSTEM_ADMIN,
      ROLE_CODE_EXECUTIVE,
      ROLE_CODE_SENIOR_MANAGING_INSTITUTIONAL_SALES_DIRECTOR,
      ROLE_CODE_PARTICIPATION_MANAGER,
    ],
    menuItems: [
      {
        subMenuTitle: 'Rejected',
        href: '/applications/rejected',
        subMenuIcon: 'NoAccounts',
      },
      {
        subMenuTitle: 'Funded',
        href: '/applications/funded',
        subMenuIcon: 'AccountBalance',
      },
    ],
  },
  {
    menuId: 'participation',
    menuTitle: 'Participation',
    menuIcon: 'Groups',
    open: false,
    roles: [
      ROLE_CODE_ADMIN,
      ROLE_CODE_PARTICIPATION_MANAGER,
      ROLE_CODE_CUSTOMER_SERVICE,
      ROLE_CODE_PARTICIPATION_ALLOCATION_ADMIN,
    ],
    menuItems: [
      {
        subMenuTitle: 'Manage',
        href: '/participation/manage',
        subMenuIcon: 'PieChart',
      },
      {
        subMenuTitle: 'Transactions',
        href: '/participation/transactions-manager',
        subMenuIcon: 'Paid',
      },
      {
        subMenuTitle: 'Payoff',
        href: '/participation/participants-payoff',
        subMenuIcon: 'AttachMoney',
      },
      {
        subMenuTitle: 'Participants',
        href: '/participation/participants',
        subMenuIcon: 'PeopleAlt',
      },
    ],
  },
  {
    menuId: 'customer-service',
    menuTitle: 'Customer Service',
    href: '/customer-service',
    menuIcon: 'SupportAgent',
    open: false,
    roles: [
      ROLE_CODE_CLOSER,
      ROLE_CODE_COMPLIANCE,
      ROLE_CODE_CUSTOMER_SERVICE,
      ROLE_CODE_ACCOUNTING,
      ROLE_CODE_INSTITUTIONAL_SALES,
      ROLE_CODE_ADMIN,
      LEGAL_ROLE_CODE,
    ],
  },
  {
    menuId: 'commissions',
    menuTitle: 'Commissions',
    href: '/commissions/user-commissions',
    menuIcon: 'Payments',
    open: false,
    roles: [
      ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN,
      ROLE_CODE_ADMIN,
      ROLE_CODE_INSTITUTIONAL_SALES,
    ],
  },
  {
    menuId: 'accounting',
    menuTitle: 'Accounting',
    menuIcon: 'PointOfSale',
    href: '/accounting/home',
    open: false,
    roles: [ROLE_CODE_CUSTOMER_SERVICE, ROLE_CODE_ACCOUNTING, ROLE_CODE_ADMIN],
    menuItems: [
      {
        subMenuTitle: 'Transactions',
        href: '/accounting/transaction-management',
        subMenuIcon: 'ReceiptLong',
      },
      { subMenuTitle: 'Journals', href: '/accounting/journals/home', subMenuIcon: 'Topic' },
      {
        subMenuTitle: 'IC Pledging',
        href: '/accounting/investment-company-allocation',
        subMenuIcon: 'Business',
      },
      {
        subMenuTitle: 'IC Transactions',
        href: '/accounting/investment-company-transactions',
        subMenuIcon: 'ReceiptLong',
      },
      {
        subMenuTitle: 'Bank Reconciliation',
        href: '/accounting/banking/reconciliation',
        subMenuIcon: 'AccountBalanceWallet',
      },
      {
        subMenuTitle: 'Expected Payments',
        href: '/accounting/expected-payments-manager',
        subMenuIcon: 'Payments',
      },
    ],
  },
  {
    menuId: 'admin',
    menuTitle: 'Admin',
    menuIcon: 'AdminPanelSettings',
    open: false,
    roles: [
      ROLE_CODE_ADMIN,
      ROLE_CODE_SYSTEM_ADMIN,
      ROLE_CODE_FUNDER_MANAGER,
      ROLE_CODE_PARTNER_MANAGER,
      ROLE_CODE_COMPLIANCE,
    ],
    menuItems: [
      { subMenuTitle: 'User/Roles', href: '/admin/user', subMenuIcon: 'PeopleAlt' },
      { subMenuTitle: 'Configuration', href: '/admin/configuration', subMenuIcon: 'Settings' },
      { subMenuTitle: 'Attributes', href: '/admin/attributes', subMenuIcon: 'Work' },
      { subMenuTitle: 'Documents', href: '/admin/documents/types', subMenuIcon: 'Article' },
      { subMenuTitle: 'Funders', href: '/admin/funders', subMenuIcon: 'AssuredWorkload' },
      { subMenuTitle: 'Partners', href: '/admin/partners', subMenuIcon: 'Handshake' },
      { subMenuTitle: 'Industry', href: '/admin/industries', subMenuIcon: 'Factory' },
      { subMenuTitle: 'Credit Tiers', href: '/admin/credit-tier', subMenuIcon: 'PriceCheck' },
      { subMenuTitle: 'Credit Rates', href: '/admin/rates', subMenuIcon: 'Grading' },
      {
        subMenuTitle: 'Diligence Profiles',
        href: '/diligence/profiles',
        subMenuIcon: 'Rule',
      },
      {
        subMenuTitle: 'Investment Co.',
        href: '/admin/investment-companies',
        subMenuIcon: 'Business',
      },
      {
        subMenuTitle: 'Advance Providers',
        href: '/admin/advance-providers',
        subMenuIcon: 'Payments',
      },
      {
        subMenuTitle: 'Interest Rates',
        href: '/admin/interest-rates',
        subMenuIcon: 'TrendingUp',
      },
      {
        subMenuTitle: 'Commissioned Users',
        href: '/admin/commissioned-users',
        subMenuIcon: 'PeopleAlt',
      },
      {
        subMenuTitle: 'System Exceptions',
        href: '/admin/system-admin/exceptions',
        subMenuIcon: 'RunningWithErrors',
      },
      {
        subMenuTitle: 'Scheduled Jobs',
        href: '/admin/system-admin/scheduled-jobs',
        subMenuIcon: 'PendingActions',
      },
    ],
  },
];

const styles: Record<string, SxProps> = {
  appLogo: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '45px',
    height: '45px',
  },
  appLogoFull: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '188px',
    height: '45px',
  },
  headerIconButton: {
    borderRadius: '50px',
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  appShell: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  contentContainer: {
    minWidth: 0,
    marginBottom: 3,
  },
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidthCollapsed + 'px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const LeftNav = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Navigation = ({
  selectedMenuItemKey,
  handleSignOut,
  children,
}: {
  selectedMenuItemKey?: string | undefined;
  handleSignOut: () => void;
  children?: any;
}) => {
  const theme = useTheme();
  const [menu, setMenu] = useState<NavigationMenuItem[]>([]);
  const [openNavigation, setOpenNavigation] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [openBookmarkMenu, setOpenBookmarkMenu] = useState(false);
  const bookmarkRef = useRef<HTMLButtonElement>(null);
  const openMenu = Boolean(anchorElMenu);

  const { bookmarks, writeBookmark, clearBookmarks } = useBookmarks();
  const { user, userIsInRoles, setImpersonationRoles } = useAuth();
  const { initials, avatarColor } = user;

  const [roleData, setRoleData] = useState<Role[]>([]);

  const userCanSystemQA = useMemo(
    () => userIsInRoles(user, [ROLE_CODE_SYSTEM_QA]),
    [user, userIsInRoles]
  );

  const navigate = useNavigate();

  let rolesService = useMemo(() => RolesService(), []);

  const getRolesData = useCallback(async () => {
    const searchRolesResponse = await rolesService.search({} as RolesSearch);
    const { data } = searchRolesResponse;
    setRoleData(data ?? []);
  }, [rolesService, setRoleData]);

  useEffect(() => {
    getRolesData();
  }, [getRolesData]);

  const handleOpenDrawer = () => {
    setOpenNavigation(true);
  };

  const toggleOpenDrawer = () => {
    setOpenNavigation(!openNavigation);
  };

  const handleSignOutClick = () => {
    handleSignOut();
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickBookmarkMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenBookmarkMenu(true);
  };

  const handleCloseBookmarkMenu = () => {
    setOpenBookmarkMenu(false);
  };

  const handleRemoveBookmark = ({ id, applicationId, companyName }: Bookmark) => {
    writeBookmark(id, applicationId, companyName);
  };

  const handleClearBookmarks = () => {
    clearBookmarks();
  };

  const menuItemIsSelected = (menuItemKey: string, locationPath: string) => {
    if (menuItemKey && locationPath) {
      return menuItemKey === locationPath;
    }
    return false;
  };

  const handleCollapseSubMenu = (indexMenu: number) => {
    let [...copy] = menu;
    copy[indexMenu].open = !copy[indexMenu].open;
    setMenu(copy);
  };

  useEffect(() => {
    const filteredMenuItems = SideNavigationMenuItems.filter((menu) => {
      if (menu.roles) {
        return userIsInRoles(user, menu.roles);
      }
      return true;
    });
    setMenu(filteredMenuItems);
  }, [user, userIsInRoles]);

  return (
    <Box id="app-shell" sx={styles.appShell}>
      <LeftNav variant="permanent" open={openNavigation}>
        <DrawerHeader sx={{ width: '' }}>
          <Grid container>
            <IconButton color="inherit" aria-label="menu" disableRipple sx={{ paddingLeft: 0 }}>
              {openNavigation ? (
                <Box
                  component="img"
                  src={theme.palette.mode === 'light' ? appLogoFullWidth : appLogoFullWidthDarkMode}
                  sx={styles.appLogoFull}
                  alt="logo"
                />
              ) : (
                <Box component="img" src={appLogoSmall} sx={styles.appLogo} alt="logo" />
              )}
            </IconButton>
          </Grid>
        </DrawerHeader>
        <List component="nav">
          <>
            <ListItemButton
              key="chevron"
              onClick={() => toggleOpenDrawer()}
              sx={{
                justifyContent: openNavigation ? 'flex-end' : 'flex',
                paddingRight: openNavigation ? '0' : '16px',
              }}
            >
              {openNavigation ? (
                <ListItemIcon sx={{ minWidth: '36px' }}>
                  <ChevronLeft />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              )}
            </ListItemButton>
            <Divider />
            {menu.map((menuItem: NavigationMenuItem, index: number) => (
              <Box key={menuItem.menuId}>
                <ListItemButton
                  key={menuItem.menuId}
                  onClick={() => {
                    handleOpenDrawer();
                    navigate(menuItem.href ?? '#!');
                    if (menuItem?.menuItems?.length) {
                      handleCollapseSubMenu(index);
                    }
                  }}
                  selected={menuItemIsSelected(selectedMenuItemKey!, menuItem.href!)}
                >
                  <ListItemIcon>
                    <Icon iconKey={menuItem.menuIcon} />
                  </ListItemIcon>
                  <ListItemText primary={menuItem.menuTitle} />
                  {menuItem.menuItems && (menuItem.open ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {menuItem.menuItems &&
                  (openNavigation ? (
                    <Collapse in={menuItem.open} timeout="auto">
                      <List disablePadding component="div">
                        {menuItem.menuItems.map((subMenuItem: SubMenuItem) => (
                          <ListItemButton
                            key={subMenuItem.subMenuTitle}
                            sx={{ pl: 4 }}
                            onClick={() => {
                              navigate(subMenuItem.href ?? '');
                            }}
                            selected={menuItemIsSelected(selectedMenuItemKey!, subMenuItem.href!)}
                          >
                            <ListItemIcon sx={{ pl: 2, fontSize: '10px' }}>
                              {subMenuItem.subMenuIcon ? (
                                <Icon iconKey={subMenuItem.subMenuIcon} />
                              ) : (
                                <ArrowRight />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={subMenuItem.subMenuTitle} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  ) : null)}
              </Box>
            ))}
          </>
        </List>
      </LeftNav>

      <Box id="app-content" sx={styles.contentContainer}>
        <BoxHeader id="app-header">
          <GlobalSearch />
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={() => navigate(-1)}>
            <BackIcon />
          </IconButton>
          <IconButton onClick={() => navigate(1)}>
            <ForwardIcon />
          </IconButton>
          <Badge
            ref={bookmarkRef}
            onClick={handleClickBookmarkMenu}
            badgeContent={bookmarks.length}
            color="info"
            sx={styles.headerIconButton}
          >
            <BookmarkIcon sx={{ color: bookmarkColor }} fontSize="large" />
          </Badge>
          <ThemeModeToggle />
          <Menu
            id="bookmark-menu"
            aria-labelledby="bookmark-button"
            anchorEl={bookmarkRef.current}
            open={openBookmarkMenu}
            onClose={handleCloseBookmarkMenu}
            sx={{ mt: 5 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              {bookmarks.length ? (
                bookmarks.map((b) => (
                  <BoxBookmarkPopover key={b.id}>
                    <Box
                      onClick={() => {
                        handleCloseBookmarkMenu();
                        navigate(`/application/summary/${b.applicationId}`);
                      }}
                      style={{ textDecoration: 'none', flex: 1, padding: 5 }}
                    >
                      <Typography variant="h6">
                        {b.companyName} - {b.id}
                      </Typography>
                      <Typography variant="caption">
                        added {formatDateDistance(b.createdAt)} ago
                      </Typography>
                    </Box>
                    <IconButton sx={{ padding: 0 }} onClick={() => handleRemoveBookmark(b)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </BoxBookmarkPopover>
                ))
              ) : (
                <Typography>No Bookmarked Applications</Typography>
              )}
              {bookmarks.length ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={handleClearBookmarks}
                >
                  Clear
                </Button>
              ) : null}
            </Box>
          </Menu>
          <AppNotification />
          <Avatar onClick={handleClickMenu} color={avatarColor}>
            {initials ?? ''}
          </Avatar>
          <Menu
            id="avatar-menu"
            aria-labelledby="avatar-button"
            anchorEl={anchorElMenu}
            open={openMenu}
            onClose={handleCloseMenu}
            sx={{ mt: 5 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {user?.username && (
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="caption" align={'right'} noWrap sx={{ color: 'primary.main' }}>
                  User Name
                </Typography>
                <Typography variant="body2" align={'left'} sx={{ color: 'text.secondary' }} noWrap>
                  {user?.username}
                </Typography>
                {user && user.roles ? (
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }} noWrap>
                    <Typography variant="caption" noWrap sx={{ color: 'primary.main' }}>
                      Roles
                    </Typography>
                    {user.roles.map((r) => (
                      <Box display="flex" key={r.roleCode}>
                        {r.roleName}
                      </Box>
                    ))}
                  </Typography>
                ) : null}
                {user && user.roles && userCanSystemQA ? (
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }} noWrap>
                    <Typography variant="caption" noWrap sx={{ color: 'primary.main' }}>
                      Roles to Impersonate
                    </Typography>
                    {roleData.map((role) => (
                      <Typography
                        variant="caption"
                        component={'div'}
                        key={role.roleId}
                        onClick={() => {
                          setImpersonationRoles(user, [role]);
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: `${appPrimaryLight}FF`,
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {role.roleName}
                      </Typography>
                    ))}
                  </Typography>
                ) : null}
                {user && user.impersonatedRoles && user.impersonatedRoles.length > 0 ? (
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }} noWrap>
                    <Typography variant="caption" noWrap sx={{ color: 'primary.main' }}>
                      Impersonated Role
                    </Typography>
                    {user.impersonatedRoles.map((role) => (
                      <Typography variant="caption" component={'div'} key={role.roleId}>
                        {role.roleName}
                      </Typography>
                    ))}
                    {user.impersonatedRoles.length > 0 && (
                      <Typography
                        variant="caption"
                        component={'div'}
                        key={'reset-impersonation'}
                        onClick={() => {
                          setImpersonationRoles(user, []);
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: `${appPrimaryLight}FF`,
                            cursor: 'pointer',
                          },
                        }}
                      >
                        Reset Impersonation
                      </Typography>
                    )}
                  </Typography>
                ) : null}
              </Box>
            )}
            <Divider variant="middle" />
            {user?.id && (
              <MenuItem
                onClick={() => {
                  navigate(`/profile/${user?.id}`);
                  setAnchorElMenu(null);
                }}
              >
                Profile
              </MenuItem>
            )}
            <MenuItem onClick={handleSignOutClick}>Logout</MenuItem>
          </Menu>
        </BoxHeader>
        <Box>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Navigation);
