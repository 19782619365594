import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import useAuth from 'hooks/useAuth';
import RoleBasedGuard from 'routing/RoleBasedGuard';
import {
  ROLE_CODE_ACCOUNTING,
  ROLE_CODE_ADMIN,
  ROLE_CODE_CLOSER,
  ROLE_CODE_COMPLIANCE,
  ROLE_CODE_CUSTOMER_SERVICE,
  ROLE_CODE_FUNDER_MANAGER,
  ROLE_CODE_INSTITUTIONAL_SALES,
  ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN,
  ROLE_CODE_PARTICIPATION_ALLOCATION_ADMIN,
  ROLE_CODE_PARTICIPATION_MANAGER,
  ROLE_CODE_PARTNER_MANAGER,
  ROLE_CODE_PROCESSOR,
  ROLE_CODE_QUALITY_ASSURANCE,
  ROLE_CODE_SYSTEM_ADMIN,
  ROLE_CODE_UNDERWRITER,
} from 'services/RolesService';
import { RecordPageView } from 'awsRUM';

// components
import JournalGroupEditor from 'components/Accounting/JournalGroupEditor';
import JournalEditor from 'components/Accounting/JournalEditor';
import TransactionWorkflowEditor from 'components/Accounting/TransactionWorkflowEditor';

// Providers
import { ApplicationsProvider } from 'contexts/ApplicationsContext';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import { AccountingProvider } from 'contexts/AccountingContext';
import { SystemAdminProvider } from 'contexts/SystemAdminContext';

// -- Un-Authenticated Pages
const LoginPage = React.lazy(() => import('pages/auth/Login'));
const RequestResetPasswordPage = React.lazy(() => import('pages/auth/RequestPasswordReset'));
const ResetPasswordPage = React.lazy(() => import('pages/auth/ResetPassword'));
const NotFoundPage = React.lazy(() => import('pages/NotFound'));

// -- Authenticated Pages
// base set of pages for user
// const HomePage = React.lazy(() => import('pages/Home'));

// accounting
const InvestmentCompanyAllocationStagePage = React.lazy(
  () => import('pages/accounting/InvestmentCompanyAllocationStage')
);
const BankingHomePage = React.lazy(() => import('pages/accounting/BankingHome'));
const JournalsHomePage = React.lazy(() => import('pages/accounting/JournalsHome'));
const TransactionManagementPage = React.lazy(
  () => import('pages/accounting/TransactionManagement')
);
const AccountingHomePage = React.lazy(() => import('pages/accounting/AccountingHome'));
const InvestmentCompanyAllocation = React.lazy(
  () => import('pages/accounting/InvestmentCompanyAllocation')
);
const AccountingOverPayments = React.lazy(() => import('pages/accounting/OverPayments'));
const ExpectedPaymentsManager = React.lazy(
  () => import('pages/accounting/ExpectedPaymentsManager')
);
const InvestmentCompanyAllocationEditPage = React.lazy(
  () => import('pages/accounting/InvestmentCompanyAllocationEdit')
);
const InvestmentCompanyTransactionsManager = React.lazy(
  () => import('pages/accounting/InvestmentCompanyTransactionsManager')
);

//Participation
const Participants = React.lazy(() => import('pages/participation/ParticipantsManagement'));
const ManageParticipant = React.lazy(() => import('pages/participation/ManageParticipant'));
const ParticipationsManager = React.lazy(() => import('pages/participation/ParticipationsManager'));
const ParticipationAllocationSimulation = React.lazy(
  () => import('pages/participation/ParticipationAllocationSimulation')
);
const ParticipationTransactionsManager = React.lazy(
  () => import('pages/participation/ParticipationTransactionsManager')
);
const FundingParticipationManagement = React.lazy(
  () => import('pages/participation/FundingParticipationManagement')
);
const InterestRatesManagement = React.lazy(
  () => import('pages/participation/InterestRatesManagement')
);

// new application
const NewApplicationPage = React.lazy(() => import('pages/application/NewApplication'));
const NewApplicationPersonsPage = React.lazy(
  () => import('pages/application/NewApplicationPersons')
);
const NewApplicationSubmitPage = React.lazy(() => import('pages/application/NewApplicationSubmit'));

// create application
const CreateApplicationPage = React.lazy(() => import('pages/application/CreateApplication'));

// application
const ApplicationsPage = React.lazy(() => import('pages/application/Applications'));
const ApplicationsRejectedPage = React.lazy(() => import('pages/application/ApplicationsRejected'));
const ApplicationsFundedPage = React.lazy(() => import('pages/application/ApplicationsFunded'));
const ApplicationSummaryPage = React.lazy(() => import('pages/application/ApplicationSummary'));
const KycCipPage = React.lazy(() => import('pages/application/KycCip'));
const BankCalculatorPage = React.lazy(() => import('pages/application/BankCalc'));
const CreditReviewPage = React.lazy(() => import('pages/application/CreditReview'));
const OffersPage = React.lazy(() => import('pages/application/Offers'));
const ContractManagementPage = React.lazy(() => import('pages/application/ContractManagement'));
const InterviewsPage = React.lazy(() => import('pages/application/Interviews'));
const ApprovalsPage = React.lazy(() => import('pages/application/Approvals'));
const QualityAssurancePage = React.lazy(() => import('pages/application/QualityAssurance'));
const FundingManagementPage = React.lazy(() => import('pages/application/FundingManagement'));
const DocumentManagementPage = React.lazy(() => import('pages/application/Documents'));
const ActivitiesPage = React.lazy(() => import('pages/application/Activities'));
const FinancialsPage = React.lazy(() => import('pages/application/Financials'));
const StipulationsPage = React.lazy(() => import('pages/application/Stipulations'));
const NotesPage = React.lazy(() => import('pages/application/Notes'));
const PreviewDocument = React.lazy(() => import('pages/application/PreviewDocument'));

// Commissions
const CommissionsPage = React.lazy(() => import('pages/commissions/Commissions'));

// customer service
const CustomerServicePage = React.lazy(() => import('pages/customer-service/CustomerService'));
const CustomerServiceAppSummaryPage = React.lazy(
  () => import('components/CustomerService/ApplicationSummary')
);
const TransactionDetailsPage = React.lazy(
  () => import('pages/customer-service/TransactionDetails')
);
const ScheduleDiscountPage = React.lazy(() => import('pages/customer-service/ScheduleDiscount'));

// admin pages
const UserRolesAdminPage = React.lazy(() => import('pages/admin/UserRolesAdmin'));
const RolesAdminPage = React.lazy(() => import('pages/admin/RolesAdmin'));
const FunderAdminPage = React.lazy(() => import('pages/admin/FunderAdmin'));
const IndustryAdminPage = React.lazy(() => import('pages/admin/IndustryAdmin'));
const ConfigurationAdminPage = React.lazy(() => import('pages/admin/ConfigurationAdmin'));
const DiligenceProfileCriteriaAdminPage = React.lazy(
  () => import('pages/admin/DiligenceProfileCriteriaAdmin')
);
const AttributeAdmin = React.lazy(() => import('pages/admin/AttributeAdmin'));
const DocumentsAdmin = React.lazy(() => import('pages/admin/DocumentsAdmin'));
const PartnerAdminPage = React.lazy(() => import('pages/admin/PartnerAdmin'));
const RateAdminPage = React.lazy(() => import('pages/admin/RateAdmin'));
const RatesPositionAdminPage = React.lazy(() => import('pages/admin/RatePositionAdmin'));
const CreditTierAdminPage = React.lazy(() => import('pages/admin/CreditTierAdmin'));
const InvestmentCompanyAdminPage = React.lazy(() => import('pages/admin/InvestmentCompanyAdmin'));
const AdvanceProviderAdminPage = React.lazy(() => import('pages/admin/AdvanceProviderAdmin'));
const CommissionedUsersAdminPage = React.lazy(() => import('pages/admin/CommissionedUsersAdmin'));
const ParticipantsPayoffAdminPage = React.lazy(() => import('pages/admin/ParticipantsPayoffAdmin'));
const SystemExceptionsAdminPage = React.lazy(() => import('pages/admin/SystemExceptionAdmin'));
const ScheduledJobsAdminPage = React.lazy(() => import('pages/admin/ScheduledJobsAdmin'));

// user pages
const ProfilePage = React.lazy(() => import('pages/Profile'));

const CustomerServiceRoles = [
  ROLE_CODE_PROCESSOR,
  ROLE_CODE_UNDERWRITER,
  ROLE_CODE_CLOSER,
  ROLE_CODE_COMPLIANCE,
  ROLE_CODE_QUALITY_ASSURANCE,
  ROLE_CODE_ACCOUNTING,
  ROLE_CODE_INSTITUTIONAL_SALES,
  ROLE_CODE_CUSTOMER_SERVICE,
];

const PageRouter = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (location.pathname && location.pathname.indexOf('/auth/request-reset') > -1) {
    return (
      <Suspense fallback={<></>}>
        <RequestResetPasswordPage />
      </Suspense>
    );
  }

  if (location.pathname && location.pathname.indexOf('/auth/reset-auth') > -1) {
    return (
      <Suspense fallback={<></>}>
        <Routes>
          <Route
            path="/auth/reset-auth/:token"
            key="auth-reset-auth"
            element={<ResetPasswordPage />}
          />
        </Routes>
      </Suspense>
    );
  }

  if (location.pathname && location.pathname.indexOf('/auth/expired') > -1) {
    return (
      <Suspense fallback={<LoadingIndicator loading />}>
        <LoginPage authHasExpired />
      </Suspense>
    );
  }

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<LoadingIndicator loading />}>
        <LoginPage />
      </Suspense>
    );
  }

  if (location.pathname) {
    RecordPageView();
  }

  /* routes above handle authentication */
  /* routes below assume authentication was successful */
  return (
    <Suspense fallback={<LoadingIndicator loading />}>
      <Routes>
        <Route path="/" key="home" element={<Navigate to="/applications" replace />} />
        {/* Future usage */}
        {/*<Route*/}
        {/*  path="/home"*/}
        {/*  key="home"*/}
        {/*  element={*/}
        {/*    <ApplicationsProvider>*/}
        {/*      <HomePage />*/}
        {/*    </ApplicationsProvider>*/}
        {/*  }*/}
        {/*/>*/}
        {/* application routes */}
        <Route
          path="/applications"
          key="applications"
          element={
            <ApplicationsProvider>
              <ApplicationsPage />
            </ApplicationsProvider>
          }
        />
        <Route
          path="/applications/rejected"
          key="applications-rejected"
          element={<ApplicationsRejectedPage />}
        />
        <Route
          path="/applications/funded"
          key="applications-funded"
          element={<ApplicationsFundedPage />}
        />
        <Route
          path="/new-application/:companyId?"
          key="new-application"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_PROCESSOR]}>
              <NewApplicationPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/new-application/persons/:companyId?"
          key="new-application-persons"
          element={<NewApplicationPersonsPage />}
        />
        <Route
          path="/new-application/submit/:companyId?"
          key="new-application-submit"
          element={<NewApplicationSubmitPage />}
        />
        <Route
          path="/create-application"
          key="create-application"
          element={<CreateApplicationPage />}
        />
        <Route
          path="/application/summary/:applicationId"
          key="application-summary"
          element={
            <ApplicationProvider>
              <ApplicationSummaryPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/kyc-cip/:applicationId"
          key="kyc-cip"
          element={
            <ApplicationProvider>
              <KycCipPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/bank-calculator/:applicationId"
          key="application-bank-calculation"
          element={
            <ApplicationProvider>
              <BankCalculatorPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/credit-review/:applicationId"
          key="application-credit-review"
          element={
            <ApplicationProvider>
              <CreditReviewPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/stipulations/:applicationId"
          key="stips"
          element={
            <ApplicationProvider>
              <StipulationsPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/offers/:applicationId"
          key="offers"
          element={
            <ApplicationProvider>
              <OffersPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/contracts/:applicationId"
          key="application-contracts"
          element={
            <ApplicationProvider>
              <ContractManagementPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/interviews/:applicationId"
          key="application-interviews"
          element={
            <ApplicationProvider>
              <InterviewsPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/approvals/:applicationId"
          key="application-approvals"
          element={
            <ApplicationProvider>
              <ApprovalsPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/quality-assurance/:applicationId"
          key="application-quality-assurance"
          element={
            <ApplicationProvider>
              <QualityAssurancePage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/financials/:applicationId"
          key="application-financials"
          element={
            <ApplicationProvider>
              <FinancialsPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/funding/:applicationId"
          key="application-funding"
          element={
            <ApplicationProvider>
              <FundingManagementPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/notes/:applicationId"
          key="application-notes"
          element={
            <ApplicationProvider>
              <NotesPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/documents/:applicationId"
          key="application-documents"
          element={
            <ApplicationProvider>
              <DocumentManagementPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/activities/:applicationId"
          key="application-activities"
          element={
            <ApplicationProvider>
              <ActivitiesPage />
            </ApplicationProvider>
          }
        />
        <Route
          path="/application/document/preview/:documentId"
          key="document/preview"
          element={<PreviewDocument />}
        />
        {/* customer service routes */}
        <Route
          path="/customer-service"
          key="customer-service"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <CustomerServicePage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/summary/:applicationId"
          key="customer-service-application-summary"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <CustomerServiceAppSummaryPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/schedule-discount/:applicationId"
          key="customer-service-schedule-discount"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <AccountingProvider>
                  <ScheduleDiscountPage />
                </AccountingProvider>
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/kyc-cip/:applicationId"
          key="customer-service-kyc-cip"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <KycCipPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/bank-calculator/:applicationId"
          key="customer-service-bank-calculation"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <BankCalculatorPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/credit-review/:applicationId"
          key="customer-service-credit-review"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <CreditReviewPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/financials/:applicationId"
          key="customer-service-financials"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <FinancialsPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/offers/:applicationId"
          key="offers"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <OffersPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/contracts/:applicationId"
          key="customer-service-contracts"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <ContractManagementPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/interviews/:applicationId"
          key="customer-service-interviews"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <InterviewsPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/approvals/:applicationId"
          key="customer-service-approvals"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <ApprovalsPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/quality-assurance/:applicationId"
          key="customer-service-quality-assurance"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <QualityAssurancePage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/customer-service/transaction-details/:applicationId"
          key="customer-service-transaction-details"
          element={
            <RoleBasedGuard roles={CustomerServiceRoles}>
              <ApplicationProvider>
                <TransactionDetailsPage />
              </ApplicationProvider>
            </RoleBasedGuard>
          }
        />
        {/* accounting page routes */}
        <Route
          path="/accounting/transaction/workflow-editor"
          key="journals-editor"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <TransactionWorkflowEditor />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/expected-payments-manager"
          key="expected-payments-manager"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <ExpectedPaymentsManager />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/banking/reconciliation"
          key="accounting"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <BankingHomePage route={'reconciliation'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/journals/home"
          key="journals-home"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <JournalsHomePage route={'home'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/journals/search"
          key="journals-search"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <JournalsHomePage route={'search'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/journals/group-editor"
          key="journals-editor"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <JournalGroupEditor />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/journals/editor"
          key="journals-editor"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <JournalEditor />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/transaction-management"
          key="transaction-management"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING, ROLE_CODE_CUSTOMER_SERVICE]}>
              <AccountingProvider>
                <TransactionManagementPage />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/home"
          key="home"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING, ROLE_CODE_CUSTOMER_SERVICE]}>
              <ApplicationsProvider>
                <AccountingProvider>
                  <AccountingHomePage route={'home'} />
                </AccountingProvider>
              </ApplicationsProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/account-management"
          key="account-management"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING, ROLE_CODE_CUSTOMER_SERVICE]}>
              <AccountingProvider>
                <AccountingHomePage route={'account-management'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/institution-management"
          key="insitution-management"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING, ROLE_CODE_CUSTOMER_SERVICE]}>
              <AccountingProvider>
                <AccountingHomePage route={'institution-management'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/journal-types"
          key="journal-types"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <AccountingHomePage route={'journal-types'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/transaction-types"
          key="transaction-types"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <AccountingHomePage route={'transaction-types'} />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/investment-company-allocation"
          key="investment-company-allocation"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <InvestmentCompanyAllocation />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/investment-company-transactions"
          key="investment-company-transactions"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <InvestmentCompanyTransactionsManager />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/investment-company-allocation/stage"
          key="investment-company-allocation-stage"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <InvestmentCompanyAllocationStagePage />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/investment-company-allocation/:investmentCompanyFundingId"
          key="investment-company-allocation-edit"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING]}>
              <AccountingProvider>
                <InvestmentCompanyAllocationEditPage />
              </AccountingProvider>
            </RoleBasedGuard>
          }
        />
        {/* participation routes */}
        <Route path="/participation/participants" key="participants" element={<Participants />} />
        <Route
          path="/participation/new-participant"
          key="new-participant"
          element={<ManageParticipant />}
        />
        <Route
          path="/participation/edit/:participantId"
          key="edit-participant"
          element={<ManageParticipant />}
        />
        <Route
          path="/participation/transactions-manager"
          key="participation-transactions-manager"
          element={<ParticipationTransactionsManager />}
        />
        <Route
          path="/participation/manage"
          key="participations-manager"
          element={<ParticipationsManager />}
        />
        <Route
          path="/participation/funding-participation-management/:offerId"
          key="funding-participation-management"
          element={<FundingParticipationManagement />}
        />
        <Route
          path="/participation/simulation"
          key="participation-allocation-simulation"
          element={<ParticipationAllocationSimulation />}
        />
        <Route
          path="/participation/simulation/:applicationId"
          key="participation-allocation-simulation-application-id"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN, ROLE_CODE_PARTICIPATION_ALLOCATION_ADMIN]}>
              <ParticipationAllocationSimulation />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/participation/participants-payoff"
          key="participants-payoff"
          element={<ParticipantsPayoffAdminPage />}
        />
        <Route
          path="/admin/interest-rates"
          key="admin-interest-rates"
          element={
            <RoleBasedGuard
              roles={[ROLE_CODE_ADMIN, ROLE_CODE_ACCOUNTING, ROLE_CODE_PARTICIPATION_MANAGER]}
            >
              <InterestRatesManagement />
            </RoleBasedGuard>
          }
        />
        {/* Commissions Routes */}
        <Route
          path="/commissions/user-commissions"
          key="user"
          element={
            <RoleBasedGuard
              roles={[
                ROLE_CODE_ADMIN,
                ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN,
                ROLE_CODE_INSTITUTIONAL_SALES,
              ]}
            >
              <CommissionsPage />
            </RoleBasedGuard>
          }
        />
        {/* users routes */}
        <Route path="/password" key="password" element={<ResetPasswordPage />} />
        <Route path="/profile/:userId" key="profile" element={<ProfilePage />} />
        <Route path="/auth/login" key="login" element={<LoginPage />} />;
        <Route path="/auth/request-reset" key="request-reset" element={<ResetPasswordPage />} />;
        {/* admin routes */}
        <Route
          path="/admin/user"
          key="user"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <UserRolesAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/roles"
          key="roles"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <RolesAdminPage />
            </RoleBasedGuard>
          }
        />
        {/* Tentatively hide privileges until use case arises
        <Route
          path="/admin/privileges"
          key="privileges"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <PrivilegesAdminPage />
            </RoleBasedGuard>
          }
        /> */}
        <Route
          path="/admin/funders"
          key="funders"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN, ROLE_CODE_FUNDER_MANAGER]}>
              <FunderAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/partners"
          key="partners"
          element={
            <RoleBasedGuard
              roles={[ROLE_CODE_ADMIN, ROLE_CODE_PARTNER_MANAGER, ROLE_CODE_COMPLIANCE]}
            >
              <PartnerAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/industries"
          key="industries"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <IndustryAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/rates/:productId?"
          key="rates"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <RateAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/rates-position/:productId/:rateId"
          key="rates-position"
          element={<RatesPositionAdminPage />}
        />
        <Route path="admin/credit-tier/" key="credit-tier" element={<CreditTierAdminPage />} />
        <Route
          path="/diligence/profiles"
          key="diligence-profiles"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <DiligenceProfileCriteriaAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/configuration"
          key="configuration"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <ConfigurationAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/attributes"
          key="attributes"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <AttributeAdmin />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/documents/types"
          key="documents"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <SystemAdminProvider>
                <DocumentsAdmin route={'types'} />
              </SystemAdminProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/documents/statuses"
          key="documents"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <SystemAdminProvider>
                <DocumentsAdmin route={'statuses'} />
              </SystemAdminProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/documents/generation"
          key="documents"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <SystemAdminProvider>
                <DocumentsAdmin route={'generation'} />
              </SystemAdminProvider>
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/investment-companies"
          key="investment-companies"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <InvestmentCompanyAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/advance-providers"
          key="advance-providers"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ADMIN]}>
              <AdvanceProviderAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/commissioned-users"
          key="commissioned-users"
          element={
            <RoleBasedGuard
              roles={[ROLE_CODE_ADMIN, ROLE_CODE_INSTITUTIONAL_SALES_COMMISSION_ADMIN]}
            >
              <CommissionedUsersAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/system-admin/exceptions"
          key="system-admin"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_SYSTEM_ADMIN]}>
              <SystemExceptionsAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/admin/system-admin/scheduled-jobs"
          key="system-admin"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_SYSTEM_ADMIN]}>
              <ScheduledJobsAdminPage />
            </RoleBasedGuard>
          }
        />
        <Route
          path="/accounting/overpayments"
          key="overpayments"
          element={
            <RoleBasedGuard roles={[ROLE_CODE_ACCOUNTING, ROLE_CODE_CUSTOMER_SERVICE]}>
              <AccountingOverPayments />
            </RoleBasedGuard>
          }
        />
        <Route path="*" key="404" element={<NotFoundPage />} />;
      </Routes>
    </Suspense>
  );
};

export default PageRouter;
