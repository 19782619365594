import AccountType from 'models/Banking/AccountType';

export const accountTypesData: AccountType[] = [
  {
    accountTypeId: '22896ab2-6ff1-4d5f-b713-c1e903bbe17a',
    accountTypeName: 'Company',
    accountTypeDescription: 'Company',
  },
  {
    accountTypeId: 'ab5750ad-4219-481c-a46b-a5b7b5c93d3e',
    accountTypeName: 'Partner',
    accountTypeDescription: 'Partner',
  },
  {
    accountTypeId: '8950a14a-2705-48f2-9ef6-16f62cf9a8ee',
    accountTypeName: 'Funder',
    accountTypeDescription: 'Funder',
  },
  {
    accountTypeId: '54d124fb-05ba-422c-993c-2f446acd5544',
    accountTypeName: 'Advance Provider',
    accountTypeDescription: 'Advance Provider',
  },
  {
    accountTypeId: '4dc82d9b-1a18-43e9-bb4f-ccd2150226bd',
    accountTypeName: 'Participant Funding',
    accountTypeDescription: 'Participant Funding',
  },
  {
    accountTypeId: '3e207155-ae63-4387-b3d3-cd43d0af5478',
    accountTypeName: 'Participant Remittance',
    accountTypeDescription: 'Participant Remittance',
  },
  {
    accountTypeId: 'f154d61d-5ffb-4e09-9002-8ccea36322f6',
    accountTypeName: 'Participant Interest Remittance',
    accountTypeDescription: 'Participant Interest Remittance',
  },
  {
    accountTypeId: '09f7b1a6-87f9-489e-bfef-69075417eac6',
    accountTypeName: 'Investment Company',
    accountTypeDescription: 'Investment Company',
  },
];
