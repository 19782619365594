import { useContext } from 'react';
import { AccountingContext } from 'contexts/AccountingContext';

export const useAccountingData = () => {
  const context = useContext(AccountingContext);
  if (!context) {
    throw new Error('useAccountingData must be used within AccountingProvider');
  }
  return context;
};
