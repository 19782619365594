import AccountStatus from 'models/Banking/AccountStatus';
import AccountStatuses from 'models/Banking/AccountStatuses';

export const accountStatusesData: AccountStatus[] = [
  {
    accountStatusId: AccountStatuses.Active,
    accountStatusName: 'Active - Verified',
    accountStatusDescription: 'Account is Active and can be used to issue transactions',
  },
  {
    accountStatusId: AccountStatuses.Closed,
    accountStatusName: 'Closed',
    accountStatusDescription: 'Account has been Closed',
  },
  {
    accountStatusId: AccountStatuses.Pending,
    accountStatusName: 'Pending',
    accountStatusDescription: 'Account has not yet been activated',
  },
  {
    accountStatusId: AccountStatuses.Suspended,
    accountStatusName: 'Suspended',
    accountStatusDescription: 'Account has been suspended and cannot be used',
  },
  {
    accountStatusId: AccountStatuses.NotWorking,
    accountStatusName: 'Not Working',
    accountStatusDescription: 'Account is in a non-working state and cannot be used',
  },
  {
    accountStatusId: AccountStatuses.Unknown,
    accountStatusName: 'Unknown',
    accountStatusDescription: 'Account is not known and needs to be verified',
  },
];
