import HttpClient from 'services/HttpClient';
import ApiResponseType from 'models/API/ApiResponseType';
import { AUTH_TOKEN_NAME, LOGOUT_ENDPOINT, REFRESH_TOKEN_ENDPOINT, USER_PROFILE_KEY } from 'config';
import { decodeJwtToken, isValidToken } from 'utils/jwt';
import dateIsBefore from 'utils/date/dateIsBefore';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const fmsServices = () => {
  let isRefreshing = false;

  const httpClient = HttpClient();
  const FMS_API_URL = process.env.REACT_APP_FMS_API_URL ?? 'http://localhost:7500';

  const clearSession = async () => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);

      if (token) {
        const tokenDecoded = decodeJwtToken(token) as { userId: string };

        await postUnAuthenticated(LOGOUT_ENDPOINT, { userId: tokenDecoded.userId });
      }
    } catch (error: any) {
      console.error(`Error while request an user logout. Message: ${error.message ?? 'N/A'}`);
    }

    localStorage.removeItem(AUTH_TOKEN_NAME);
    localStorage.removeItem(USER_PROFILE_KEY);

    setTimeout(() => {
      // hard reset the location to page that user can auth again
      window.location.replace('/auth/expired');
    }, 100);
  };

  const getToken = () => {
    const bearerToken = localStorage.getItem(AUTH_TOKEN_NAME);
    if (bearerToken && bearerToken.length > 0) {
      return bearerToken;
    }
    return undefined;
  };

  const tokenExpired = (token?: string | null): boolean => {
    if (!token || token.length === 0) {
      return true;
    }

    return !isValidToken(token);
  };

  const handleRefreshToken = async () => {
    isRefreshing = true;

    try {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      const tokenDecoded = decodeJwtToken(token) as { refreshToken: string; userId: string };

      const refreshResponse = await fetch(REFRESH_TOKEN_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken: tokenDecoded.refreshToken,
          userId: tokenDecoded.userId,
        }),
      });

      const { data, success } = await refreshResponse.json();

      isRefreshing = false;

      if (success && data) {
        localStorage.setItem(AUTH_TOKEN_NAME, data.token);

        return data.token;
      }

      await clearSession();
    } catch (error: any) {
      console.error(`Error while refreshing user session. Message: ${error?.message ?? 'N/A'}`);
    }
  };

  const handleTokenExpired = async (token?: string) => {
    if (!token) {
      await clearSession();
      return;
    }

    const tokenDecoded = decodeJwtToken(token) as {
      refreshToken: string;
      refreshTokenExpiration: Date;
    };

    if (
      !tokenDecoded.refreshToken ||
      dateIsBefore(tokenDecoded.refreshTokenExpiration, new Date())
    ) {
      await clearSession();
      return;
    }

    return await handleRefreshToken();
  };

  const checkRefreshToken = async () => {
    if (isRefreshing) {
      while (isRefreshing) {
        await delay(1000);
      }
    }

    const token = getToken();

    if (tokenExpired(token)) {
      return await handleTokenExpired(token);
    }

    return token;
  };

  /** GET: Returns the resource at given url while providing authorization */
  const get = async <T, ApiResponseType>(url: string): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;
    const response = (await httpClient.getData<T>(requestUrl, token)) as unknown as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.getData<T>(requestUrl, updatedToken)) as unknown as ApiResponseType;
  };

  /** GET: Returns the resource at given url WITHOUT providing authorization */
  const getUnAuthenticated = async <T, ApiResponseType>(url: string): Promise<ApiResponseType> => {
    const requestUrl = FMS_API_URL + url;
    return (await httpClient.getDataUnAuthenticated<T>(requestUrl)) as unknown as ApiResponseType;
  };

  /** POST: Updates the resource at given url while providing authorization */
  const post = async <T, ApiResponseType>(url: string, data?: T): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;
    const response = (await httpClient.postData<T, unknown>(
      requestUrl,
      data,
      token
    )) as unknown as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.postData<T, unknown>(
      requestUrl,
      data,
      updatedToken
    )) as unknown as ApiResponseType;
  };

  /** POST: Submits FormData to the resource at given url while providing authorization */
  const postFormData = async <T, ApiResponseType>(
    url: string,
    data?: FormData
  ): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;

    const response = (await httpClient.postFormData<T>(
      requestUrl,
      data,
      token
    )) as unknown as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.postFormData<T>(
      requestUrl,
      data,
      updatedToken
    )) as unknown as ApiResponseType;
  };

  /** POST: Updates the resource at given url without providing authorization */
  const postUnAuthenticated = async <T, ApiResponseType>(
    url: string,
    data?: T
  ): Promise<ApiResponseType> => {
    const requestUrl = FMS_API_URL + url;
    return (await httpClient.postDataUnAuthenticated<T, unknown>(
      requestUrl,
      data
    )) as unknown as ApiResponseType;
  };

  /** PUT: Updates the resource at given url */
  const put = async <T, ApiResponseType>(url: string, data?: T): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;

    const response = (await httpClient.putData<T, unknown>(
      requestUrl,
      data,
      token
    )) as unknown as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.putData<T, unknown>(
      requestUrl,
      data,
      updatedToken
    )) as unknown as ApiResponseType;
  };

  /** DELETE: Deletes the resource at given url */
  const remove = async (url: string): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;

    const response = (await httpClient.deleteData(requestUrl, token)) as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.deleteData(requestUrl, updatedToken)) as ApiResponseType;
  };

  const postArrayBufferResponse = async <T, ApiResponseType>(
    url: string,
    data?: T
  ): Promise<ApiResponseType> => {
    const token = await checkRefreshToken();

    const requestUrl = FMS_API_URL + url;

    const response = (await httpClient.postArrayBufferResponse<T>(
      requestUrl,
      data,
      token
    )) as unknown as ApiResponseType;

    // @ts-ignore
    if (response.status !== 401) {
      return response;
    }

    const updatedToken = await handleRefreshToken();

    return (await httpClient.postArrayBufferResponse<T>(
      requestUrl,
      data,
      updatedToken
    )) as unknown as ApiResponseType;
  };

  return {
    getUnAuthenticated,
    get,
    post,
    postArrayBufferResponse,
    postFormData,
    postUnAuthenticated,
    put,
    remove,
  };
};

export default fmsServices;
