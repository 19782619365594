export const FUNDING_STATUS_ID_DEFAULT = 'a2feef84-15af-4606-afd1-b15682edadb8';
export const FUNDING_STATUS_ID_PAID_OFF_WITH_RENEWAL = 'b83bae5c-4bbc-47f7-83ff-3ba881440afc';
export const FUNDING_STATUS_ID_PAID_VIA_DISCOUNTED_PAYOFF = '57164356-5c1f-4edf-afab-17b1d3017292';
export const FUNDING_STATUS_ID_PAID_OFF_VIA_LEGAL_SETTLEMENT =
  '5ea40afd-e397-4d37-b5a0-a0ae9feaeb63';
export const FUNDING_STATUS_ID_PAID_IN_FULL = 'bac5e4b3-83d3-466c-95f7-1ace4d7b6f9c';

export const FUNDING_STATUS_COMPLETED_IDS = [
  FUNDING_STATUS_ID_DEFAULT,
  FUNDING_STATUS_ID_PAID_OFF_WITH_RENEWAL,
  FUNDING_STATUS_ID_PAID_VIA_DISCOUNTED_PAYOFF,
  FUNDING_STATUS_ID_PAID_OFF_VIA_LEGAL_SETTLEMENT,
  FUNDING_STATUS_ID_PAID_IN_FULL,
];
