import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
/*
	Encapsulates all outbound http communication from the application 
  to the FMS API to get/set data and forms the basic adaptor 
  for http requests made by the application.
	Also provides way to set auth headers needed by some requests
  using modern fetch based methods:
  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  Inspiration: https://github.com/Microsoft/typed-rest-client
*/
const HttpClient = () => {
  let defaultHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  };

  // the two possible API responses
  type ApiResponseType = ApiResponse | ApiResponsePaged;

  const fetchResponseHandler = <DataType>(response): ApiResponseType => {
    // Wraps fetch response and traps most errors
    // and returns http status code of the raw response.
    // Ensures consistent response in form of:
    // ApiResponse | ApiResponsePaged
    if (response.ok) {
      return response
        .json()
        .then((json) => {
          // API should always return a JSON payload of type ApiResponseType
          // this is the normal and expected/desired path for getting API responses
          let apiResponse = json as ApiResponseType;
          if (apiResponse.data) {
            apiResponse.data = apiResponse.data as DataType;
          }
          apiResponse.status = response.status; // add http status code
          return Promise.resolve(apiResponse);
        })
        .catch(() =>
          // the status was ok but there is no json body
          Promise.resolve({
            success: true,
            message: response.error.messages,
            errors: response.error,
            status: response.status,
          } as unknown as ApiResponseType)
        );
    } else {
      // not "ok" and NOT a http status code of 200
      return response
        .json()
        .catch(() =>
          // the status was not ok and there is no json body
          Promise.resolve({
            success: false,
            status: response.status,
            message: response.statusText,
            errors: response.error,
          } as unknown as ApiResponseType)
        )
        .then((json) => {
          // the status was not ok but there is a json body
          const apiResponse = json as ApiResponseType;
          apiResponse.status = response.status; // add http status code
          apiResponse.success = false;
          return Promise.resolve(apiResponse);
        });
    }
  };

  const getData = async <T>(url: string, token?: string) => {
    let getHeaders = defaultHeaders;
    if (token && token.length) {
      getHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'GET',
      headers: getHeaders,
    }).then((response) => fetchResponseHandler<T>(response));
  };

  const getDataUnAuthenticated = async <T>(url: string) =>
    await fetch(url, {
      method: 'GET',
      headers: defaultHeaders,
    }).then((response) => fetchResponseHandler<T>(response));

  const postData = async <T, R>(url, data?: T, token?: string) => {
    let postHeaders = defaultHeaders;
    if (token && token.length) {
      postHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'POST',
      headers: postHeaders,
      body: JSON.stringify(data as T),
    }).then((response) => fetchResponseHandler<R>(response));
  };

  const postArrayBufferResponse = async <T>(url, data?: T, token?: string) => {
    let postHeaders = {
      'Content-Type': 'application/json',
      responseType: 'arraybuffer',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    };
    if (token && token.length) {
      postHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'POST',
      headers: postHeaders,
      body: JSON.stringify(data as T),
    }).then((response) => response.arrayBuffer());
  };

  const postFormData = async <T>(url, formData?: FormData, token?: string) => {
    // NOTE: no need to add content type: "multipart/form-data" header as fetch will add it
    let formDataHeaders = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    };
    if (token && token.length) {
      formDataHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'POST',
      headers: formDataHeaders,
      body: formData,
    }).then((response) => fetchResponseHandler<T>(response));
  };

  const postDataUnAuthenticated = async <T, R>(url: string, data?: T) => {
    const postHeaders = defaultHeaders;
    return await fetch(url, {
      method: 'POST',
      headers: postHeaders,
      body: JSON.stringify(data),
    }).then((response) => fetchResponseHandler<R>(response));
  };

  const putData = async <T, R>(url: string, data?: T, token?: string) => {
    let putHeaders = defaultHeaders;
    if (token && token.length) {
      putHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'PUT',
      headers: putHeaders,
      body: JSON.stringify(data),
    }).then((response) => fetchResponseHandler<R>(response));
  };

  const deleteData = async (url: string, token?: string) => {
    let deleteHeaders = defaultHeaders;
    if (token && token.length) {
      deleteHeaders['Authorization'] = 'Bearer ' + token;
    }
    return await fetch(url, {
      method: 'DELETE',
      headers: deleteHeaders,
    }).then((response) => fetchResponseHandler<any>(response));
  };

  return {
    getData,
    getDataUnAuthenticated,
    postData,
    postArrayBufferResponse,
    postFormData,
    postDataUnAuthenticated,
    putData,
    deleteData,
  };
};

export default HttpClient;
