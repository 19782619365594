export enum ApplicationEventEnum {
  ApplicationCreated = 'applications.ApplicationCreated',
  ApplicationRejectReasonUpserted = 'applications.ApplicationRejectReasonUpserted',
  ApplicationStatusActivityCreated = 'applications.ApplicationStatusActivityCreated',
  ApplicationTeamMemberCreated = 'applications.ApplicationTeamMemberCreated',
  ApplicationTeamMemberUpdated = 'applications.ApplicationTeamMemberUpdated',
  ApplicationTeamMemberUpserted = 'applications.ApplicationTeamMemberUpserted',
  ApplicationTeamMemberRemoved = 'applications.ApplicationTeamMemberRemoved',
  ApplicationTeamAutoAssigned = 'applications.ApplicationTeamAutoAssigned',
  ApplicationBankStatementUpserted = 'applications.ApplicationBankStatementUpserted',
  ApplicationLiabilityUpdated = 'applications.ApplicationLiabilityUpdated',
  ApplicationUpdated = 'applications.ApplicationUpdated',
  ApplicationStatusUpdated = 'applications.ApplicationStatusUpdated',
  ApplicationIndustryEligibilityUpdated = 'applications.ApplicationIndustryEligibilityUpdated',
  ApplicationMaxPTIUpdated = 'applications.ApplicationMaxPTIUpdated',
  ApplicationMaxPTBUpdated = 'applications.ApplicationMaxPTBUpdated',
  ApplicationAdjustedCreditTierIdUpdated = 'applications.ApplicationAdjustedCreditTierIdUpdated',
  ApplicationSubStatusUpdated = 'applications.ApplicationSubStatusUpdated',
  ApplicationPricingUpdated = 'applications.ApplicationPricingUpdated',
  ApplicationFunderUpdated = 'applications.ApplicationFunderUpdated',
  ApplicationUseOfFundsUpdated = 'applications.ApplicationUseOfFundsUpdated',
  ApplicationApprovalUpserted = 'applications.ApplicationApprovalUpserted',
  ApplicationCreditCalcDatapointsUpdated = 'applications.ApplicationCreditCalcDatapointsUpdated',
  ApplicationTaxLiensAndJudgmentsUpdated = 'applications.ApplicationTaxLiensAndJudgmentsUpdated',
  ApplicationFundedInLast30DaysUpdated = 'applications.ApplicationFundedInLast30DaysUpdated',
  ApplicationAccountingMethodUpdated = 'applications.ApplicationAccountingMethodUpdated',
}

export default ApplicationEventEnum;
