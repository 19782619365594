import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import Note from 'models/Notes/Note';
import NotesSearch from 'models/Notes/NotesSearch';
import DocumentFile from 'models/Documents/DocumentFile';
import NoteDocumentSearch from 'models/Notes/NoteDocumentSearch';
import NoteDocument from 'models/Notes/NoteDocument';

export const NOTE_TYPE_DILIGENCE = 'diligence';
export const NOTE_TYPE_CUSTOMER_SERVICE = 'customer-service';
export const NOTE_TYPE_CUSTOMER_FINANCIALS = 'financials';
export const NOTE_TYPE_FUNDING = 'funding';
export const NOTE_TYPE_OUTCOME_AND_STATUS = 'outcome-and-status';
export const NOTE_TYPE_APPLICATIONS = 'applications';

/**
This service provides data for "Notes" which are associated to core entities 
via the modelId (identifier of entity) and modelType (string of the type of entity)
*/
const NotesService = () => {
  const initNote = (
    userId: string,
    modelId: string,
    modelType: string,
    noteType: string,
    noteText: string = ''
  ) => {
    const noteObject: Note = {
      userId,
      modelId,
      modelType,
      noteType,
      noteText,
    };

    return noteObject;
  };

  const upsertNote = async (note: Note): Promise<ApiResponse> => {
    const upsertNoteResponse = await fmsServices().post<Note, ApiResponse>('/notes/upsert', note);

    return upsertNoteResponse;
  };

  const searchNotes = async (notesSearch: NotesSearch): Promise<ApiResponsePaged<Note[]>> => {
    const searchNotesResponse = await fmsServices().post<NotesSearch, ApiResponsePaged<Note[]>>(
      '/notes/search',
      notesSearch
    );
    return searchNotesResponse;
  };

  const uploadNoteDocument = async (
    documentFile: DocumentFile,
    noteId: string
  ): Promise<ApiResponse> => {
    const uploadForm = new FormData();
    if (documentFile.document.companyId) {
      uploadForm.append('companyId', documentFile.document.companyId);
    }
    uploadForm.append('documentName', documentFile.file.name);
    uploadForm.append('documentFileType', documentFile.file.type);
    uploadForm.append('documentStatusId', documentFile.document.documentStatusId);
    uploadForm.append('documentTypeId', documentFile.document.documentTypeId!);
    uploadForm.append('applicationId', documentFile.document.modelId!);
    uploadForm.append('noteId', noteId);
    uploadForm.set('file', documentFile.file);
    const uploadDocumentResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/notes/documents/upload',
      uploadForm
    );
    return uploadDocumentResponse;
  };

  const uploadNoteDocuments = async (
    documentFiles: DocumentFile[],
    noteId: string
  ): Promise<ApiResponse> => {
    let apiResponse: ApiResponse = { message: '', success: false };
    let messages = '';
    let anyErrors = false;
    for (let i = 0; i < documentFiles.length; i++) {
      const docFile = documentFiles[i];
      const uploadDocumentResponse = await uploadNoteDocument(docFile, noteId);
      if (uploadDocumentResponse.success) {
        messages += `File ${docFile.file.name} uploaded successfully`;
      } else {
        messages += `File ${docFile.file.name} NOT uploaded successfully`;
        anyErrors = true;
        apiResponse.errors = uploadDocumentResponse.errors;
      }
    }
    apiResponse.message = messages;
    apiResponse.success = anyErrors === false;

    return apiResponse;
  };

  const searchNoteDocuments = async (
    notesDocumentsSearch: NoteDocumentSearch
  ): Promise<ApiResponse<NoteDocument[]>> => {
    const searchNotesResponse = await fmsServices().post<
      NoteDocumentSearch,
      ApiResponse<NoteDocument[]>
    >('/notes/documents/search', notesDocumentsSearch);
    return searchNotesResponse;
  };
  return {
    initNote,
    upsertNote,
    searchNotes,
    uploadNoteDocument,
    uploadNoteDocuments,
    searchNoteDocuments,
  };
};

export default NotesService;
