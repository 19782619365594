import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
// services
import ApplicationsService from 'services/ApplicationsService';
import AttributesService from 'services/AttributesService';
import DiligenceService from 'services/DiligenceService';
import FundingService from 'services/FundingService';
import NotesService from 'services/NotesService';
// models
import ApplicationContextType from 'models/Application/ApplicationContextType';
import Application from 'models/Application/Application';
import ApplicationStep from 'models/Application/ApplicationStep';
import Attribute from 'models/Attributes/Attribute';
import DiligenceCheck from 'models/Diligence/DiligenceCheck';
import Funding from 'models/Funding/Funding';
import { getOutcome } from 'components/Diligence/utils';
import Note from 'models/Notes/Note';
// hooks
import useAuth from 'hooks/useAuth';

export const ApplicationContext = React.createContext<ApplicationContextType | null>(null);

export const ApplicationProvider = ({ children }: { children: ReactNode }) => {
  const { applicationId } = useParams();

  const [application, setApplication] = useState<Application | undefined>(undefined);
  const [applicationSteps, setApplicationSteps] = useState<ApplicationStep[]>([]);
  const [diligenceChecks, setDiligenceChecks] = useState<DiligenceCheck[]>([]);
  const [manualReviewChecks, setManualReviewChecks] = useState<DiligenceCheck[]>([]);
  const [rejectedChecks, setRejectedChecks] = useState<DiligenceCheck[]>([]);
  const [notRunChecks, setNotRunChecks] = useState<DiligenceCheck[]>([]);
  const [funding, setFunding] = useState<Funding>();
  const [notes, setNotes] = useState<Note[]>([]);
  const [usesOfFunds, setUsesOfFunds] = useState<Attribute[] | undefined>();

  const applicationsService = useMemo(ApplicationsService, []);
  const attributesService = useMemo(AttributesService, []);
  const diligenceService = useMemo(DiligenceService, []);
  const fundingService = useMemo(FundingService, []);
  const noteService = useMemo(NotesService, []);
  const { user, userIsAdmin, userIsOnApplicationTeam } = useAuth();
  const navigate = useNavigate();

  const isApplicationEditable = useMemo(() => {
    const statusAllowsEdit = applicationsService.statusAllowsEdit(application?.statusId);
    const currentUserIsTeamMember = userIsOnApplicationTeam(user, application);

    return !!(statusAllowsEdit && (currentUserIsTeamMember || userIsAdmin(user)));
  }, [applicationsService, userIsAdmin, user, userIsOnApplicationTeam, application]);

  const applicationUseOfFunds = useMemo(() => {
    if (application && usesOfFunds) {
      return usesOfFunds.find((useOfFunds) => useOfFunds.attributeId === application.useOfFunds);
    }
  }, [application, usesOfFunds]);

  const getApplicationSteps = useCallback(async () => {
    if (!applicationId) {
      setApplicationSteps([]);
      return;
    }

    const stepsResponse = await diligenceService.getApplicationSteps(applicationId);

    if (!stepsResponse.success || !stepsResponse.data) {
      setApplicationSteps([]);
      return;
    }

    setApplicationSteps(stepsResponse.data);
    return stepsResponse.data;
  }, [applicationId, diligenceService]);

  const getApplication = useCallback(async () => {
    if (!applicationId) {
      setApplication(undefined);
      return;
    }

    const appRequest = await applicationsService.getApplication(applicationId);
    if (!appRequest.data) {
      setApplication(undefined);
      navigate('/not-found');
      return;
    }

    setApplication(appRequest.data as Application);
    return appRequest.data;
  }, [applicationsService, applicationId, navigate]);

  const getCheckResults = useCallback(async () => {
    if (applicationId) {
      const checksResponse = await diligenceService.getApplicationChecks(applicationId);
      if (checksResponse.success) {
        let checks = checksResponse.data as DiligenceCheck[];

        setDiligenceChecks(checks);
      }
    }
  }, [applicationId, diligenceService]);

  const getFundingData = useCallback(async () => {
    const fundingResponse = await fundingService.searchFunding({
      applicationId: applicationId,
    });
    if (fundingResponse.success && fundingResponse.data?.[0]) {
      setFunding(fundingResponse.data[0]);
    }
  }, [applicationId, fundingService]);

  const getNotes = useCallback(
    async (pageNumber: number, pageSize: number) => {
      const notesRespose = await noteService.searchNotes({
        modelId: applicationId,
        pageNumber,
        pageSize,
      });
      if (notesRespose.success && notesRespose.data) {
        setNotes(notesRespose.data);
      }
    },
    [applicationId, noteService]
  );

  const reloadApplication = useCallback(async () => {
    await getApplication();
    await getApplicationSteps();
  }, [getApplication, getApplicationSteps]);

  const reloadNotes = useCallback(
    async (pageNumber: number = 1, pageSize: number = 50) => {
      await getNotes(pageNumber, pageSize);
    },
    [getNotes]
  );

  const reloadDiligenceChecks = useCallback(() => {
    getCheckResults();
  }, [getCheckResults]);

  const reloadFunding = useCallback(async () => {
    await getFundingData();
  }, [getFundingData]);

  useEffect(() => {
    reloadApplication();
  }, [reloadApplication]);

  useEffect(() => {
    getCheckResults();
  }, [getCheckResults]);

  useEffect(() => {
    reloadFunding();
  }, [reloadFunding]);

  useEffect(() => {
    reloadNotes();
  }, [reloadNotes]);

  useEffect(() => {
    const manualReviewChecks = diligenceChecks.filter((check) => {
      const outcome = getOutcome(check);
      return ['manual'].includes(outcome);
    });

    setManualReviewChecks(manualReviewChecks);

    const rejectedChecks = diligenceChecks.filter((check) => {
      const outcome = getOutcome(check);
      return ['rejected'].includes(outcome) || ['reject'].includes(outcome);
    });

    setRejectedChecks(rejectedChecks);

    const notRunChecks = diligenceChecks.filter((check) => {
      const outcome = getOutcome(check);
      return ['not-done'].includes(outcome);
    });

    setNotRunChecks(notRunChecks);
  }, [diligenceChecks, setManualReviewChecks, setRejectedChecks, setNotRunChecks]);

  useEffect(() => {
    (async () => {
      if (!usesOfFunds) {
        try {
          const usesOfFunds = await attributesService.getUseOfFunds();
          setUsesOfFunds(usesOfFunds);
        } catch (e: any) {
          console.log(e);
        }
      }
    })();
  }, [usesOfFunds, attributesService]);

  useEffect(() => {
    getFundingData();
  }, [getFundingData]);

  const contextValues = useMemo(
    () => ({
      application,
      applicationSteps,
      applicationUseOfFunds,
      diligenceChecks,
      manualReviewDiligenceChecks: manualReviewChecks,
      rejectedDiligenceChecks: rejectedChecks,
      notRunDiligenceChecks: notRunChecks,
      reloadApplication,
      reloadDiligenceChecks,
      reloadFunding,
      funding,
      isApplicationEditable,
      reloadNotes,
      notes,
      usesOfFunds,
    }),
    [
      application,
      applicationSteps,
      applicationUseOfFunds,
      diligenceChecks,
      reloadApplication,
      reloadDiligenceChecks,
      reloadFunding,
      notes,
      manualReviewChecks,
      rejectedChecks,
      notRunChecks,
      funding,
      isApplicationEditable,
      reloadNotes,
      usesOfFunds,
    ]
  );

  return (
    <ApplicationContext.Provider value={contextValues}>{children}</ApplicationContext.Provider>
  );
};
