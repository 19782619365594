import { LARGE_PAGE_SIZE } from 'config';
// services
import fmsServices from 'services/fmsServices';
// models
import AttributeList from 'models/Attributes/AttributeList';
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import Attribute from 'models/Attributes/Attribute';
import State from 'models/Attributes/State';

export const DEFAULT_ATTRIBUTE_LIST_CODE = 'use-of-funds';
export const DEFAULT_ATTRIBUTE_LIST_ID = 'c16f1ce2-ba26-44d0-9ef8-8d6b0d2cf264';

/**
This service provides data for "attributes" or "lookup values" 
that are picklists such as geographic states 
or enumerated fixed sets of values used across the system in drop downs
and as attribution of core entities like companies, applications, partners etc
*/
const AttributesService = () => {
  const getUSStates = async () => await getUSStatesData();

  const getApplicationPriorities = () => ['Normal', 'Expedited'];

  const getLegalEntityTypes = async () => await getAttributes('legal-entity-type');

  const getUseOfFunds = async () => await getAttributes('use-of-funds');

  const getAttributes = async (
    attributeListCode: string,
    pageSize: number = LARGE_PAGE_SIZE
  ): Promise<Attribute[]> => {
    const attributesSearch = {
      attributeListCode,
      pageSize,
    };

    const getAttributesResponse = await fmsServices().post<any, ApiResponsePaged<Attribute[]>>(
      `/attributes/search`,
      attributesSearch
    );

    return getAttributesResponse.data || [];
  };

  const getUSStatesData = async (): Promise<State[]> => {
    const getUSStatesResponse = await fmsServices().get<any, ApiResponse<State[]>>(
      `/attributes/getUSStates`
    );

    return getUSStatesResponse.data || [];
  };

  const getAttributeLists = async (): Promise<AttributeList[]> => {
    const getAttributesListsResponse = await fmsServices().post<any, ApiResponse>(
      `/attributes/list`
    );

    return getAttributesListsResponse.data as AttributeList[];
  };

  const upsertAttribute = async (attribute: Attribute): Promise<ApiResponse> => {
    const upsertResponse = await fmsServices().post<any, ApiResponse>(
      `/attributes/upsert`,
      attribute
    );

    return upsertResponse;
  };

  const deleteAttribute = async (attributeId: string): Promise<ApiResponse> => {
    const removeResponse = await fmsServices().post<any, ApiResponse>(`/attributes/delete`, {
      attributeId: attributeId,
    });

    return removeResponse;
  };

  return {
    getUSStates,
    getUseOfFunds,
    getApplicationPriorities,
    getLegalEntityTypes,
    getAttributes,
    getAttributeLists,
    upsertAttribute,
    deleteAttribute,
  };
};

export default AttributesService;
