// services
import fmsServices from 'services/fmsServices';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ApiResponse from 'models/API/ApiResponse';
// models
import Account from 'models/Banking/Account';
import AccountInstitution from 'models/Banking/AccountInstitution';
import AccountInstitutionsSearch from 'models/Banking/AccountInstitutionsSearch';
import AccountUpsertRequest from 'models/Banking/AccountUpsertRequest';
import AccountsSearch from 'models/Banking/AccountsSearch';
import AccountType from 'models/Banking/AccountType';
import AccountStatus from 'models/Banking/AccountStatus';
import AccountStatusUpdate from 'models/Banking/AccountStatusUpdate';
import CompanyBankStatementAdvance from 'models/Banking/CompanyBankStatementAdvance';
import CompanyBankStatementAdvanceSearch from 'models/Banking/CompanyBankStatementAdvanceSearch';
import CompanyBankStatementAdvanceIncludeUpdate from 'models/Banking/CompanyBankStatementAdvanceIncludeUpdate';
import CompanyBankStatement from 'models/Banking/CompanyBankStatement';
import CompanyBankStatementIncludeUpdate from 'models/Banking/CompanyBankStatementIncludeUpdate';
import CompanyBankStatementsSearch from 'models/Banking/CompanyBankStatementsSearch';
import CompanyBankStatementsMonthly from 'models/Banking/CompanyBankStatementsMonthly';
import CompanyBankStatementsMonthlyAggregate from 'models/Banking/CompanyBankStatementsMonthlyAggregate';
import CompanyBankStatementsMonthlySearch from 'models/Banking/CompanyBankStatementsMonthlySearch';
import CompanyBankStatementMonthlyIncludeUpdate from 'models/Banking/CompanyBankStatementMonthlyIncludeUpdate';
import CodatInvitationRequest from 'models/Banking/CodatInvitationRequest';
import CodatPullRequest from 'models/Banking/CodatPullRequest';
import DocumentFile from 'models/Documents/DocumentFile';
import TransactionType from 'models/Transactions/TransactionType';
import CompanyBankStatementAdvanceEnablePayoffUpdate from 'models/Banking/CompanyBankStatementAdvanceEnablePayoffUpdate';
import CompanyBankStatementAdvanceStatus from 'models/Banking/CompanyBankStatementAdvanceStatus';
import CompanyBankStatementAdvanceStatusUpdate from 'models/Banking/CompanyBankStatementAdvanceStatusUpdate';
import CompanyBankStatementTransactionType from 'models/Banking/CompanyBankStatementTransactionType';
import CompanyBankStatementUpsert from 'models/Banking/CompanyBankStatementUpsert';
import CompanyBankStatementRecurringPayment from 'models/Banking/CompanyBankStatementRecurringPayment';
import CompanyBankStatementRecurringPaymentSearch from 'models/Banking/CompanyBankStatementRecurringPaymentSearch';
import CompanyBankStatementRecurringPaymentUpsert from 'models/Banking/CompanyBankStatementRecurringPaymentUpsert';
import RemittanceFrequency from 'models/Offers/RemittanceFrequency';
import CompanyBankStatementRecurringPaymentDelete from 'models/Banking/CompanyBankStatementRecurringPaymentDelete';
import CompanyBankStatementRecurringPaymentType from 'models/Banking/CompanyBankStatementRecurringPaymentType';
import CompanyBankStatementRecurringPaymentIncludeUpdate from 'models/Banking/CompanyBankStatementRecurringPaymentIncludeUpdate';
import CompanyBankStatementAdvanceUpsert from 'models/Banking/CompanyBankStatementAdvanceUpsert';
import CompanyBankStatementAdvanceDelete from 'models/Banking/CompanyBankStatementAdvanceDelete';
import AdvanceProvider from 'models/Banking/AdvanceProvider';
import AdvanceProviderSearch from 'models/Banking/AdvanceProviderSearch';
import MoneyThumbCompanyBankStatement from 'models/Banking/MoneyThumbCompanyBankStatement';
import ApplicationCashFlow from 'models/Banking/ApplicationCashFlow';
import CompanyInternalAdvances from 'models/Banking/CompanyInternalAdvances';
import { TRANSACTION_TYPE_ACH, TRANSACTION_TYPE_WIRE } from 'services/TransactionSevice';

// Datasets (static data sets that are provided by banking service)
import { accountTypesData } from 'services/data/accountTypesData';
import { accountStatusesData } from 'services/data/accountStatusesData';
import { accountTransactionTypesData } from 'services/data/accountTransactionTypesData';
import { companyBankStatementAdvanceStatusesData } from 'services/data/companyBankStatementAdvanceStatuses';
import { companyBankStatementTransactionTypes } from 'services/data/companyBankStatementTransactionTypes';

// constants
export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_CREDIT =
  'f40f3bce-076a-43ed-ada7-0c4e02062c7f';
export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_DEBIT =
  '90f585ae-9464-4f80-b62f-1a27418de840';
export const COMPANY_BANK_STATEMENT_STATUS_ID_ACTIVE = '12f4b918-33f0-4656-b4b1-d39cec65af7f';
export const COMPANY_BANK_STATEMENT_STATUS_ID_PAID = 'b36a1ee8-0631-4ad2-8a95-0562c0e20515';
export const COMPANY_BANK_STATEMENT_STATUS_ID_DEFAULTED = '26102634-de6c-4584-97b0-c15d281cdf00';
export const ACH_TRANSACTION_TYPE_ID = '209cffb6-9cbf-4109-93e1-c01e0652df3d';
export const WIRE_TRANSACTION_TYPE_ID = 'b8eedcb5-24c1-421a-8bcd-0283d5ec2616';
export const PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS = 'dac96569-1850-471a-8ee1-886b2c738113';
export const COMPANY_ACCOUNT_TYPE_ID = '22896ab2-6ff1-4d5f-b713-c1e903bbe17a';
export const COMPANY_FUNDED_ACCOUNT_TYPE_ID = '44aed84a-3941-4387-91b2-cf9afefc9cee';
export const PARTNER_ACCOUNT_TYPE_ID = 'ab5750ad-4219-481c-a46b-a5b7b5c93d3e';
export const FUNDER_ACCOUNT_TYPE_ID = '8950a14a-2705-48f2-9ef6-16f62cf9a8ee';
export const PARTICIPANT_FUNDING_ACCOUNT_TYPE_ID = '4dc82d9b-1a18-43e9-bb4f-ccd2150226bd';
export const PARTICIPANT_REMITTANCE_ACCOUNT_TYPE_ID = '3e207155-ae63-4387-b3d3-cd43d0af5478';
export const PARTICIPANT_INTEREST_REMITTANCE_ACCOUNT_TYPE_ID =
  'f154d61d-5ffb-4e09-9002-8ccea36322f6';
export const ADVANCE_PROVIDER_ACCOUNT_TYPE_ID = '54d124fb-05ba-422c-993c-2f446acd5544';
export const INVESTMENT_COMPANY_ACCOUNT_TYPE_ID = '09f7b1a6-87f9-489e-bfef-69075417eac6';
export const ACCOUNT_HOLDER_NAME_TYPE_ID = 'e0665438-2ea6-4812-9ee7-d1521e0c7940';
export const COMPANY_MODEL_TYPE = 'company';
export const INVESTMENT_COMPANY_MODEL_TYPE = 'investment company';
export const FUNDER_MODEL_TYPE = 'funder';
export const PARTICIPANT_MODEL_TYPE = 'participant';
export const PARTNER_MODEL_TYPE = 'partner';
export const ADVANCE_PROVIDER_MODEL_TYPE = 'advance provider';

export const DEFAULT_COMPANY_BANK_STATEMENT: CompanyBankStatementUpsert = {
  companyId: '',
  applicationId: '',
  accountId: '',
  transactionTypeId: '',
  accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
  transactionDescription: '',
  transactionAmount: 0,
  transactionDate: new Date(),
  include: true,
};

export const DEFAULT_COMPANY_BANK_STATEMENT_ADVANCE: CompanyBankStatementAdvance = {
  companyId: '',
  accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
  advanceProviderId: '',
  amount: 0,
  frequency: 'Unknown',
  startDate: undefined,
  remainingBalance: 0,
  enablePayoff: false,
  affectsPosition: true,
  include: true,
};

export const DEFAULT_COMPANY_BANK_STATEMENT_RECURRING_PAYMENT: CompanyBankStatementRecurringPaymentUpsert =
  {
    applicationId: '',
    companyBankStatementRecurringPaymentId: '',
    companyBankStatementRecurringPaymentTypeId: '',
    accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
    companyId: '',
    name: '',
    description: '',
    amount: 0,
    frequency: RemittanceFrequency.Monthly,
    include: true,
  };
export const moneyThumbPdfInsightsBaseURL = 'https://online.moneythumb.com/pdfinsightstp';

export const BANK_DATA_SOURCE_MONEY_THUMB = 'moneythumb';

const BankingService = () => {
  const initNewBankingAccount = (modelId = '', modelType = '', accountType = '') =>
    ({
      modelId,
      modelType,
      institutionId: '',
      accountTypeId: accountType,
      accountStatusId: '',
      accountHolderName: '',
      accountNumber: '',
    }) as Account;

  const getAccountTypes = (): AccountType[] => accountTypesData;
  const getAccountStatuses = (): AccountStatus[] => accountStatusesData;
  const getAccountStatusById = (id: string): AccountStatus | undefined =>
    getAccountStatuses().find((status) => status.accountStatusId === id);
  const getCompanyBankStatementTransactionTypes = (): CompanyBankStatementTransactionType[] =>
    companyBankStatementTransactionTypes;
  const getAdvanceStatuses = (): CompanyBankStatementAdvanceStatus[] =>
    companyBankStatementAdvanceStatusesData;
  const getTransactionTypes = (): TransactionType[] => accountTransactionTypesData;
  const getBankInstitutionSupportedTransactionTypes = (
    accountInstitution: AccountInstitution
  ): TransactionType[] => {
    const supportedTransactionTypes: string[] = [
      accountInstitution.supportsAch ? TRANSACTION_TYPE_ACH.toUpperCase() : '',
      accountInstitution.supportsWire ? TRANSACTION_TYPE_WIRE.toUpperCase() : '',
    ];

    return accountTransactionTypesData.filter((type) =>
      supportedTransactionTypes.includes(type.transactionMethod!.toUpperCase())
    );
  };

  const searchAccounts = async (
    accountSearch: AccountsSearch
  ): Promise<ApiResponsePaged<Account[]>> => {
    try {
      const response = await fmsServices().post<AccountsSearch, ApiResponsePaged<Account[]>>(
        '/banking/accounts/search',
        accountSearch
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<Account[]>;
    }
  };

  const searchAccountsFundedCompaniesOnly = async (
    accountSearch: AccountsSearch
  ): Promise<ApiResponsePaged<Account[]>> => {
    try {
      const response = await fmsServices().post<AccountsSearch, ApiResponsePaged<Account[]>>(
        '/banking/accounts/search/funded-accounts',
        accountSearch
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<Account[]>;
    }
  };

  const getDebitingEntitiesAccounts = async (): Promise<ApiResponse<Account[]>> => {
    try {
      const response = await fmsServices().get<null, ApiResponse<Account[]>>(
        '/banking/accounts/debitingentity'
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<Account[]>;
    }
  };

  const getAccountByModelId = async (modelId: string): Promise<ApiResponse<Account>> => {
    try {
      const response = await fmsServices().post<
        {
          [key: string]: string;
        },
        ApiResponse
      >('/banking/account/details-by-model', { modelId });
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const updateAccountStatus = async (
    accountStatusUpdate: AccountStatusUpdate
  ): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<AccountStatusUpdate, ApiResponse>(
        '/banking/account/status',
        accountStatusUpdate
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const handleCodatInvitation = async (
    codatInvitationRequest: CodatInvitationRequest
  ): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<CodatInvitationRequest, ApiResponse>(
        '/codat/link',
        codatInvitationRequest
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const handleCodatPull = async (codatPullRequest: CodatPullRequest): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<CodatPullRequest, ApiResponse>(
        '/codat/pull',
        codatPullRequest
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const searchInstitutions = async (
    accountInstitutionSearch: AccountInstitutionsSearch
  ): Promise<ApiResponsePaged<AccountInstitution[]>> => {
    try {
      const response = await fmsServices().post<
        AccountInstitutionsSearch,
        ApiResponsePaged<AccountInstitution[]>
      >('/banking/institutions/search', accountInstitutionSearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<AccountInstitution[]>;
    }
  };

  const searchCompanyBankStatements = async (
    companyId: string,
    companyBankStatementsSearch: CompanyBankStatementsSearch
  ): Promise<ApiResponsePaged<CompanyBankStatement[]>> => {
    try {
      const searchRequest = {
        companyId,
        ...companyBankStatementsSearch,
      };
      const response = await fmsServices().post<any, ApiResponsePaged<CompanyBankStatement[]>>(
        '/banking/statements/search',
        searchRequest
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        CompanyBankStatement[]
      >;
    }
  };

  const upsertCompanyBankStatement = async (
    applicationId: string,
    companyId: string,
    companyBankStatementUpsert: CompanyBankStatementUpsert
  ): Promise<ApiResponse<CompanyBankStatement>> => {
    try {
      const upsertRequest = {
        ...companyBankStatementUpsert,
        applicationId,
        companyId,
      };
      const response = await fmsServices().post<any, ApiResponse<CompanyBankStatement>>(
        '/banking/statements/upsert',
        upsertRequest
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<CompanyBankStatement>;
    }
  };

  const initializeCompanyBankStatement = async (applicationId: string, companyId: string) => ({
    applicationId,
    companyId,
    accountId: '',
    transactionTypeId: '',
    accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
    transactionDescription: '',
    transactionAmount: 0,
    transactionDate: new Date(),
    include: true,
  });

  const toggleCompanyBankStatementInclude = async (
    companyBankStatementUpdate: CompanyBankStatementIncludeUpdate
  ): Promise<ApiResponse<CompanyBankStatement>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementIncludeUpdate,
        ApiResponse<CompanyBankStatement>
      >('/banking/statements/toggle', companyBankStatementUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatement>;
    }
  };

  const aggregateCompanyBankStatementsMonthly = async (
    companyBankStatementsMonthlyAggregate: CompanyBankStatementsMonthlyAggregate
  ): Promise<ApiResponse<CompanyBankStatementsMonthly>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementsMonthlyAggregate,
        ApiResponse<CompanyBankStatementsMonthly>
      >('/banking/statements/monthly/aggregate', companyBankStatementsMonthlyAggregate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementsMonthly>;
    }
  };

  const searchCompanyBankStatementsMonthly = async (
    companyBankStatementsMonthlySearch: CompanyBankStatementsMonthlySearch
  ): Promise<ApiResponsePaged<CompanyBankStatementsMonthly[]>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementsMonthlySearch,
        ApiResponsePaged<CompanyBankStatementsMonthly[]>
      >('/banking/statements/monthly/search', companyBankStatementsMonthlySearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        CompanyBankStatementsMonthly[]
      >;
    }
  };

  const toggleCompanyBankStatementMonthlyInclude = async (
    companyBankStatementMonthlyUpdate: CompanyBankStatementMonthlyIncludeUpdate
  ): Promise<ApiResponse<CompanyBankStatementsMonthly>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementMonthlyIncludeUpdate,
        ApiResponse<CompanyBankStatementsMonthly>
      >('/banking/statements/monthly/toggle', companyBankStatementMonthlyUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementsMonthly>;
    }
  };

  const upsertCompanyBankStatementMonthly = async (
    companyBankStatementMonthly: CompanyBankStatementsMonthly,
    mode: string
  ): Promise<ApiResponse<CompanyBankStatementsMonthly>> => {
    try {
      if (mode === 'edit') {
        delete companyBankStatementMonthly.createdBy;
        delete companyBankStatementMonthly.createdAt;
        delete companyBankStatementMonthly.updatedBy;
        delete companyBankStatementMonthly.updatedAt;
        delete companyBankStatementMonthly.deletedAt;
      }

      const response = await fmsServices().post<
        CompanyBankStatementsMonthly,
        ApiResponse<CompanyBankStatementsMonthly>
      >('/banking/statements/monthly/upsert', companyBankStatementMonthly);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementsMonthly>;
    }
  };

  const initializeCompanyBankStatementAdvance = () =>
    ({
      companyId: '',
      accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
      advanceProviderId: '',
      amount: 0,
      frequency: 'Unknown',
      startDate: null,
      endDate: null,
      remainingBalance: 0,
      companyBankStatementAdvanceStatusId: null,
      enablePayoff: true,
      include: true,
      productId: null,
    }) as unknown as CompanyBankStatementAdvance;

  const upsertCompanyBankStatementAdvance = async (
    companyBankStatementAdvanceUpsert: CompanyBankStatementAdvanceUpsert
  ): Promise<ApiResponsePaged<CompanyBankStatementAdvance[]>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceUpsert,
        ApiResponsePaged<CompanyBankStatementAdvance[]>
      >('/banking/advances/upsert', companyBankStatementAdvanceUpsert);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        CompanyBankStatementAdvance[]
      >;
    }
  };

  const searchCompanyBankStatementAdvances = async (
    advanceSearch: CompanyBankStatementAdvanceSearch
  ): Promise<ApiResponsePaged<CompanyBankStatementAdvance[]>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceSearch,
        ApiResponsePaged<CompanyBankStatementAdvance[]>
      >('/banking/advances/search', advanceSearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        CompanyBankStatementAdvance[]
      >;
    }
  };

  const searchCompanyInternalAdvances = async (
    companyId: string,
    applicationId: string
  ): Promise<ApiResponse<CompanyInternalAdvances[]>> => {
    try {
      const response = await fmsServices().post<
        {
          companyId: string;
          applicationId: string;
        },
        ApiResponse<CompanyInternalAdvances[]>
      >('/banking/internaladvances/search', {
        companyId,
        applicationId,
      });
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages };
    }
  };

  const calculateCashFlow = async (
    applicationId: string,
    companyId: string
  ): Promise<ApiResponse<ApplicationCashFlow>> => {
    try {
      const response = await fmsServices().post<
        { applicationId: string; companyId: string },
        ApiResponse<ApplicationCashFlow>
      >('/banking/cashflow', { applicationId, companyId });
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<ApplicationCashFlow>;
    }
  };

  const searchAdvanceProviders = async (
    advanceProviderSearch: AdvanceProviderSearch
  ): Promise<ApiResponsePaged<AdvanceProvider[]>> => {
    try {
      const response = await fmsServices().post<
        AdvanceProviderSearch,
        ApiResponsePaged<AdvanceProvider[]>
      >('/banking/advanceproviders/search', advanceProviderSearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<AdvanceProvider[]>;
    }
  };

  const searchFilterOutInternalProviders = async (
    advanceProviderSearch: AdvanceProviderSearch
  ): Promise<ApiResponsePaged<AdvanceProvider[]>> => {
    try {
      const response = await fmsServices().post<
        AdvanceProviderSearch,
        ApiResponsePaged<AdvanceProvider[]>
      >('/banking/advanceproviders/search/filter-out-internal-providers', advanceProviderSearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<AdvanceProvider[]>;
    }
  };

  const upsertAdvanceProvider = async (
    data: AdvanceProvider,
    mode: string
  ): Promise<ApiResponse> => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    const response = await fmsServices().post<AdvanceProvider, ApiResponse>(
      '/banking/advanceproviders/upsert',
      data
    );
    return response;
  };

  const deleteAdvanceProvider = async (advanceProviderId: string): Promise<ApiResponse> =>
    await fmsServices().post<{}, ApiResponse>('/banking/advanceproviders/delete', {
      advanceProviderId,
    });

  const deleteBankAccount = async (accountId: string): Promise<ApiResponse> =>
    await fmsServices().post<any, ApiResponse>('/banking/account/delete/bank-account', {
      accountId,
    });

  const deleteCompanyBankStatementAdvanceStatus = async (
    companyBankStatementAdvanceDelete: CompanyBankStatementAdvanceDelete
  ): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceDelete,
        ApiResponse<CompanyBankStatementAdvance>
      >('/banking/advances/remove', companyBankStatementAdvanceDelete);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementAdvance>;
    }
  };

  const updateCompanyBankStatementAdvanceStatus = async (
    companyBankStatementAdvanceStatusUpdate: CompanyBankStatementAdvanceStatusUpdate
  ): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceStatusUpdate,
        ApiResponse<CompanyBankStatementAdvanceStatus>
      >('/banking/advances/status', companyBankStatementAdvanceStatusUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementAdvanceStatus>;
    }
  };

  const toggleCompanyBankStatementAdvanceEnablePayoff = async (
    enablePayoffUpdate: CompanyBankStatementAdvanceEnablePayoffUpdate
  ): Promise<ApiResponse<CompanyBankStatementAdvance>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceEnablePayoffUpdate,
        ApiResponse<CompanyBankStatementAdvance>
      >('/banking/advances/payoff/toggle', enablePayoffUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementAdvance>;
    }
  };

  const setCompanyInternalAdvancesEnablePayoff = async (
    fundingId: string,
    applicationId?: string
  ) => {
    try {
      const response: any = await fmsServices().post('/banking/internaladvances/payoff/toggle', {
        applicationId,
        fundingId,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      };
    }
  };

  const toggleCompanyBankStatementAdvanceInclude = async (
    advanceUpdate: CompanyBankStatementAdvanceIncludeUpdate
  ): Promise<ApiResponse<CompanyBankStatementAdvance>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementAdvanceIncludeUpdate,
        ApiResponse<CompanyBankStatementAdvance>
      >('/banking/advances/include/toggle', advanceUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementAdvance>;
    }
  };

  const initializeCompanyBankStatementRecurringPayment = async (
    applicationId: string,
    companyId: string
  ) => ({
    applicationId,
    companyBankStatementRecurringPaymentTypeId: '',
    accountDataProviderId: PRIMARY_DEFAULT_ACC_DATA_PROVIDER_ID_LIBERTAS,
    companyId,
    name: '',
    description: '',
    amount: 0,
    frequency: RemittanceFrequency.Monthly,
    include: true,
  });

  const searchCompanyBankStatementRecurringPayments = async (
    recurringPaymentSearch: CompanyBankStatementRecurringPaymentSearch
  ): Promise<ApiResponsePaged<CompanyBankStatementRecurringPayment[]>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementRecurringPaymentSearch,
        ApiResponsePaged<CompanyBankStatementRecurringPayment[]>
      >('/banking/recurringpayments/search', recurringPaymentSearch);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<
        CompanyBankStatementRecurringPayment[]
      >;
    }
  };

  const upsertCompanyBankStatementRecurringPayment = async (
    recurringPaymentUpsert: CompanyBankStatementRecurringPaymentUpsert
  ): Promise<ApiResponse<CompanyBankStatementRecurringPayment>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementRecurringPaymentUpsert,
        ApiResponse<CompanyBankStatementRecurringPayment>
      >('/banking/recurringpayments/upsert', recurringPaymentUpsert);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementRecurringPayment>;
    }
  };

  const deleteCompanyBankStatementRecurringPayment = async (
    recurringPaymentDelete: CompanyBankStatementRecurringPaymentDelete
  ): Promise<ApiResponse> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementRecurringPaymentDelete,
        ApiResponse
      >('/banking/recurringpayments/remove', recurringPaymentDelete);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse;
    }
  };

  const toggleCompanyBankStatementRecurringPaymentInclude = async (
    recurringPaymentUpdate: CompanyBankStatementRecurringPaymentIncludeUpdate
  ): Promise<ApiResponse<CompanyBankStatementRecurringPayment>> => {
    try {
      const response = await fmsServices().post<
        CompanyBankStatementRecurringPaymentIncludeUpdate,
        ApiResponse<CompanyBankStatementRecurringPayment>
      >('/banking/recurringpayments/include/toggle', recurringPaymentUpdate);
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementRecurringPayment>;
    }
  };

  const getCompanyBankStatementRecurringPaymentTypes = async (): Promise<
    ApiResponse<CompanyBankStatementRecurringPaymentType[]>
  > => {
    try {
      const response = await fmsServices().post<
        {},
        ApiResponse<CompanyBankStatementRecurringPaymentType[]>
      >('/banking/recurringpayments/types', {});
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<CompanyBankStatementRecurringPaymentType[]>;
    }
  };

  const uploadBankStatement = async (
    documentFile: DocumentFile,
    accountId?: string
  ): Promise<ApiResponse> => {
    let uploadBankStatementResponse: ApiResponse;
    const uploadForm = new FormData();
    uploadForm.append('companyId', documentFile.document.companyId);
    uploadForm.append('applicationId', documentFile.document.modelId);
    uploadForm.set('file', documentFile.file);

    if (accountId) {
      uploadForm.append('accountId', accountId);
    }
    uploadBankStatementResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/banking-actions/bank-statement/upload',
      uploadForm
    );
    return uploadBankStatementResponse;
  };

  const syncResults = async (applicationId: string): Promise<ApiResponse> => {
    const response = await fmsServices().post<{}, ApiResponse>('/moneythumb/syncresults', {
      applicationId,
    });
    return response;
  };

  const getMoneyThumbTransactions = async (
    companyId: string,
    applicationId: string
  ): Promise<ApiResponse<MoneyThumbCompanyBankStatement[]>> => {
    try {
      const response = await fmsServices().post<{}, ApiResponse<MoneyThumbCompanyBankStatement[]>>(
        '/moneythumb/transactions',
        {
          companyId,
          applicationId,
        }
      );
      return response;
    } catch (error: any) {
      return {
        success: false,
        message: error.messages,
      } as ApiResponse<MoneyThumbCompanyBankStatement[]>;
    }
  };

  const upsertBankingAccount = async (account: AccountUpsertRequest) => {
    try {
      const response = await fmsServices().post<AccountUpsertRequest, ApiResponsePaged<Account>>(
        '/banking/account/upsert',
        account
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<Account>;
    }
  };

  const upsertBankingAccountInstitution = async (accountInstitution: AccountInstitution) => {
    try {
      return fmsServices().post<AccountInstitution, ApiResponsePaged<AccountInstitution>>(
        '/banking/institutions/upsert',
        accountInstitution
      );
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponsePaged<Account>;
    }
  };

  const getBankDataSource = () => BANK_DATA_SOURCE_MONEY_THUMB;

  return {
    getAccountByModelId,
    initNewBankingAccount,
    getAccountTypes,
    getAccountStatuses,
    getAccountStatusById,
    getTransactionTypes,
    getBankInstitutionSupportedTransactionTypes,
    getCompanyBankStatementTransactionTypes,
    searchAccounts,
    searchAccountsFundedCompaniesOnly,
    getDebitingEntitiesAccounts,
    updateAccountStatus,
    handleCodatInvitation,
    handleCodatPull,
    searchInstitutions,
    searchCompanyBankStatements,
    toggleCompanyBankStatementInclude,
    aggregateCompanyBankStatementsMonthly,
    searchCompanyBankStatementsMonthly,
    toggleCompanyBankStatementMonthlyInclude,
    upsertCompanyBankStatementMonthly,
    initializeCompanyBankStatementAdvance,
    upsertCompanyBankStatementAdvance,
    searchCompanyBankStatementAdvances,
    searchAdvanceProviders,
    searchFilterOutInternalProviders,
    upsertAdvanceProvider,
    deleteAdvanceProvider,
    deleteBankAccount,
    upsertCompanyBankStatement,
    initializeCompanyBankStatement,
    deleteCompanyBankStatementAdvanceStatus,
    updateCompanyBankStatementAdvanceStatus,
    toggleCompanyBankStatementAdvanceEnablePayoff,
    setCompanyInternalAdvancesEnablePayoff,
    toggleCompanyBankStatementAdvanceInclude,
    getAdvanceStatuses,
    initializeCompanyBankStatementRecurringPayment,
    searchCompanyBankStatementRecurringPayments,
    upsertCompanyBankStatementRecurringPayment,
    deleteCompanyBankStatementRecurringPayment,
    toggleCompanyBankStatementRecurringPaymentInclude,
    getCompanyBankStatementRecurringPaymentTypes,
    uploadBankStatement,
    getMoneyThumbTransactions,
    syncResults,
    upsertBankingAccount,
    upsertBankingAccountInstitution,
    getBankDataSource,
    searchCompanyInternalAdvances,
    calculateCashFlow,
  };
};

export default BankingService;
