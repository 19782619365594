import { ReactNode, useRef } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      TransitionComponent={undefined}
      variant="success"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // With close as default
      action={(key) => (
        <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
