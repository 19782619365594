import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import { enUS } from 'date-fns/locale';
import * as yup from 'yup';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
// components
import PageTitle from 'components/Shared/PageTitle';
import SectionTitle from 'components/Shared/SectionTitle';
import JournalGroupEditorToolbar from 'components/Accounting/JournalGroupEditorToolbar';
// services
import JournalService from 'services/JournalService';
import FundingService from 'services/FundingService';
// models
import JournalEditorModel, { EditableJournal } from 'models/Accounting/JournalEditorModel';
import JournalGroup from 'models/Accounting/JournalGroup';
import JournalGroupUpsertRequest from 'models/Accounting/JournalGroupUpsertRequest';
// hooks and utils
import { useAccountingData } from 'hooks/useAccountingData';
import { formatCurrency } from 'utils/numbers/formatCurrency';
import formatDateTimeWithoutTimezone from 'utils/date/formatDateTimeWithoutTimezone';
import { MUIDataGridProColumnHeaderWrapStyle, BoxGridContainer } from 'styling/MUIStylingUtils';
import useConfirm from 'hooks/useConfirm';

const journalValidationSchema = yup.object({
  identifier: yup.string().required('Journals must be associated to a Funding'),
  transactionId: yup.string().required('Journals must be associated to a Transaction'),
  notes: yup.string().optional().nullable(),
});

const defaultInitialValues: JournalEditorModel = {
  journalGroupId: '',
  eedDate: undefined,
  fundingId: undefined,
  identifier: undefined, //funding.identifier
  transactionId: undefined,
  journalActionGroupId: '',
  amount: 0,
  notes: undefined,
  isReturn: false,
};

const JournalGroupSummary = React.memo(
  ({ totalCredits, totalDebits }: { totalCredits: number; totalDebits: number }) => {
    const theme = useTheme();
    return (
      <Grid container spacing={1} my={1}>
        <Grid item xs={6} />

        <Grid item xs={2}>
          Debits: {formatCurrency(totalDebits)}
        </Grid>
        <Grid item xs={2}>
          Credits: {formatCurrency(totalCredits)}
        </Grid>
        <Grid item xs={2} sx={{ color: theme.palette.error.main }}>
          <strong>Imbalance: {formatCurrency(totalCredits - totalDebits)}</strong>
        </Grid>
      </Grid>
    );
  }
);

const JournalEditor = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { journalTypes, journalActionGroups } = useAccountingData();

  const [rows, setRows] = useState<GridRowsProp<EditableJournal>>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [journalGroup, setJournalGroup] = useState<JournalGroup | undefined>();

  const journalService = useMemo(JournalService, []);
  const fundingService = useMemo(FundingService, []);

  const { getConfirmation, ConfirmationModal } = useConfirm();

  const totalCredits = useMemo(
    () =>
      rows
        .filter((journal) => journal.creditOrDebit === 'credit')
        .reduce((acc, curr) => acc + +curr.absoluteAmount, 0),
    [rows]
  );

  const totalDebits = useMemo(
    () =>
      rows
        .filter((journal) => journal.creditOrDebit === 'debit')
        .reduce((acc, curr) => acc + +curr.absoluteAmount, 0),
    [rows]
  );

  const initialValues = useMemo(() => {
    let values = {
      ...defaultInitialValues,
      transactionId: searchParams.get('transactionId'),
      journalGroupId: searchParams.get('journalGroupId'),
      fundingId: searchParams.get('fundingId') ?? '',
      identifier: searchParams.get('fundingIdentifier') ?? '',
      funding: {
        fundingId: searchParams.get('fundingId') ?? '',
        identifier: searchParams.get('fundingIdentifier') ?? '',
      },
    };

    if (journalGroup?.journalGroupId) {
      values = {
        ...values,
        ...journalGroup,
      };
    }

    return values;
  }, [searchParams, journalGroup]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: journalValidationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const { values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit } = formik;

  const getFundingSearchResponse = useCallback(
    async ({
      offerIdentifier,
      fundingIdentifier,
      fundingId,
    }: {
      offerIdentifier?: string;
      fundingIdentifier?: string;
      fundingId?: string;
    }) => {
      const fundingSearchResponse = await fundingService.searchFunding({
        offerIdentifier,
        identifier: fundingIdentifier,
        pageNumber: 1,
        pageSize: 10,
      });
      if (fundingSearchResponse?.data?.length) {
        if (fundingSearchResponse.data.length === 1) {
          const [funding] = fundingSearchResponse.data;

          setFieldValue('fundingId', funding?.fundingId);
          setFieldValue('identifier', funding?.identifier);
        }
      }
    },
    [fundingService, setFieldValue]
  );

  const onSubmit = useCallback(
    async (formData) => {
      const { fundingId, ...journalGroup } = formData as JournalEditorModel;

      if (rows.some((row) => !row.description?.trim())) {
        enqueueSnackbar(`Description is required.`, { variant: 'error' });
        return;
      }

      if (rows.some((row) => !row.journalTypeId)) {
        enqueueSnackbar(`Journal Type is required.`, { variant: 'error' });
        return;
      }

      try {
        const journalGroupUpsert: JournalGroupUpsertRequest = {
          journalGroup: {
            journalGroupId: journalGroup.journalGroupId,
            journalActionGroupId: journalGroup.journalActionGroupId,
            journalSystemActionEventId: journalGroup.journalSystemActionEventId,
            transactionId: journalGroup.transactionId,
            amount: journalGroup.amount,
            notes: journalGroup.notes,
            isReturn: journalGroup.isReturn,
          },
          journals: rows.map((row) => ({
            fundingId,
            eedDate: row.eedDate,
            journalId: row.journalId,
            journalTypeId: row.journalTypeId,
            journalGroupId: row.journalGroupId,
            transactionId: row.transactionId,
            modelId: row.modelId,
            signedAmount: row.signedAmount,
            absoluteAmount: row.absoluteAmount,
            creditOrDebit: row.creditOrDebit,
            description: row.description,
            notes: row.notes,
            journalMonth: row.journalMonth,
            journalYear: row.journalYear,
          })),
          fundingId,
          fundingIdentifier: formData.identifier,
        };

        const upsertResponse =
          await journalService.upsertJournalGroupWithJournals(journalGroupUpsert);
        if (upsertResponse.success) {
          navigate(-1);
          enqueueSnackbar('Journal Saved Successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(`Error saving journals. ${upsertResponse.message}`, { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar(`Error saving journals. ${error.message}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, navigate, journalService, rows]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      setRows(rows.filter((row) => row.id !== id));
    },
    [setRows, rows]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [setRowModesModel, rowModesModel, setRows, rows]
  );

  const handleDeleteJournalGroup = async () => {
    const responseConfirmation = await getConfirmation(
      'Delete Journal Group',
      'Are you sure you want to delete this journal group?'
    );
    if (responseConfirmation && values.journalGroupId) {
      handleOnDeleteJournalGroup(values.journalGroupId);
    }
  };

  const handleOnDeleteJournalGroup = async (journalGroupId: string) => {
    const responseDelete = await journalService.deleteJournalGroup(journalGroupId);
    if (responseDelete.success) {
      navigate(-1);
      enqueueSnackbar(responseDelete.message, { variant: 'success' });
    } else {
      enqueueSnackbar(`${responseDelete.message}. ${responseDelete.errors}`, { variant: 'error' });
    }
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel<EditableJournal>) => {
      const updatedRow: EditableJournal = {
        ...newRow,
        isNew: false,
        signedAmount:
          newRow.creditOrDebit === 'credit' && newRow.absoluteAmount > 0
            ? newRow.absoluteAmount * -1
            : newRow.absoluteAmount,
        transactionId: journalGroup?.transactionId,
        journalMonth: new Date(newRow.eedDate!).getMonth(),
        journalYear: new Date(newRow.eedDate!).getFullYear(),
      };

      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [setRows, rows, journalGroup]
  );

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    [setRowModesModel]
  );

  const columns: GridColDef<EditableJournal>[] = useMemo(
    (): GridColDef<EditableJournal>[] => [
      {
        field: 'journalTypeId',
        headerName: 'Journal Type Name',
        flex: 1,
        type: 'singleSelect',
        editable: true,
        valueOptions: [{ value: '', label: '' }].concat(
          journalTypes.map((journalType) => ({
            value: journalType.journalTypeId!,
            label: journalType.journalTypeName!,
          }))
        ),
      },
      {
        field: 'creditOrDebit',
        headerName: 'Credit or Debit',
        width: 125,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['credit', 'debit'],
      },
      {
        field: 'absoluteAmount',
        headerName: 'Amount',
        type: 'number',
        width: 150,
        editable: true,
        renderCell: (params) => formatCurrency(params.row.absoluteAmount),
      },
      {
        field: 'eedDate',
        headerName: 'EED',
        flex: 1,
        type: 'date',
        editable: true,
        valueGetter: (_, row) =>
          row.eedDate ? new Date(formatDateTimeWithoutTimezone(row.eedDate)) : undefined,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: true,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        flex: 1,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                key={'save'}
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                key={'cancel'}
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              key={'edit-journal'}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              key={'delete-journal'}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      handleCancelClick,
      handleEditClick,
      handleDeleteClick,
      handleSaveClick,
      rowModesModel,
      journalTypes,
    ]
  );

  useEffect(() => {
    if (!initialValues.identifier) {
      return;
    }

    getFundingSearchResponse({ fundingIdentifier: initialValues.identifier });
  }, [initialValues.identifier, getFundingSearchResponse]);

  useEffect(() => {
    const transactionId = searchParams.get('transactionId') ?? '';
    const journalGroupId = searchParams.get('journalGroupId') ?? '';
    const offerIdentifier = searchParams.get('offerIdentifier') ?? '';
    const fundingIdentifier = searchParams.get('fundingIdentifier') ?? '';
    const fundingId = searchParams.get('fundingId') ?? '';

    if (journalGroupId) {
      journalService.search({ journalGroupId }).then((result) => {
        if (result.success && result.data) {
          setRows(
            result.data.map(
              (journal) =>
                ({
                  ...journal,
                  id: journal.journalId,
                  isNew: false,
                  mode: GridRowModes.View,
                  journalTypeId: journal.journalType?.journalTypeId ?? '',
                }) as EditableJournal
            )
          );
        }
      });

      journalService.getJournalGroup(journalGroupId).then((result) => {
        if (result.success && result.data) {
          const journalGroupResponse = result.data;
          setJournalGroup(journalGroupResponse);
        }
      });
    } else if (transactionId) {
      journalService.search({ transactionId }).then((result) => {
        if (result.success && result.data) {
          setRows(
            result.data.map(
              (journal) =>
                ({
                  ...journal,
                  id: journal.journalId,
                  isNew: false,
                  mode: GridRowModes.View,
                  journalTypeId: journal.journalType?.journalTypeId ?? '',
                }) as EditableJournal
            )
          );
        }
      });
    } else if (fundingId) {
      // Search by journalGroupId take precedence
      getFundingSearchResponse({ fundingId });
    } else if (fundingIdentifier) {
      getFundingSearchResponse({ fundingIdentifier });
    } else if (offerIdentifier) {
      getFundingSearchResponse({ offerIdentifier });
    }
  }, [journalService, searchParams, getFundingSearchResponse, setJournalGroup, setRows]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Container maxWidth={false}>
            <PageTitle title="Journal Group Editor" />
            <Grid container spacing={2} my={1}>
              <Grid item xs={12}>
                <SectionTitle title={'Group Details: ' + journalGroup?.identifier ?? ''} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  required
                  id="identifier"
                  name="identifier"
                  label="Funding Identifier"
                  variant="standard"
                  value={values.identifier}
                  onChange={handleChange}
                  error={touched.identifier && Boolean(errors.identifier)}
                  helperText={touched.identifier && errors.identifier}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  required
                  id="amount"
                  name="amount"
                  label="Amount"
                  variant="standard"
                  value={values.amount}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={touched.amount && Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-action-group-label" shrink={true} required={true}>
                    Action Group
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    name="journalActionGroupId"
                    variant="standard"
                    id="select-journal-action-group"
                    label="Journal Type"
                    value={values.journalActionGroupId}
                    onChange={handleChange}
                  >
                    <MenuItem value={''} key={'empty-option'}>
                      <em>&nbsp;</em>
                    </MenuItem>
                    {journalActionGroups.map(({ journalActionGroupId, journalActionGroupName }) => (
                      <MenuItem key={journalActionGroupId} value={journalActionGroupId}>
                        {journalActionGroupName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  id="journalGroupId"
                  name="journalGroupId"
                  value={values.journalGroupId ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Group Id"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={touched.journalGroupId && Boolean(errors.journalGroupId)}
                  helperText={touched.journalGroupId && errors.journalGroupId}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  fullWidth
                  id="transactionId"
                  name="transactionId"
                  value={values.transactionId ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Transaction Id"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={touched.transactionId && Boolean(errors.transactionId)}
                  helperText={touched.transactionId && errors.transactionId}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  id="notes"
                  name="notes"
                  value={values.notes ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Notes"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  sx={{ marginTop: 1 }}
                  id={'isReturn'}
                  name={'isReturn'}
                  control={<Checkbox checked={values.isReturn} onChange={handleChange} />}
                  label="Is Return"
                />
              </Grid>
              <Grid item xs={12}>
                <BoxGridContainer
                  sx={{
                    height: 'calc(100vh - 485px)',
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary',
                    },
                    '& .textPrimary': {
                      color: 'text.primary',
                    },
                  }}
                >
                  <SectionTitle title={'Journal Items'} />
                  <DataGridPro
                    density={'compact'}
                    sx={MUIDataGridProColumnHeaderWrapStyle}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: JournalGroupEditorToolbar,
                      noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          No Journals
                        </Stack>
                      ),
                    }}
                    slotProps={{
                      toolbar: { setRows, setRowModesModel, eedDate: values.eedDate },
                    }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                  />
                </BoxGridContainer>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={1}>
                <JournalGroupSummary totalCredits={totalCredits} totalDebits={totalDebits} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'caption'} component={'div'}>
                  Action Group ID: {values.journalActionGroupId}
                </Typography>
                <Typography variant={'caption'} component={'div'}>
                  System Action Event ID: {values.journalSystemActionEventId}
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="right">
                <Button type="submit" variant="contained" size={'small'}>
                  Save
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  size={'small'}
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant={'text'}
                >
                  Cancel
                </Button>
                {values.journalGroupId && (
                  <Button
                    color={'secondary'}
                    variant={'text'}
                    sx={{ ml: 1 }}
                    size={'small'}
                    onClick={handleDeleteJournalGroup}
                  >
                    Delete Group
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormikProvider>
      <ConfirmationModal />
    </LocalizationProvider>
  );
};

export default React.memo(JournalEditor);
