import ApiResponse from 'models/API/ApiResponse';
import fmsServices from 'services/fmsServices';
import { generateId } from 'utils/generateId';
// models
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import Document from 'models/Documents/Document';
import DocumentSearch from 'models/Documents/DocumentSearch';
import DocumentFile from 'models/Documents/DocumentFile';
import DocumentTypesSearch from 'models/Documents/DocumentTypeSearch';
import DocumentRequirementsSearch from 'models/Documents/DocumentRequirementsSearch';
import DocumentType from 'models/Documents/DocumentType';
import InitNewDocumentFileOptions from 'models/Documents/InitNewDocumentFileOptions';
import DocumentGeneration from 'models/Documents/DocumentGeneration';

// max files to upload at the same time in upload documents component
export const MAX_FILES_UPLOAD = 50;
// alert text above the file drop area inside upload documents component
export const DEFAULT_UPLOAD_ALERT_TEXT =
  'Please upload the required documents and map them. (Application, Three Most Recent Bank Statements & Most Recent Financials (Balance Sheet and Income Statement/P&L)';

export const SUPPORTED_FILE_TYPES_BANK_STATEMENT = ['pdf'];
export const SUPPORTED_FILE_TYPES_BANK_STATEMENT_DROPZONE = { 'application/pdf': ['.pdf'] };

export const SUPPORTED_FILE_TYPES_STIPS = [
  'pdf',
  'png',
  'jpg',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'zip',
  'heic',
  'csv',
];
export const SUPPORTED_FILE_TYPES_STIPS_DROPZONE = {
  'image/*': ['.png', '.jpg', '.jpeg', '.heic'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/zip': ['.zip'],
  'text/csv': ['.csv'],
};

export const SUPPORTED_FILE_TYPES_PREVIEW = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'pdf',
];
// max file size in MB
export const MAX_FILE_SIZE_UPLOAD_IN_MB = 50;

/** Document Upload Configuration */
export const documentUploadConfig = {
  MAX_FILES_UPLOAD,
  MAX_FILE_SIZE_UPLOAD_IN_MB,
  DEFAULT_UPLOAD_ALERT_TEXT,

  SUPPORTED_FILE_TYPES_STIPS,
  SUPPORTED_FILE_TYPES_STIPS_DROPZONE,

  SUPPORTED_FILE_TYPES_BANK_STATEMENT,
  SUPPORTED_FILE_TYPES_BANK_STATEMENT_DROPZONE,
};

export const DOCUMENT_STATUS_ID_SUBMITTED = '73807700-75f3-46b3-9d59-9f9d4fa185fb';
export const DOCUMENT_STATUS_ID_MISSING = 'dfe1669f-0fb5-4acd-8092-dcbbd5fa067a';
export const DOCUMENT_PROCESSING_STATUS_ID_PROCESSING = '6d2b8ed2-d002-4f2f-9e36-c73cc556d588';
export const DOCUMENT_PROCESSING_STATUS_ID_COMPLETED = 'a22e996c-9cb1-4bfc-a99b-ef31381b7228';
export const DOCUMENT_PROCESSING_STATUS_ID_FAILED = 'e7080fdb-a4f5-4393-a858-7708dda69697';
export const BANK_STATEMENT_YEARLY_DOC_TYPE_ID = '0528b6b4-87bb-4038-a1cc-4e8b1e779bc9';
/** Specific document for credit committee meeting */
export const CREDIT_COMMITTEE_INFORMATION_DOC_TYPE_ID = 'f3db9cdb-16ba-469f-b4fd-522f53c7d9e5';
export const BANK_STATEMENT_MONTHLY_DOC_TYPE_ID = '5c89e41f-7d78-4a8e-8215-884d0f927516';
export const DOCUMENT_TYPE_ID_BUSINESS_CREDIT_REPORT = '73167e3f-359d-44ac-8411-f3190b58fcc1';
export const DOCUMENT_TYPE_ID_PERSONAL_CREDIT_REPORT = '70902dca-8252-49fa-bd27-ec89bfbf14fe';
export const SIGNED_APPLICATION_DOC_TYPE_ID = 'de983595-bd63-4d5e-a88d-2128c7486700';
export const GENERATED_CONTACT_DOC_TYPE_ID = '29eaeca3-4342-442b-add1-83d3d1281c12';
export const ITEMIZATION_DOC_TYPE_ID = 'ad5e015f-8d63-44ce-b62e-b690876bd323';
export const DISCLOSURE_DOC_TYPE_ID = '89da055b-2b11-4fca-949b-31ce85df162a';
export const SIGNED_CONTRACT_DOC_TYPE_ID = '4deaeca3-4342-442b-add1-83d3d1281c65';
export const DOCUMENT_TYPE_PARTICIPATION_MASTER_AGREEMENT = '884af739-8d37-4904-9922-f416104d0170';

/** Specific documents for customer service notes */
const DOCUMENT_TYPE_ACH_AUTHORIZATION_FORM = '05935d66-586b-4725-8439-68ed6316332d';
export const DOCUMENT_TYPE_VOID_CHECK = '09b74490-7453-411d-bf45-0de6c0b0011a';
export const DOCUMENT_TYPE_W9 = 'cf216a2e-23e1-42c3-9f4e-d735c5cbd97d';
const DOCUMENT_TYPE_BANK_STATEMENT = '5c89e41f-7d78-4a8e-8215-884d0f927516';
const DOCUMENT_TYPE_FINANCIALS = '1ed302f1-a4c1-3f7a-3a13-85be5c19374a';
const DOCUMENT_TYPE_AGING_AR = '1d5685a3-f8e3-4b68-9485-e9f460f7acfc';
const DOCUMENT_TYPE_INTERNAL_LEGAL_LETTER = '22948168-864a-0fe3-3d06-2ce48a257ddb';
const DOCUMENT_TYPE_SETTLEMENT_AGREEMENT = 'c5f7df73-b5b0-d95c-7c23-ce9bd6762740';
const DOCUMENT_TYPE_TERM_SHEET = 'abdd1db6-054a-c581-8a28-a94c5fcfe5ba';
const DOCUMENT_TYPE_PAYOFF_BALANCE_LETTER = '107c034c-b1a9-4fac-8e65-1aa1edff85e4';
const DOCUMENT_TYPE_ZERO_BALANCE_LETTER = 'ca00335b-b594-4dcc-a58d-d8f541ae0125';
const DOCUMENT_TYPE_LEGAL_DOCUMENTS = 'eb046e91-0b5c-9134-6018-91a8848f944d';
const DOCUMENT_TYPE_OTHER = '8fee3c27-cab2-d687-6045-03a7d9d8f701';

export const NOTE_DOCUMENT_TYPES = [
  DOCUMENT_TYPE_ACH_AUTHORIZATION_FORM,
  DOCUMENT_TYPE_VOID_CHECK,
  DOCUMENT_TYPE_BANK_STATEMENT,
  DOCUMENT_TYPE_FINANCIALS,
  DOCUMENT_TYPE_AGING_AR,
  DOCUMENT_TYPE_INTERNAL_LEGAL_LETTER,
  DOCUMENT_TYPE_SETTLEMENT_AGREEMENT,
  DOCUMENT_TYPE_TERM_SHEET,
  DOCUMENT_TYPE_PAYOFF_BALANCE_LETTER,
  DOCUMENT_TYPE_ZERO_BALANCE_LETTER,
  DOCUMENT_TYPE_LEGAL_DOCUMENTS,
  DOCUMENT_TYPE_OTHER,
];

/**
This service provides data for "Documents" which is one core entity
within the system that represents the "stipulations" or documents that
are the supporting evidence for underwriting and decision makers.
*/
const DocumentsService = () => {
  const initNewDocumentFile = (options: InitNewDocumentFileOptions): DocumentFile => {
    let id: string = options?.documentId ? options.documentId : generateId();
    let document = {} as Document;
    document.documentName = options.file.name;
    document.documentTypeId = options.documentTypeId;
    document.companyId = options?.companyId ?? '';
    document.modelId = options?.modelId;
    document.documentStatusId = DOCUMENT_STATUS_ID_SUBMITTED;
    document.modelType = options.modelType;
    return {
      id,
      document,
      file: options.file,
    };
  };

  const getBankstatementDocumentType = (): DocumentType => {
    const bankStatementDocType: DocumentType = {
      documentTypeId: BANK_STATEMENT_MONTHLY_DOC_TYPE_ID,
      documentTypeName: 'Bank Statement',
      documentTypeDescription: 'Monthly Bank Statement',
    };
    return bankStatementDocType;
  };

  const getDocumentTypes = async ({
    allowExternalDelivery,
    productId,
  }: {
    allowExternalDelivery?: boolean;
    productId?: string;
  } = {}): Promise<ApiResponsePaged<DocumentType[]>> => {
    if (allowExternalDelivery) {
      const documentTypesExternalResponse = await fmsServices().post<
        any,
        ApiResponsePaged<DocumentType[]>
      >('/documents/types', {
        allowExternalDelivery,
        productId,
      });
      return documentTypesExternalResponse;
    } else {
      const documentTypes = await fmsServices().post<any, ApiResponsePaged<DocumentType[]>>(
        '/documents/types',
        {
          productId,
        }
      );
      return documentTypes;
    }
  };

  const searchDocumentTypes = async (
    documentsSearch: DocumentTypesSearch
  ): Promise<ApiResponsePaged> => {
    const searchResponseDocumentsTypes = await fmsServices().post<
      DocumentTypesSearch,
      ApiResponsePaged
    >('/documents/types/search', documentsSearch);
    return searchResponseDocumentsTypes;
  };

  const uploadDocument = async (documentFile: DocumentFile): Promise<ApiResponse> => {
    const uploadForm = new FormData();
    if (documentFile.document.companyId) {
      uploadForm.append('companyId', documentFile.document.companyId);
    }
    uploadForm.append('documentName', documentFile.file.name);
    uploadForm.append('documentFileType', documentFile.file.type);
    uploadForm.append('documentStatusId', documentFile.document.documentStatusId);
    uploadForm.append('documentTypeId', documentFile.document.documentTypeId!);
    uploadForm.append('modelId', documentFile.document.modelId!);
    uploadForm.append('modelType', documentFile.document.modelType!);
    uploadForm.set('file', documentFile.file);
    const uploadDocumentResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/documents/upload',
      uploadForm
    );
    return uploadDocumentResponse;
  };

  const uploadDocuments = async (documentFiles: DocumentFile[]): Promise<ApiResponse> => {
    let apiResponse: ApiResponse = { message: '', success: false };
    let messages = '';
    let anyErrors = false;
    for (let i = 0; i < documentFiles.length; i++) {
      const docFile = documentFiles[i];
      const uploadDocumentResponse = await uploadDocument(docFile);
      if (uploadDocumentResponse.success) {
        messages += `File ${docFile.file.name} uploaded successfully`;
      } else {
        messages += `File ${docFile.file.name} NOT uploaded successfully`;
        anyErrors = true;
        apiResponse.errors = uploadDocumentResponse.errors;
      }
    }
    apiResponse.message = messages;
    apiResponse.success = anyErrors === false;

    return apiResponse;
  };

  const upsertDocument = async (payload: any): Promise<ApiResponse> => {
    const responseUpsert = await fmsServices().post<any, ApiResponse>('/documents/upsert', payload);
    return responseUpsert;
  };

  const uploadEditedDocument = async (documentFile: DocumentFile): Promise<ApiResponse> => {
    const uploadForm = new FormData();
    uploadForm.append('companyId', documentFile.document.companyId);
    uploadForm.append('applicationId', documentFile.document.modelId);
    uploadForm.append('documentName', documentFile.file.name);
    uploadForm.append('documentDescription', documentFile?.document?.documentDescription!);
    uploadForm.append('documentFileType', documentFile.file.type);
    uploadForm.append('documentStatusId', documentFile.document.documentStatusId);
    uploadForm.append('documentTypeId', documentFile.document.documentTypeId!);
    uploadForm.append('modelId', documentFile.document.modelId!);
    uploadForm.append('modelType', documentFile.document.modelType!);
    uploadForm.append('documentId', documentFile.document.documentId!);
    uploadForm.set('file', documentFile.file);
    const uploadDocumentResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/documents/upload',
      uploadForm
    );
    return uploadDocumentResponse;
  };

  const uploadRequiredDocuments = async (
    documentFiles: DocumentFile[],
    documents: Document[]
  ): Promise<ApiResponse> => {
    let apiResponse: ApiResponse = { message: '', success: false };
    let messages = '';
    let anyErrors = false;
    let uploadResponse: ApiResponse = { message: '', success: false };
    documentFiles.forEach(async (doc: DocumentFile, idx: number) => {
      const uploadForm = new FormData();
      uploadForm.append('companyId', doc.document.companyId);
      uploadForm.append('applicationId', doc.document.companyId);
      uploadForm.append('documentName', doc.file.name);
      uploadForm.append('documentFileType', doc.file.type);
      uploadForm.append('documentStatusId', doc.document.documentStatusId);
      uploadForm.append('documentTypeId', doc.document.documentTypeId!);
      uploadForm.append('modelId', doc.document.modelId!);
      uploadForm.append('modelType', doc.document.modelType!);
      uploadForm.append('documentId', documents[idx].documentId!);
      uploadForm.set('file', doc.file);
      uploadResponse = await fmsServices().postFormData<any, ApiResponse>(
        '/documents/upload',
        uploadForm
      );
      if (uploadResponse.success) {
        messages += `File ${doc.file.name} uploaded successfully`;
      } else {
        messages += `File ${doc.file.name} NOT uploaded successfully`;
        anyErrors = true;
        apiResponse.errors = uploadResponse.errors;
      }
      apiResponse.message = messages;
      apiResponse.success = anyErrors === false;
    });
    return uploadResponse;
  };

  const searchDocuments = async (
    documentsSearch: DocumentSearch
  ): Promise<ApiResponsePaged<Document[]>> => {
    const searchResponseDocuments = await fmsServices().post<
      DocumentSearch,
      ApiResponsePaged<Document[]>
    >('/documents/search', documentsSearch);
    return searchResponseDocuments;
  };

  const getParticipantAccountsDocuments = async (participantAccountIds: string[]) => {
    const searchResponseDocuments = await fmsServices().post('/documents/participant', {
      participantAccountIds,
    });
    return searchResponseDocuments;
  };

  const removeDocument = async (documentId: string): Promise<ApiResponsePaged> => {
    const removeDocumentResponse = await fmsServices().post<any, ApiResponsePaged>(
      '/documents/remove',
      { documentId }
    );
    return removeDocumentResponse;
  };

  const downloadDocumentUrl = async (
    companyId: string,
    applicationId: string,
    documentId: string
  ): Promise<ApiResponse> => {
    const downloadDocumentUrlResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/downloadurl',
      { companyId, applicationId, documentId }
    );
    return downloadDocumentUrlResponse;
  };

  const downloadParticipantDocumentUrl = async (
    participantAccountId: string
  ): Promise<ApiResponse> => {
    const downloadDocumentUrlResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/participant/downloadurl',
      { participantAccountId }
    );
    return downloadDocumentUrlResponse;
  };

  const downloadPartnerDocumentUrl = async (
    partnerId: string,
    documentId: string
  ): Promise<ApiResponse> => {
    const downloadDocumentUrlResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/partner/downloadurl',
      { partnerId, documentId }
    );
    return downloadDocumentUrlResponse;
  };

  const downloadAllDocuments = async (applicationId: string, companyId: string) => {
    const downloadAllDocumentsResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/batch/downloaddocuments',
      { companyId, applicationId }
    );
    return downloadAllDocumentsResponse;
  };

  const updateDocument = async (document: Document): Promise<ApiResponse> => {
    const updateDocumentResponse = await fmsServices().post<Document, ApiResponse>(
      '/documents/update',
      document
    );
    return updateDocumentResponse;
  };

  const updateMissingDocument = async (
    documentFile: DocumentFile,
    documentId: string
  ): Promise<ApiResponse> => {
    const uploadForm = new FormData();
    uploadForm.set('documentId', documentId);
    uploadForm.append('companyId', documentFile.document.companyId);
    uploadForm.append('applicationId', documentFile.document.modelId);
    uploadForm.append('documentName', documentFile.file.name);
    uploadForm.append('documentFileType', documentFile.file.type);
    uploadForm.append('documentStatusId', documentFile.document.documentStatusId);
    uploadForm.append('documentTypeId', documentFile.document.documentTypeId!);
    uploadForm.append('modelId', documentFile.document.modelId!);
    uploadForm.append('modelType', documentFile.document.modelType!);
    uploadForm.set('file', documentFile.file);
    const uploadMissingDocumentResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/documents/upload',
      uploadForm
    );
    return uploadMissingDocumentResponse;
  };

  const getDocumentStatuses = async (): Promise<ApiResponse> => {
    const documentStatusResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/statuses'
    );
    return documentStatusResponse;
  };

  const updateDocumentStatus = async (
    documentId: string,
    documentStatusId: string
  ): Promise<ApiResponse> => {
    const updateDocumentResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/status/update',
      { documentId, documentStatusId }
    );
    return updateDocumentResponse;
  };

  const getDocumentRequirements = async (
    searchRequest: DocumentRequirementsSearch
  ): Promise<ApiResponse> => {
    const documentRequirementsResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/requirements/search',
      searchRequest
    );
    return documentRequirementsResponse;
  };

  const generateRequiredDocuments = async (payload: any): Promise<ApiResponse> => {
    const generationResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/requirements/generate',
      payload
    );
    return generationResponse;
  };

  const sentRequiredDocumentsNotification = async (values: any): Promise<ApiResponse> => {
    const {
      applicationId,
      note,
      includeInternalDocumentsInEmail,
      includeExternalDocumentsInEmail,
      sendEmailToPartnerManager,
      sendEmailToPartnerAndManager,
    } = values;
    const sentRequiredDocumentsResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/requirements/notifications',
      {
        applicationId,
        note,
        includeInternalDocumentsInEmail,
        includeExternalDocumentsInEmail,
        sendEmailToPartnerManager,
        sendEmailToPartnerAndManager,
      }
    );
    return sentRequiredDocumentsResponse;
  };

  const getDocumentTypeById = async (documentTypeId: string) => {
    const documentTypeResponse = await getDocumentTypes();
    if (!documentTypeResponse.data) return;
    const filteredDocuments = documentTypeResponse.data.filter(
      (documentType) => documentType.documentTypeId === documentTypeId
    );
    if (!filteredDocuments) return;
    return filteredDocuments;
  };

  const downloadDocumentBase64 = async (documentId: string) => {
    const documentResponse = await fmsServices().post<any, ApiResponse>(`/documents/download`, {
      documentId,
    });
    const { data } = documentResponse;
    return data;
  };

  const getDocumentGeneration = async (): Promise<ApiResponse> => {
    const documentGenerationResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/generation/search'
    );
    return documentGenerationResponse;
  };

  const upsertDocumentGeneration = async (
    documentGeneration: DocumentGeneration
  ): Promise<ApiResponse> => {
    const documentGenerationResponse = await fmsServices().post<any, ApiResponse>(
      '/documents/generation/upsert',
      documentGeneration
    );
    return documentGenerationResponse;
  };

  return {
    getBankstatementDocumentType,
    getDocumentTypes,
    getDocumentTypeById,
    initNewDocumentFile,
    searchDocumentTypes,
    upsertDocument,
    uploadDocuments,
    uploadDocument,
    uploadEditedDocument,
    uploadRequiredDocuments,
    updateDocument,
    updateMissingDocument,
    searchDocuments,
    removeDocument,
    downloadDocumentUrl,
    downloadPartnerDocumentUrl,
    downloadAllDocuments,
    getDocumentStatuses,
    updateDocumentStatus,
    getDocumentRequirements,
    generateRequiredDocuments,
    sentRequiredDocumentsNotification,
    downloadDocumentBase64,
    downloadParticipantDocumentUrl,
    getParticipantAccountsDocuments,
    getDocumentGeneration,
    upsertDocumentGeneration,
  };
};

export default DocumentsService;
