import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import Notification from 'models/Notification/Notification';
import NotificationSearch from 'models/Notification/NotificationSearch';

const NotificationService = () => {
  const upsertNotification = async (notification: Notification): Promise<ApiResponse> => {
    const upsertNotificationResponse = await fmsServices().post<Notification, ApiResponse>(
      '/notification/upsert',
      notification
    );

    return upsertNotificationResponse;
  };

  const searchNotifications = async (
    notificationSearch: NotificationSearch
  ): Promise<ApiResponsePaged> => {
    const searchNotificationResponse = await fmsServices().post<
      NotificationSearch,
      ApiResponsePaged
    >('/notification/search', notificationSearch);
    return searchNotificationResponse;
  };

  const removeNotification = async (notificationId: string): Promise<ApiResponse> => {
    const upsertNotificationResponse = await fmsServices().post<
      { notificationId: string },
      ApiResponse
    >('/notification/remove', { notificationId });

    return upsertNotificationResponse;
  };

  const markAllAsRead = async (): Promise<ApiResponse> => {
    const upsertNotificationResponse = await fmsServices().post<Notification, ApiResponse>(
      '/notification/markallasread'
    );

    return upsertNotificationResponse;
  };

  return {
    upsertNotification,
    markAllAsRead,
    searchNotifications,
    removeNotification,
  };
};

export default NotificationService;
