import { useContext } from 'react';
import { AuthContextFMS } from 'contexts/AuthContextFMS';

const useAuth = () => {
  const context = useContext(AuthContextFMS);

  if (!context) throw new Error('Auth context must be present inside AuthProvider');

  return context;
};

export default useAuth;
