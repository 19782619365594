import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import { enUS } from 'date-fns/locale';
import * as yup from 'yup';
import {
  Autocomplete,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
// components
import PageTitle from 'components/Shared/PageTitle';
import SectionTitle from 'components/Shared/SectionTitle';
// services
import FundingService from 'services/FundingService';
import TransactionService, {
  TRANSACTION_EVENT_ID_SERVICING_RECEIVE_REMITTANCE,
  TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT,
  TRANSACTION_STATUS_ID_READY_TO_SEND,
  TRANSACTION_TYPE_ACH,
} from 'services/TransactionSevice';
import BankingService, { FUNDER_ACCOUNT_TYPE_ID } from 'services/BankingService';
import FunderService from 'services/FunderService';
// models
import TransactionWorkflowModel, {
  EditableTransaction,
} from 'models/Accounting/TransactionWorkflowModel';
import TransactionWorkflow from 'models/Transactions/TransactionWorkflow';
import AccountsSearch from 'models/Banking/AccountsSearch';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import Account from 'models/Banking/Account';
// hooks and utils
import { useAccountingData } from 'hooks/useAccountingData';
import useConfirm from 'hooks/useConfirm';
import { generateId } from 'utils/generateId';
import { formatCurrency } from 'utils/numbers/formatCurrency';
import { removeEmpty } from 'utils/date/removeEmpty';

const workflowValidationSchema = yup.object({
  sendTransactionOn: yup.date().required('Send On is required'),
  fundingIdentifier: yup.string().required('Workflows must be associated to a Funding'),
  workflow: yup.string().required('You must pick a workflow'),
  notes: yup.string().optional().nullable(),
});

const defaultTransaction: EditableTransaction = {
  id: 'Default-ID',
  isNew: true,
  mode: GridRowModes.Edit,
  fundingIdentifier: '',
  creditOrDebit: '',
  transactionId: '',
  transactionTypeId: '',
  transactionEventId: '',
  sourceAccountId: '',
  destinationAccountId: '',
  transactionAmount: 0,
  transactionDescription: '',
  transactionStatusId: '',
  transactionProviderId: '',
};

const defaultInitialValues: TransactionWorkflowModel = {
  workflow: '',
  fundingIdentifier: undefined,
  transactionId: '',
  transactionTypeId: '',
  transactionEventId: '',
  sourceAccountId: '',
  destinationAccountId: '',
  transactionAmount: 0,
  transactionDescription: '',
  transactionStatusId: '',
  transactionProviderId: '',
};

export const TRANSACTION_TYPE_ID_ACH_COMMISSION_PARTNER_CREDIT =
  'f7c93bb6-4332-49cd-98ab-e1d7c8ec4717'; // "ACH Commission ISO Credit - Standard"

export const TRANSACTION_TYPE_ID_ACH_MERCHANT_CREDIT_OVERPAYMENT_REFUND =
  'cce2f6c1-6b20-4335-a231-7139cccaac4b';

export const TRANSACTION_TYPE_ID_ACH_MERCHANT_CREDIT_REFUND =
  'efa14d7b-b485-49e2-b03f-27f8bbed33be';

function EditToolbar(props: any) {
  const { setRows, setRowModesModel, sendTransactionOn, fundingIdentifier } = props;

  const handleClick = useCallback(
    (creditOrDebit: string) => () => {
      const id = generateId();
      setRows((oldRows) => [
        ...oldRows,
        {
          ...defaultTransaction,
          id,
          creditOrDebit,
          sendTransactionOn,
          isNew: true,
          fundingIdentifier,
        },
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' },
      }));
    },
    [sendTransactionOn, fundingIdentifier, setRowModesModel, setRows]
  );

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick('credit')}>
        Add Credit
      </Button>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick('debit')}>
        Add Debit
      </Button>
    </GridToolbarContainer>
  );
}

const WorkflowSummary = React.memo(
  ({ totalCredits, totalDebits }: { totalCredits: number; totalDebits: number }) => (
    <Grid container spacing={1} my={1}>
      <Grid item xs={6} />

      <Grid item xs={2}>
        Debits: {formatCurrency(totalDebits)}
      </Grid>
      <Grid item xs={2}>
        Credits: {formatCurrency(totalCredits)}
      </Grid>
      <Grid item xs={2}>
        Total: {formatCurrency(totalCredits + totalDebits)}
      </Grid>
    </Grid>
  )
);

interface AutocompleteEditInputCellProps {
  params: GridRenderEditCellParams;
  options?: Account[];
  getOptionLabel?: (option: any) => string;
}

const workflows = [
  'One Time Transaction - Send Cash',
  'One Time Transaction - Receive Cash',
  'ISO Commission Payment',
  'Customer Refund',
  'Merchant Overpayment Credit',
];

const defaultEditorFromWorkflow = (workflow: string): EditableTransaction | null => {
  switch (workflow) {
    case 'One Time Transaction - Send Cash':
      return {
        ...defaultTransaction,
        transactionTypeId: TRANSACTION_TYPE_ACH,
        transactionEventId: TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT,
        creditOrDebit: 'credit',
      };
    case 'One Time Transaction - Receive Cash':
      return {
        ...defaultTransaction,
        transactionTypeId: TRANSACTION_TYPE_ACH,
        transactionEventId: TRANSACTION_EVENT_ID_SERVICING_RECEIVE_REMITTANCE,
        creditOrDebit: 'debit',
      };
    case 'ISO Commission Payment':
      return {
        ...defaultTransaction,
        transactionTypeId: TRANSACTION_TYPE_ID_ACH_COMMISSION_PARTNER_CREDIT,
        transactionEventId: TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT,
        creditOrDebit: 'credit',
      };
    case 'Customer Refund':
      return {
        ...defaultTransaction,
        transactionTypeId: TRANSACTION_TYPE_ID_ACH_MERCHANT_CREDIT_REFUND,
        transactionEventId: TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT,
        creditOrDebit: 'credit',
      };
    case 'Merchant Overpayment Credit':
      return {
        ...defaultTransaction,
        transactionTypeId: TRANSACTION_TYPE_ID_ACH_MERCHANT_CREDIT_OVERPAYMENT_REFUND,
        transactionEventId: TRANSACTION_EVENT_ID_SERVICING_SEND_CREDIT,
        creditOrDebit: 'credit',
      };
    default:
      return null;
  }
};

const TransactionWorkflowEditor = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { getConfirmation, ConfirmationModal } = useConfirm();
  const bankingService = useMemo(BankingService, []);
  const funderService = useMemo(FunderService, []);
  const { transactionTypes, transactionEvents } = useAccountingData();

  const [rows, setRows] = useState<GridRowsProp<EditableTransaction>>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [sourceAccounts, setSourceAccounts] = useState<ApiResponsePaged<Account[]>>();
  const [selectedSourceAccount, setSelectedSourceAccount] = useState<Account>();
  const [selectedDestinationAccount, setSelectedDestinationAccount] = useState<Account>();
  const [sourceAccountSearch, setSourceAccountSearch] = useState<AccountsSearch>({
    accountTypeId: FUNDER_ACCOUNT_TYPE_ID,
    pageNumber: 1,
    pageSize: 10,
  });
  const [destinationAccounts, setDestinationAccounts] = useState<ApiResponsePaged<Account[]>>();
  const [destinationAccountSearch, setDestinationAccountSearch] = useState<AccountsSearch>({
    pageNumber: 1,
    pageSize: 10,
  });
  const fundingService = useMemo(FundingService, []);
  const transactionService = useMemo(TransactionService, []);

  const totalCredits = useMemo(
    () =>
      rows
        .filter((transaction) => transaction.creditOrDebit === 'credit')
        .reduce((acc, curr) => acc + +curr.transactionAmount, 0),
    [rows]
  );

  const totalDebits = useMemo(
    () =>
      rows
        .filter((transaction) => transaction.creditOrDebit === 'debit')
        .reduce((acc, curr) => acc + +curr.transactionAmount, 0),
    [rows]
  );

  const initialValues = useMemo(
    () => ({
      ...defaultInitialValues,
      transactionId: searchParams.get('transactionId'),
      workflow: searchParams.get('workflow') ?? '',
      fundingId: searchParams.get('fundingId') ?? '',
      fundingIdentifier: searchParams.get('fundingIdentifier') ?? '',
      funding: {
        fundingId: searchParams.get('fundingId') ?? '',
        identifier: searchParams.get('fundingIdentifier') ?? '',
      },
    }),
    [searchParams]
  );

  const AutocompleteEditInputCell: React.FC<AutocompleteEditInputCellProps> = useCallback(
    (props: AutocompleteEditInputCellProps) => {
      const { params, options } = props;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const apiRef = useGridApiContext();
      const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: Account | null
      ) => {
        event.stopPropagation();
        apiRef.current.setEditCellValue({
          id: params.id,
          field: params.field,
          value: newValue?.accountHolderName,
        });
        const row = apiRef.current.getRow(params.id);
        if (params.field === 'sourceAccount') {
          setSelectedSourceAccount(newValue ?? undefined);
          row.sourceAccountId = newValue?.accountId ?? '';
        } else {
          setSelectedDestinationAccount(newValue ?? undefined);
          row.destinationAccountId = newValue?.accountId ?? '';
        }
      };

      return (
        <Autocomplete
          disablePortal
          value={
            params.field === 'sourceAccount' ? selectedSourceAccount : selectedDestinationAccount
          }
          id={
            params.field === 'sourceAccount'
              ? 'sourceAccountAutoComplete'
              : 'destinationAccountAutoComplete'
          }
          options={options ?? []}
          sx={{ width: '100%', pt: 2 }}
          getOptionLabel={(account) => `${account.accountHolderName}-${account.accountNumber}`}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={(event, value) => handleChange(event, value)}
          ListboxProps={{
            onScroll: (event: React.SyntheticEvent) => {
              if (params.field === 'sourceAccount') {
                if (!sourceAccounts?.hasNextPage) {
                  return;
                }

                const listboxNode = event.currentTarget;
                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                  setSourceAccountSearch((prevState) =>
                    removeEmpty({
                      ...prevState,
                      pageNumber: prevState.pageNumber + 1,
                    })
                  );
                }
              }
              if (!sourceAccounts?.hasNextPage) {
                return;
              }

              const listboxNode = event.currentTarget;
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                setDestinationAccountSearch((prevState) =>
                  removeEmpty({
                    ...prevState,
                    pageNumber: prevState.pageNumber + 1,
                  })
                );
              }
            },
          }}
        />
      );
    },
    [selectedDestinationAccount, selectedSourceAccount, sourceAccounts?.hasNextPage]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: workflowValidationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const {
    values,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = formik;

  const getFundingSearchResponse = useCallback(
    async ({
      offerIdentifier,
      fundingIdentifier,
      fundingId,
    }: {
      offerIdentifier?: string;
      fundingIdentifier?: string;
      fundingId?: string;
    }) => {
      const fundingSearchResponse = await fundingService.searchFunding({
        offerIdentifier,
        identifier: fundingIdentifier,
        pageNumber: 1,
        pageSize: 10,
      });
      if (fundingSearchResponse?.data?.length) {
        if (fundingSearchResponse.data.length === 1) {
          const [funding] = fundingSearchResponse.data;

          setFieldValue('fundingId', funding?.fundingId);
          setFieldValue('fundingIdentifier', funding?.identifier);
        }
      }
    },
    [fundingService, setFieldValue]
  );

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const transactionWorkflow: TransactionWorkflow = {
          workflow: formData.workflow,
          fundingId: formData.fundingId,
          fundingIdentifier: formData.fundingIdentifier,
          transactions: rows.map((transaction) => ({
            transactionId: '',
            transactionTypeId: transaction.transactionTypeId,
            transactionEventId: transaction.transactionEventId,
            fundingId: transaction.fundingId,
            sourceAccountId: transaction.sourceAccountId,
            destinationAccountId: transaction.destinationAccountId,
            transactionAmount: transaction.transactionAmount,
            transactionDescription: transaction.transactionDescription,
            originatingPartyName: transaction.originatingPartyName,
            transactionStatusId: TRANSACTION_STATUS_ID_READY_TO_SEND,
            sendTransactionOn: transaction.sendTransactionOn,
            transactionProviderId: transaction.transactionProviderId,
          })),
        };
        const upsertResponse = await transactionService.performWorkflow(transactionWorkflow);
        if (upsertResponse.success) {
          navigate(-1);
          enqueueSnackbar('Workflow Saved Successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(upsertResponse.message, { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, navigate, transactionService, rows]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [setRowModesModel, rowModesModel]
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      setRows(rows.filter((row) => row.id !== id));
    },
    [setRows, rows]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [setRowModesModel, rowModesModel, setRows, rows]
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel<EditableTransaction>) => {
      const updatedRow: EditableTransaction = {
        ...newRow,
        isNew: false,
      };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      setSelectedDestinationAccount(undefined);
      setSelectedSourceAccount(undefined);
      return updatedRow;
    },
    [setRows, rows]
  );

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    [setRowModesModel]
  );

  const columns: GridColDef<EditableTransaction>[] = useMemo(
    (): GridColDef<EditableTransaction>[] => [
      {
        field: 'transactionTypeId',
        headerName: 'Transaction Type',
        flex: 1,
        type: 'singleSelect',
        editable: true,
        valueOptions: [{ value: '', label: '' }].concat(
          transactionTypes.map((transactionType) => ({
            value: transactionType.transactionTypeId!,
            label: transactionType.transactionTypeName!,
          }))
        ),
      },
      {
        field: 'transactionEventId',
        headerName: 'Transaction Event',
        flex: 1,
        type: 'singleSelect',
        editable: true,
        valueOptions: [{ value: '', label: '' }].concat(
          transactionEvents.map((transactionEvent) => ({
            value: transactionEvent.transactionEventId!,
            label: transactionEvent.transactionEventName!,
          }))
        ),
      },
      {
        field: 'sourceAccount',
        headerName: 'Internal Account (Libertas)',
        flex: 1,
        editable: true,
        renderEditCell: (params) => (
          <AutocompleteEditInputCell params={params} options={sourceAccounts?.data} />
        ),
      },
      {
        field: 'destinationAccount',
        headerName: 'External Account',
        flex: 1,
        editable: true,
        renderEditCell: (params) => (
          <AutocompleteEditInputCell params={params} options={destinationAccounts?.data} />
        ),
      },
      {
        field: 'creditOrDebit',
        headerName: 'Direction',
        width: 125,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['credit', 'debit'],
      },
      {
        field: 'transactionAmount',
        headerName: 'Amount',
        type: 'number',
        width: 150,
        editable: true,
        renderCell: (cell) => formatCurrency(cell.row.transactionAmount),
      },
      {
        field: 'notes',
        headerName: 'Notes',
        flex: 1,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                key={'save'}
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                key={'cancel'}
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              key={'edit-transaction'}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              key={'delete-transaction'}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      transactionTypes,
      transactionEvents,
      AutocompleteEditInputCell,
      sourceAccounts?.data,
      destinationAccounts?.data,
      rowModesModel,
      handleEditClick,
      handleDeleteClick,
      handleSaveClick,
      handleCancelClick,
    ]
  );

  useEffect(() => {
    if (!initialValues.fundingIdentifier) {
      return;
    }

    getFundingSearchResponse({ fundingIdentifier: initialValues.fundingIdentifier });
  }, [initialValues.fundingIdentifier, getFundingSearchResponse]);

  useEffect(() => {
    // const transactionId = searchParams.get('transactionId') ?? '';
    const offerIdentifier = searchParams.get('offerIdentifier') ?? '';
    const fundingIdentifier = searchParams.get('fundingIdentifier') ?? '';
    const fundingId = searchParams.get('fundingId') ?? '';

    if (fundingId) {
      // Search by journalGroupId take precedence
      getFundingSearchResponse({ fundingId });
    } else if (fundingIdentifier) {
      getFundingSearchResponse({ fundingIdentifier });
    } else if (offerIdentifier) {
      getFundingSearchResponse({ offerIdentifier });
    }
  }, [searchParams, getFundingSearchResponse, setRows]);

  useEffect(() => {
    async function get() {
      const accountSearchResponse = await bankingService.searchAccounts(sourceAccountSearch);
      if (accountSearchResponse?.data) {
        const activeFunders: Account[] = [];
        for (const account of accountSearchResponse.data) {
          const funder = await funderService.searchFunders({
            funderId: account.modelId,
          });
          if (funder?.data?.length && funder.data[0].active) {
            activeFunders.push(account);
          }
        }
        setSourceAccounts((prevState) => ({
          ...accountSearchResponse,
          data: activeFunders,
        }));
      }
    }

    get();
  }, [bankingService, funderService, sourceAccountSearch]);

  useEffect(() => {
    async function get() {
      const accountSearchResponse = await bankingService.searchAccounts(destinationAccountSearch);

      if (accountSearchResponse?.data) {
        const responseData =
          accountSearchResponse.data?.filter(
            (account) => account.accountId !== values.sourceAccount?.accountId
          ) ?? [];

        setDestinationAccounts((prevState) => ({
          ...accountSearchResponse,
          data: responseData,
        }));
      }
    }

    get();
  }, [bankingService, values.sourceAccount?.accountId, destinationAccountSearch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Container maxWidth={false}>
            <PageTitle title="Transaction Workflow Editor" />
            <Grid container spacing={2} my={1}>
              <Grid item xs={12}>
                <SectionTitle title={'Summary Transaction Details'} />
              </Grid>
              <Grid item xs={1.5}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Send On Date"
                    format="MM/dd/yyyy"
                    value={values.sendTransactionOn}
                    onChange={(value) => {
                      setFieldValue('sendTransactionOn', value, true);
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        variant: 'standard',
                        id: 'sendTransactionOn',
                        name: 'sendTransactionOn',
                        onBlur: handleBlur,
                        InputLabelProps: { shrink: true },
                        error:
                          (touched.sendTransactionOn || isSubmitting) &&
                          Boolean(errors.sendTransactionOn),
                        helperText: touched.sendTransactionOn && errors.sendTransactionOn,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  required
                  id="fundingIdentifier"
                  name="fundingIdentifier"
                  label="Funding Identifier"
                  variant="standard"
                  value={values.fundingIdentifier}
                  onChange={handleChange}
                  error={touched.fundingIdentifier && Boolean(errors.fundingIdentifier)}
                  helperText={touched.fundingIdentifier && errors.fundingIdentifier}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-workflow-label" shrink={true} required={true}>
                    Workflow
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    name="workflow"
                    variant="standard"
                    id="select-workflow-label"
                    label="Workflow"
                    value={values.workflow}
                    // onChange={handleChange}
                    onChange={async (e: SelectChangeEvent) => {
                      if (rows.length) {
                        const responseConfirmation = await getConfirmation(
                          'Update Workflow',
                          `Are you sure you want to update the workflow? It will reset any current transaction rows.`
                        );

                        if (!responseConfirmation) {
                          return;
                        }
                      }

                      const defaultValues = defaultEditorFromWorkflow(e.target.value);
                      if (defaultValues) {
                        setFieldValue('transactionTypeId', defaultValues.transactionTypeId);
                        setFieldValue('transactionEventId', defaultValues.transactionEventId);
                        setRows([
                          {
                            ...defaultTransaction,
                            transactionTypeId: defaultValues.transactionTypeId,
                            transactionEventId: defaultValues.transactionEventId,
                            creditOrDebit: defaultValues.creditOrDebit,
                          },
                        ]);
                      }
                      setFieldValue('workflow', e.target.value);
                    }}
                    error={!!(errors.workflow && touched.workflow)}
                  >
                    {workflows.map((workflow: string) => (
                      <MenuItem key={workflow} value={workflow}>
                        {workflow}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.workflow && (touched.workflow || isSubmitting) && (
                  <FormHelperText error={true}>{errors.workflow}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="notes"
                  name="notes"
                  value={values.notes ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Notes"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    height: 'calc(100vh - 450px)',
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary',
                    },
                    '& .textPrimary': {
                      color: 'text.primary',
                    },
                  }}
                >
                  <SectionTitle title={'Transaction Items'} />
                  <DataGridPro
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: EditToolbar,
                      noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          No Transaction Details
                        </Stack>
                      ),
                    }}
                    slotProps={{
                      toolbar: {
                        setRows,
                        setRowModesModel,
                        sendTransactionOn: values.sendTransactionOn,
                        fundingIdentifier: values.fundingIdentifier,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={1}>
                <WorkflowSummary totalCredits={totalCredits} totalDebits={totalDebits} />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="right">
                <Button type="submit" variant="contained" size={'small'}>
                  Save
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  size={'small'}
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant={'text'}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
            <ConfirmationModal />
          </Container>
        </form>
      </FormikProvider>
    </LocalizationProvider>
  );
};

export default React.memo(TransactionWorkflowEditor);
