import useAuth from 'hooks/useAuth';
import AuthenticatedLayout from 'layouts/AuthenticatedLayout';
import UnauthenticatedLayout from 'layouts/UnAuthentictedLayout';

function App() {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated === false) {
    return <UnauthenticatedLayout />;
  } else {
    return <AuthenticatedLayout />;
  }
}

export default App;
