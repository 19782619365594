// React
import { useState, useMemo, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
// providers & contexts
import MuiThemeModeContext from 'contexts/MuiThemeModeContext';
import { AuthProvider } from 'contexts/AuthContextFMS';
import NotistackProvider from 'components/NotistackProvider';
import LocalCacheService from 'services/LocalCacheService';
// App styling
import { theme } from 'styling/theming';
import 'styling/Application.css';
// Global app error handler
import ErrorBoundary from 'components/ErrorBoundary';

// MUI licensing
import { MUI_X_PRO_LICENSE_KEY } from 'config';
import App from 'App';

LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);

const AppShell = () => {
  const [themeMode, setThemeMode] = useState('light');

  const lightMode = useMemo(() => theme('light'), []);
  const darkMode = useMemo(() => theme('dark'), []);

  const localCacheService = useMemo(() => LocalCacheService(), []);

  useEffect(() => {
    const savedMode = localCacheService.get('themeMode', 'light');
    setThemeMode(savedMode);
  }, [setThemeMode, localCacheService]);

  const colorMode = useMemo(
    () => ({
      toggleTheme: () => {
        const updatedThemeMode = themeMode === 'light' ? 'dark' : 'light';
        localCacheService.set('themeMode', updatedThemeMode);
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [localCacheService, setThemeMode, themeMode]
  );

  const router = createBrowserRouter([
    {
      path: '*',
      element: (
        <AuthProvider>
          <App />
        </AuthProvider>
      ),
    },
  ]);

  return (
    <ErrorBoundary>
      <NotistackProvider>
        <MuiThemeModeContext.Provider value={colorMode}>
          <ThemeProvider theme={themeMode === 'light' ? lightMode : darkMode}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </MuiThemeModeContext.Provider>
      </NotistackProvider>
    </ErrorBoundary>
  );
};

const containerAppShell = document.getElementById('root');
const appShellRoot = createRoot(containerAppShell as Element);
appShellRoot.render(<AppShell />);
