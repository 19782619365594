import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import FundingRequest from 'models/Funding/FundingRequest';
import FundingSearchRequest from 'models/Funding/FundingSearchRequest';
import Funding from 'models/Funding/Funding';
import FundingRemittanceSchedule from 'models/Funding/FundingRemittanceSchedule';
import FundingRemittanceScheduleSearch from 'models/Funding/FundingRemittanceScheduleSearch';
import InvestmentCompanySearch from 'models/Funding/InvestmentCompanySearch';
import InvestmentCompany from 'models/Funding/InvestmentCompany';
import fundingRemittanceStatusData from 'services/data/fundingRemittanceStatusData';
import ScheduleModificationRequest from 'models/Funding/ScheduleModificationRequest';
import ScheduleModificationResponse from 'models/Funding/ScheduleModificationResponse';
import SaveScheduleModificationRequest from 'models/Funding/SaveScheduleModificationRequest';
import InvestmentCompanyFunding from 'models/Funding/InvestmentCompanyFunding';
import InvestmentCompanyFundingSearch from 'models/Funding/InvestmentCompanyFundingSearch';
import AddRemittanceToScheduleRequest from 'models/Funding/AddRemittanceToScheduleRequest';
import RemoveRemittanceFromScheduleRequest from 'models/Funding/RemoveRemittanceFromScheduleRequest';
import ScheduleRebalanceRequest from 'models/Funding/ScheduleRebalanceRequest';
import FundingRenewalTool from 'models/Funding/FundingRenewalTool';
import BusinessDayFromDateRequest from 'models/Funding/BusinessDayFromDateRequest';
import FundingBalanceModificationSearch from 'models/Funding/FundingBalanceModificationSearch';
import FundingBalanceModification from 'models/Funding/FundingBalanceModification';
import FundingBalanceModificationUpsertRequest from 'models/Funding/FundingBalanceModificationUpsertRequest';
import FundingParticipation from 'models/Participation/FundingParticipation';
import CreateCreditToMerchantRequest from 'models/Funding/CreateCreditToMerchantRequest';
import Transaction from 'models/Transactions/Transaction';
import FundingStatus from 'models/Funding/FundingStatus';
import Attorneys from 'models/Funding/Attorneys';
import AttorneysSearch from 'models/Funding/AttorneysSearch';
import ScheduleMerchantAccountModificationRequest from 'models/Funding/ScheduleMerchantAccountModificationRequest';
import FundingBalanceModificationReason from 'models/Funding/FundingBalanceModificationReason';
import InvestmentCompanyFundingTransaction from 'models/Funding/InvestmentCompanyFundingTransaction';
import InvestmentCompanyTransactionSearch from 'models/Accounting/InvestmentCompanyTransactionSearch';
import Offer from 'models/Offers/Offer';
import { OfferStatuses } from 'services/OffersService';
import { TERMLOAN_PRODUCT_ID } from 'services/ApplicationsService';
import PostFundingStatus from 'models/Funding/PostFundingStatus';
import { FUNDING_STATUS_COMPLETED_IDS } from 'components/FundingManagement/config';
import ChangeStatus from 'models/Funding/ChangeStatus';
import BankruptcyChapter from 'models/Funding/BankruptcyChapter';

export const FUNDING_BALANCE_MODIFICATION_REASON_INCREASED_RETURNED_DEBIT_ATTEMPT_ID =
  'e4486cb1-c2bc-60d4-4dd1-6ebb1b27e72e';
export const FUNDING_BALANCE_MODIFICATION_REASON_DECREASE_RENEWAL_ID =
  '5ec9f0b9-63ec-a252-65de-240f759f2f14';
export const FUNDING_STATUS_ID_ON_TIME = '7674a742-62b1-4e6d-babd-39ee820509cc';

/** 
This service manages "Funding" and approved set of offers for a given application. 
Also manages getting the data for funded offers and viewing/editing the remittance schedule.
*/
const FundingService = () => {
  const getFundingRemittanceStatuses = () => fundingRemittanceStatusData;
  const getFundingRemittanceStatusById = (id?: string) =>
    fundingRemittanceStatusData.find((status) => status.fundingRemittanceStatusId === id);
  const inFinishedStatus = (fundingStatusId: string) =>
    FUNDING_STATUS_COMPLETED_IDS.includes(fundingStatusId);

  const fundingStatusPermitsAddingParticipants = ({
    offer,
    funding,
  }: {
    offer?: Offer;
    funding?: Funding;
  }) =>
    ((offer?.offerStatusId && offer.offerStatusId !== OfferStatuses.Funded) ||
      (offer?.productId && offer.productId !== TERMLOAN_PRODUCT_ID)) &&
    (!funding || funding.fundingStatusId === FUNDING_STATUS_ID_ON_TIME);

  const getFundingBalanceModificationReasons = async () => {
    const response = await fmsServices().get<any, ApiResponse<FundingBalanceModificationReason[]>>(
      '/funding/balancemodifications/reasons'
    );
    return response;
  };

  const createInitialFunding = async (fundingRequest: FundingRequest) => {
    const response = await fmsServices().post<FundingRequest, ApiResponse>(
      '/funding/create',
      fundingRequest
    );
    return response;
  };

  const upsertFunding = async (funding: Funding) => {
    const response = await fmsServices().post<Funding, ApiResponse>('/funding/upsert', funding);
    return response;
  };

  const changeFundingStatus = async (changeStatus: ChangeStatus) => {
    const response = await fmsServices().post<ChangeStatus, ApiResponse>(
      '/funding/change-status',
      changeStatus
    );
    return response;
  };

  const unfundFunding = async (funding: Funding) => {
    const response = await fmsServices().post<Funding, ApiResponse>('/funding/unfund', funding);
    return response;
  };

  const searchFunding = async (fundingSearch: FundingSearchRequest) => {
    const response = await fmsServices().post<{}, ApiResponsePaged<Funding[]>>(
      '/funding/search',
      fundingSearch
    );
    return response;
  };

  const searchFundingStatuses = async () => {
    const response = await fmsServices().get<{}, ApiResponsePaged<FundingStatus[]>>(
      '/funding/statuses'
    );
    return response;
  };

  const searchPostFundingStatuses = async () => {
    const response = await fmsServices().get<{}, ApiResponsePaged<PostFundingStatus[]>>(
      '/funding/post-statuses'
    );
    return response;
  };

  const searchBankruptcyChapters = async () => {
    const response = await fmsServices().get<{}, ApiResponsePaged<BankruptcyChapter[]>>(
      '/funding/bankruptcy-chapters'
    );
    return response;
  };

  const getCompanyFundings = async (companyId: string) => {
    const response = await fmsServices().get<any, ApiResponse<FundingRenewalTool[]>>(
      `/funding/company/${companyId}`
    );
    return response;
  };

  const getAllFundingRemittanceSchedule = async (fundingId: string) => {
    const response = await fmsServices().get<any, ApiResponse<FundingRemittanceSchedule[]>>(
      `/funding/remittance/schedule/${fundingId}`
    );
    return response;
  };

  const searchFundingRemittanceSchedule = async (
    searchRequest: FundingRemittanceScheduleSearch
  ) => {
    const response = await fmsServices().post<{}, ApiResponsePaged<FundingRemittanceSchedule[]>>(
      '/funding/remittance/schedule/search',
      searchRequest
    );
    return response;
  };

  const rebuildFundingRemittanceSchedule = async (rebuildRequest: ScheduleModificationRequest) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/rebuild',
      rebuildRequest
    );
    return response;
  };

  const addRemittanceToSchedule = async (scheduleModifications: AddRemittanceToScheduleRequest) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/rebuild/add',
      scheduleModifications
    );
    return response;
  };

  const removeRemittanceFromSchedule = async (
    scheduleModifications: RemoveRemittanceFromScheduleRequest
  ) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/rebuild/remove',
      scheduleModifications
    );
    return response;
  };

  const rebalanceRemittanceFromSchedule = async (
    scheduleModifications: ScheduleRebalanceRequest
  ) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/rebuild/rebalance',
      scheduleModifications
    );
    return response;
  };

  const saveFundingRemittanceScheduleModification = async (
    scheduleModifications: SaveScheduleModificationRequest
  ) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/rebuild/save',
      scheduleModifications
    );
    return response;
  };

  const updateFundingRemittanceScheduleMerchantAccount = async (
    rebuildRequest: ScheduleMerchantAccountModificationRequest
  ) => {
    const response = await fmsServices().post<{}, ApiResponse<ScheduleModificationResponse>>(
      '/funding/remittance/schedule/merchant/account/update',
      rebuildRequest
    );
    return response;
  };

  const searchInvestmentCompany = async (investmentCompanySearch: InvestmentCompanySearch) => {
    const response = await fmsServices().post<{}, ApiResponsePaged<InvestmentCompany[]>>(
      '/funding/investmentcompanies/search',
      investmentCompanySearch
    );
    return response;
  };

  const upsertInvestmentCompany = async (
    data: InvestmentCompany,
    mode: string
  ): Promise<ApiResponse> => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    const response = await fmsServices().post<InvestmentCompany, ApiResponse>(
      '/funding/investmentcompanies/upsert',
      data
    );
    return response;
  };

  const searchInvestmentCompanyFunding = async (
    investmentCompanyFundingSearch: InvestmentCompanyFundingSearch
  ) => {
    const response = await fmsServices().post<{}, ApiResponsePaged<InvestmentCompanyFunding[]>>(
      '/funding/investmentcompanyfunding/search',
      investmentCompanyFundingSearch
    );
    return response;
  };

  const removeInvestmentCompanyFunding = async (investmentCompanyFundingId: string) => {
    const response = await fmsServices().post<{}, ApiResponsePaged<InvestmentCompanyFunding[]>>(
      '/funding/investmentcompanyfunding/remove',
      {
        investmentCompanyFundingId,
      }
    );
    return response;
  };

  const upsertInvestmentCompanyFunding = async (
    data: InvestmentCompanyFunding,
    mode: string
  ): Promise<ApiResponse> => {
    if (mode === 'edit') {
      delete data.createdBy;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.updatedAt;
      delete data.deletedAt;
    }
    const response = await fmsServices().post<InvestmentCompanyFunding, ApiResponse>(
      '/funding/investmentcompanyfunding/upsert',
      data
    );
    return response;
  };

  const batchInsertInvestmentCompanyFunding = async (
    items: InvestmentCompanyFunding[],
    mode: string
  ): Promise<ApiResponse> => {
    const payload = { items };
    const response = await fmsServices().post<{ items: InvestmentCompanyFunding[] }, ApiResponse>(
      '/funding/investmentcompanyfunding/batch-insert',
      payload
    );
    return response;
  };

  const getBusinessDayBeforeDate = async (date: Date): Promise<ApiResponse<Date>> => {
    const response = await fmsServices().post<BusinessDayFromDateRequest, ApiResponse<Date>>(
      '/funding/businessdays/before',
      { startDate: date }
    );
    return response;
  };

  const searchBalanceModifications = async (
    searchParams: FundingBalanceModificationSearch
  ): Promise<ApiResponsePaged<FundingBalanceModification[]>> => {
    const response = await fmsServices().post<
      FundingBalanceModificationSearch,
      ApiResponsePaged<FundingBalanceModification[]>
    >('/funding/balancemodifications/search', searchParams);
    return response;
  };

  const upsertBalanceModification = async (
    upsertRequest: FundingBalanceModificationUpsertRequest
  ): Promise<ApiResponse<FundingBalanceModification>> => {
    const response = await fmsServices().post<
      FundingBalanceModificationUpsertRequest,
      ApiResponse<FundingBalanceModification>
    >('/funding/balancemodifications/upsert', upsertRequest);
    return response;
  };

  const reverseBalanceModification = async (
    fundingBalanceModificationId: string
  ): Promise<ApiResponse<FundingBalanceModification>> => {
    const response = await fmsServices().post<
      { fundingBalanceModificationId: string },
      ApiResponse<FundingBalanceModification>
    >('/funding/balancemodifications/reverse', { fundingBalanceModificationId });
    return response;
  };

  const cancelBalanceModification = async (
    fundingBalanceModificationId: string
  ): Promise<ApiResponse<FundingBalanceModification>> => {
    const response = await fmsServices().post<
      { fundingBalanceModificationId: string },
      ApiResponse<FundingBalanceModification>
    >('/funding/balancemodifications/cancel', { fundingBalanceModificationId });
    return response;
  };

  const getFundingParticipations = async (fundingId: string) => {
    const response = await fmsServices().get<any, ApiResponse<FundingParticipation[]>>(
      `/funding/participations/${fundingId}`
    );
    return response;
  };

  const getFundingParticipationsBulk = async (fundingIds: string[]) => {
    const response = await fmsServices().post<any, ApiResponse<FundingParticipation[]>>(
      `/funding/participations/bulk`,
      { fundingIds }
    );
    return response;
  };

  const parseInvestmentCompanyAllocation = async (file: File) => {
    const uploadForm = new FormData();
    uploadForm.set('file', file);
    const uploadDocumentResponse = await fmsServices().postFormData<any, ApiResponse>(
      '/funding/investmentcompanies/pledging/upload',
      uploadForm
    );
    return uploadDocumentResponse;
  };

  const createCreditToMerchant = async (
    request: CreateCreditToMerchantRequest
  ): Promise<ApiResponse<Transaction>> => {
    const response = await fmsServices().post<
      CreateCreditToMerchantRequest,
      ApiResponse<Transaction>
    >('/funding/credit/merchant', request);
    return response;
  };

  const cancelCreditToMerchant = async (
    transactionId: string
  ): Promise<ApiResponse<Transaction>> => {
    const response = await fmsServices().post<{ transactionId: string }, ApiResponse<Transaction>>(
      '/funding/credit/merchant/cancel',
      { transactionId }
    );
    return response;
  };

  const searchAttorneys = async (searchParams: AttorneysSearch) => {
    const response = await fmsServices().post<any, ApiResponsePaged<Attorneys[]>>(
      `/funding/attorneys/search`,
      searchParams
    );
    return response;
  };

  const updateAttorney = async (fundingId: string, attorneyId: string) => {
    const response = await fmsServices().post<any, ApiResponse<Funding>>(
      `/funding/attorneys/update`,
      {
        fundingId,
        attorneyId,
      }
    );
    return response;
  };

  const searchInvestmentCompanyFundingTransactions = async (
    searchOptions: InvestmentCompanyTransactionSearch
  ) => {
    const response = await fmsServices().post<
      InvestmentCompanyTransactionSearch,
      ApiResponsePaged<InvestmentCompanyFundingTransaction[]>
    >('/funding/investmentcompanytransactions/search', searchOptions);
    return response;
  };

  const getNextBusinessDay = async (dateToFindNextBusinessDay?: Date) => {
    const response = await fmsServices().post<
      { dateToFindNextBusinessDay?: Date },
      ApiResponse<Date>
    >('/funding/next-business-day', {
      dateToFindNextBusinessDay,
    });
    return response;
  };

  const getMinimumDisbursalDate = async (userTimeZone: string) => {
    const response = await fmsServices().get<null, ApiResponse<Date>>(
      `/funding/minimum-disbursal-date?userTimeZone=${userTimeZone}`
    );
    return response;
  };

  const checkCounterPartyExists = async (offerId: string): Promise<ApiResponse> => {
    const response = await fmsServices().post<{ offerId: string }, ApiResponse>(
      `/funding/check-counter-party-exists`,
      { offerId }
    );
    return response;
  };

  return {
    getFundingRemittanceStatuses,
    getFundingRemittanceStatusById,
    getFundingBalanceModificationReasons,
    createInitialFunding,
    upsertFunding,
    unfundFunding,
    searchFunding,
    searchFundingStatuses,
    searchPostFundingStatuses,
    getAllFundingRemittanceSchedule,
    searchFundingRemittanceSchedule,
    rebuildFundingRemittanceSchedule,
    addRemittanceToSchedule,
    removeRemittanceFromSchedule,
    rebalanceRemittanceFromSchedule,
    saveFundingRemittanceScheduleModification,
    updateFundingRemittanceScheduleMerchantAccount,
    searchInvestmentCompany,
    upsertInvestmentCompany,
    searchInvestmentCompanyFunding,
    upsertInvestmentCompanyFunding,
    batchInsertInvestmentCompanyFunding,
    removeInvestmentCompanyFunding,
    getCompanyFundings,
    getBusinessDayBeforeDate,
    searchBalanceModifications,
    upsertBalanceModification,
    reverseBalanceModification,
    cancelBalanceModification,
    getFundingParticipations,
    getFundingParticipationsBulk,
    parseInvestmentCompanyAllocation,
    createCreditToMerchant,
    cancelCreditToMerchant,
    searchAttorneys,
    updateAttorney,
    searchInvestmentCompanyFundingTransactions,
    fundingStatusPermitsAddingParticipants,
    getNextBusinessDay,
    getMinimumDisbursalDate,
    inFinishedStatus,
    changeFundingStatus,
    searchBankruptcyChapters,
    checkCounterPartyExists,
  };
};

export default FundingService;
