export const todayRange = () => {
  const firstDay = new Date();
  const lastDay = new Date();
  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

export const thisWeekRange = () => {
  const date = new Date();
  const day = date.getDay();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - day + (day === 0 ? -6 : 1)
  );
  const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 5);

  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

export const thisMonthRange = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

export const lastWeekRange = () => {
  const date = new Date();
  const day = date.getDay();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - day - (day === 0 ? -13 : 7)
  );
  const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + 5);

  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const nextWeekRange = () => {
  const date = new Date();
  const day = date.getDay() - 7;
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - day + (day === 0 ? -6 : 1)
  );
  const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 5);

  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

export const lastMonthRange = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};

export const ytdRange = () => {
  const firstDay = new Date(new Date().getFullYear(), 0, 1);
  const lastDay = new Date();

  return {
    fromDate: firstDay,
    toDate: lastDay,
  };
};
