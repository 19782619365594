import DiligenceCheck from 'models/Diligence/DiligenceCheck';
import { semanticColors } from 'styling/colors';
import { formatDate } from 'utils/date/formatDate';

export const getStatus = (check: DiligenceCheck) => {
  if (check.results && check.results.length > 0) {
    return check.results[0].status;
  }
  return 'not-started';
};

export const showRunBasedOnStatus = (status: string) => {
  if (status === 'default') return true;
  if (status === 'not-started') return true;
  if (status === 'error') return true;
  return false;
};

export const getOutcome = (check?: DiligenceCheck) => {
  if (check && check.results && check.results.length > 0) {
    return check.results[0].outcome;
  }
  return 'not-done';
};

export const getLastRunDate = (check: DiligenceCheck) => {
  if (check.results && check.results.length > 0) {
    return formatDate(check.results[0].updatedAt);
  }
  return '';
};

export const statusColorScheme = [
  { status: 'default', color: 'grey' },
  { status: 'not-started', color: 'grey' },
  { status: 'error', color: semanticColors.alert[600] },
  { status: 'in-progress', color: semanticColors.warn[600] },
  { status: 'complete', color: semanticColors.success[400] },
];

export const outcomeColorScheme = [
  { status: 'default', color: 'grey' },
  { status: 'rejected', color: semanticColors.alert[600] },
  { status: 'manual', color: semanticColors.warn[600] },
  { status: 'pass', color: semanticColors.success[400] },
];

export const getNotesAsArray = (check: DiligenceCheck) => {
  if (check.results && check.results.length > 0) {
    const notesString = check.results[0].notes?.replaceAll(
      /Fail:|Review:|Pass:|Context:/gi,
      (pattern) => `\n${pattern}`
    );
    const notes = notesString?.split('\n').filter((note) => note !== '');

    const notesArray =
      notes?.map((note) => {
        //bold the status
        const [status, ...noteBody] = note.split(':');
        const noteText = noteBody.join(':');
        return { status, noteText };
      }) ?? [];

    // Note: Put the notes that require user review at the top
    const failAndReviewNotes = notesArray.filter(
      (note) => note.noteText.startsWith('Fail') || note.noteText.startsWith('Review')
    );
    const passNotes = notesArray.filter(
      (note) => !(note.noteText.startsWith('Fail') || note.noteText.startsWith('Review'))
    );

    return failAndReviewNotes.concat(passNotes);
  }
  return [];
};
