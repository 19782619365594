export const getFirstCharacter = (username: string) => {
  const words = username.split('.');
  return username && `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
};

// eslint-disable-next-line no-unused-vars
export const getAvatarColor = () => {
  const bgColor: string[] = ['primary', 'secondary', 'success', 'error', 'info', 'warning'];
  const randomNumber = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
  return bgColor[randomNumber];
};
