export const FUNDING_REMITTANCE_STATUS_ID_SCHEDULED = '517a7994-1f30-4390-a2fd-4a2ffe6aa5ce';
export const FUNDING_REMITTANCE_STATUS_ID_INPROGRESS = '4b9b0296-b52e-42a0-b67e-ec7063f1b120';
export const FUNDING_REMITTANCE_STATUS_ID_PROCESSING = '4ebf87f3-61d3-46f1-8f43-04ec51c81b19';
export const FUNDING_REMITTANCE_STATUS_ID_COLLECTED = '5d4dc8a5-dc57-43b3-9c66-c0ad1ab0403a';
export const FUNDING_REMITTANCE_STATUS_ID_RETURNED = '4752b369-7fd2-4690-8ffe-bec4a93962ed';
export const FUNDING_REMITTANCE_STATUS_ID_CANCELLED = '75faa7ae-60b2-42ec-bec0-bd0713ecc6a0';
export const FUNDING_REMITTANCE_STATUS_ID_ERROR = 'd4d1af81-d280-4755-8847-1e07c9732e86';

const fundingRemittanceStatusData = [
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_SCHEDULED,
    fundingRemittanceStatusName: 'Scheduled',
    fundingRemittanceStatusDescription: 'Remittance is Scheduled to occur in the future',
    displayOrder: 1,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_INPROGRESS,
    fundingRemittanceStatusName: 'In-Progress',
    fundingRemittanceStatusDescription: 'Remittance collection is in progress',
    displayOrder: 2,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_PROCESSING,
    fundingRemittanceStatusName: 'Processing',
    fundingRemittanceStatusDescription: 'Remittance collection is waiting confirmation',
    displayOrder: 3,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_COLLECTED,
    fundingRemittanceStatusName: 'Collected',
    fundingRemittanceStatusDescription: 'Remittance Collected Successfully',
    displayOrder: 4,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_RETURNED,
    fundingRemittanceStatusName: 'Returned',
    fundingRemittanceStatusDescription: 'Remittance Collection Returned',
    displayOrder: 5,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_CANCELLED,
    fundingRemittanceStatusName: 'Cancelled',
    fundingRemittanceStatusDescription: 'Remittance Collection Cancelled',
    displayOrder: 6,
  },
  {
    fundingRemittanceStatusId: FUNDING_REMITTANCE_STATUS_ID_ERROR,
    fundingRemittanceStatusName: 'Error Occurred',
    fundingRemittanceStatusDescription: 'Remittance NOT Collected Successfully',
    displayOrder: 7,
  },
];

export default fundingRemittanceStatusData;
