import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Link,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowForward,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  NotificationsNone as NotificationsNoneIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { appBrand, appGrey } from 'styling/colors';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { getFirstCharacter } from 'utils/getFirstCharacter';
import useAuth from 'hooks/useAuth';
import timeAgo from 'utils/date/timeAgo';
//services
import NotificationService from 'services/NotificationService';
import BroadcastNotificationService from 'services/BroadcastNotificationService';
//models
import Notification from 'models/Notification/Notification';
import BroadcastNotificationEvent from 'models/Shared/BroadcastNotificationEvent';
import { MoneyThumbEventEnum } from 'components/BankCalc/config';
import { ContractEventEnum } from 'components/ContractManagement/config';
import { DocumentEventEnum } from 'components/Documents/config';
import { styled } from '@mui/material/styles';

const BoxResults = styled(Box)(({ theme }) => ({
  boxShadow: '0px 2px 2px 0px #00000029',
  maxHeight: '600px',
  width: '600px',
  position: 'absolute',
  right: 0,
  top: '70px',
  opacity: '1',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '6px',
  border: `1px solid ${appGrey[200]}`,
  overflowY: 'auto',
  scale: '1 0',
  transition: 'scale 200ms ease-out',
  transformOrigin: 'top',
  '&.active': {
    scale: '1 1',
    transition: 'scale 300ms ease-out',
  },
}));

const BoxItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '12px',

  '& .result-title': {
    fontSize: '16px',
    color: theme.palette.mode === 'light' ? '#fff' : '#000',
    fontWeight: 700,
    width: '100%',
    marginBottom: '6px',
  },
  '& .status': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
  },
  '& .subtitle': {
    fontWeight: 700,
    fontSize: '12px',
    margin: 0,
    color: theme.palette.mode === 'light' ? '#fff' : '#000',
  },
  '& .body': {
    fontSize: '12px',
  },
}));

interface SearchPagination {
  pageSize: number;
  pageNumber: number;
  totalResults: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const PAGE_SIZE = 5;
const paginationInitialValues = {
  pageSize: PAGE_SIZE,
  pageNumber: 1,
  totalResults: 0,
  totalPages: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const AppNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [searchResults, setSearchResults] = useState<Notification[]>([]);
  const [pagination, setPagination] = useState<SearchPagination>(paginationInitialValues);
  const [openResultsContainer, setOpenResultsContainer] = useState<boolean>(false);
  const [hasResults, setHasResults] = useState<boolean>(true);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0);
  const navigate = useNavigate();

  const notificationService = useMemo(() => NotificationService(), []);
  const broadcastNotificationService = useMemo(() => BroadcastNotificationService(), []);

  const theme = useTheme();

  const styles: Record<string, SxProps> = {
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      left: 0,
      justifyContent: 'space-between',
      padding: '16px',
      backgroundColor: theme.palette.background.paper,
      zIndex: 9,
      '& .title': {
        fontSize: '16px',
        color: '#000',
      },
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    close: {
      minWidth: 0,
      padding: 0,
      marginRight: '10px',
      '& .MuiSvgIcon-root': {
        fontSize: '26px',
      },
    },
    totalResults: {
      backgroundColor: appBrand[800],
      padding: '0px 5px',
      borderRadius: '50%',
      marginLeft: '5px',
      fontSize: '12px',
      color: '#fff',
      '&.two-digits': {
        padding: '2px 5px',
      },
      '&.three-digits': {
        padding: '5px',
      },
    },
    resultItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `1px solid ${appGrey[200]}`,
      borderRadius: '6px',
      paddingLeft: '4px',
      boxShadow: '0px 2px 4px 0px #00000033',
      padding: '12px',
      marginRight: '12px',
      marginLeft: '12px',
      marginBottom: '12px',
    },
    link: {
      display: 'inline-flex',
      '& .MuiSvgIcon-root': {
        color: appBrand[800],
      },
    },
    footer: {
      display: 'flex',
      position: 'sticky',
      bottom: 0,
      left: 0,
      alignItems: 'center',
      opacity: 1,
      justifyContent: 'end',
      width: '100%',
      padding: '12px',
      color: appGrey[600],
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      display: 'flex',
      '& .MuiSvgIcon-root': {
        fontSize: '26px',
      },
    },
    noResults: {
      margin: '20px auto 10px',
      textAlign: 'center',
      color: appGrey[600],
    },
    notificationIcon: {
      borderRadius: '50px',
      cursor: 'pointer',
    },
    markAllNotificationsAsReadButton: {
      marginRight: 1,
    },
  };
  const showingNow = useMemo(() => {
    const start = pagination.pageNumber * PAGE_SIZE - PAGE_SIZE + 1;
    const end =
      pagination.pageNumber === pagination.totalPages
        ? pagination.totalResults
        : pagination.pageNumber * PAGE_SIZE;

    return `Showing ${start}-${end}`;
  }, [pagination.pageNumber, pagination.totalPages, pagination.totalResults]);

  const searchNotifications = useCallback(
    async (pageNumber: number = pagination.pageNumber) => {
      const searchResponse = await notificationService.searchNotifications({
        userId: user.id,
        pageSize: pagination.pageSize,
        pageNumber: pageNumber,
      });

      const searchUnreadNotificationsResponse = await notificationService.searchNotifications({
        userId: user.id,
        unread: true,
        pageSize: 1,
        pageNumber: 1,
      });

      if (searchUnreadNotificationsResponse.success) {
        setUnreadNotificationsCount(searchUnreadNotificationsResponse.total);
      }

      if (searchResponse.success) {
        setPagination((prev) => ({
          ...prev,
          totalResults: searchResponse.total,
          totalPages: searchResponse.totalPages,
          hasNextPage: searchResponse.hasNextPage,
          hasPreviousPage: searchResponse.hasPreviousPage,
        }));

        setSearchResults(searchResponse.data || []);
        if (searchResponse?.data?.length === 0) {
          setHasResults(false);
          return;
        }
        setHasResults(true);
      }
    },
    [notificationService, pagination.pageNumber, pagination.pageSize, user.id]
  );

  const markNotificationAsRead = useCallback(
    async (notification: Notification) => {
      try {
        await notificationService.upsertNotification({
          notificationId: notification.notificationId,
          notificationType: notification.notificationType,
          userId: notification.userId,
          readAt: new Date(),
          entityId: notification.entityId,
          entityType: notification.entityType,
          message: notification.message,
        });
      } catch (error: any) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    },
    [enqueueSnackbar, notificationService]
  );

  const closeNotificationPopup = useCallback(() => {
    setOpenResultsContainer(false);
    setPagination({ ...pagination, pageNumber: 1 });
  }, [pagination]);

  const toggleNotificationPopup = useCallback(() => {
    setOpenResultsContainer(!openResultsContainer);
    setPagination({ ...pagination, pageNumber: 1 });
  }, [openResultsContainer, pagination]);

  const numberOfDigits = (resultsLength: number): string => {
    if (resultsLength > 99) {
      return 'three-digts';
    }
    if (resultsLength > 9) {
      return 'two-digits';
    }
    return '';
  };

  const nextPage = useCallback(() => {
    if (pagination.hasNextPage) {
      setPagination({ ...pagination, pageNumber: pagination.pageNumber + 1 });
    }
  }, [pagination]);

  const previousPage = useCallback(() => {
    const hasPreviousPage = pagination.hasPreviousPage && pagination.pageNumber - 1 > 0;

    if (hasPreviousPage) {
      setPagination({ ...pagination, pageNumber: pagination.pageNumber - 1 });
    }
  }, [pagination]);

  const markAllNotificationsAsRead = useCallback(async () => {
    try {
      await notificationService.markAllAsRead();
      await searchNotifications();
    } catch (error: any) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, notificationService, searchNotifications]);

  const removeNotification = useCallback(
    async (notificationId: string) => {
      await notificationService.removeNotification(notificationId);

      const nextState = searchResults.filter(
        (notification) => notification.notificationId !== notificationId
      );

      if (!nextState.length) {
        previousPage();
      }
    },
    [notificationService, previousPage, searchResults]
  );

  const handleArrowClick = useCallback(
    async (notification: Notification) => {
      await markNotificationAsRead(notification);

      if (notification.notificationType === MoneyThumbEventEnum.BankStatementUploaded) {
        return navigate(`/${notification.entityType}/bank-calculator/${notification.entityId}`);
      }

      if (
        [ContractEventEnum.ContractFullySigned, ContractEventEnum.ContractMerchantSigned].includes(
          notification.notificationType as ContractEventEnum
        )
      ) {
        return navigate(`/${notification.entityType}/contracts/${notification.entityId}`);
      }

      if (
        [
          DocumentEventEnum.DocumentBankVerificationUploaded,
          DocumentEventEnum.DocumentTaxGuardReportUploaded,
        ].includes(notification.notificationType as DocumentEventEnum)
      ) {
        return navigate(`/${notification.entityType}/stipulations/${notification.entityId}`);
      }

      navigate(`/${notification.entityType}/summary/${notification.entityId}`);
    },
    [markNotificationAsRead, navigate]
  );

  useEffect(() => {
    searchNotifications(pagination.pageNumber);
  }, [pagination.pageNumber, searchNotifications]);

  useEffect(() => {
    const events = [
      `users.${user.id}.notifications`,
      `users.${user.id}.notifications.NotificationUpserted`,
      `users.${user.id}.notifications.NotificationRemoved`,
    ];

    function handleApplicationEvents(e: BroadcastNotificationEvent) {
      searchNotifications();
    }

    broadcastNotificationService.subscribeMany(events, handleApplicationEvents);

    return () => {
      broadcastNotificationService.unsubscribeMany(events, handleApplicationEvents);
    };
  }, [broadcastNotificationService, pagination.pageNumber, searchNotifications, user.id]);

  return (
    <ClickAwayListener onClickAway={closeNotificationPopup}>
      <Box sx={styles.container}>
        <Badge
          badgeContent={unreadNotificationsCount}
          color="error"
          sx={styles.notificationIcon}
          onClick={() => toggleNotificationPopup()}
        >
          <NotificationsNoneIcon />
        </Badge>

        <BoxResults className={openResultsContainer ? 'active' : ''}>
          <Box sx={styles.header}>
            <Box>
              <Button onClick={toggleNotificationPopup} sx={styles.close}>
                <Close />
              </Button>
              <Typography variant="h2" className="title">
                Notifications
              </Typography>
            </Box>
            <Box>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={markAllNotificationsAsRead}
                sx={styles.markAllNotificationsAsReadButton}
                disabled={!unreadNotificationsCount}
              >
                <Typography variant="body2">Mark all as read</Typography>
              </Button>
              {hasResults && (
                <Box>
                  <Typography variant="body2">{showingNow} of</Typography>
                  <Box sx={styles.totalResults} className={numberOfDigits(searchResults.length)}>
                    {pagination.totalResults > 99 ? '99+' : pagination.totalResults}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {hasResults ? (
            searchResults.map((result) => (
              <Box
                key={result.notificationId}
                sx={{
                  ...styles.resultItem,
                  borderColor: !result.readAt ? appBrand[800] : undefined,
                }}
              >
                <Button
                  onClick={() => removeNotification(result.notificationId!)}
                  sx={styles.close}
                >
                  <Close />
                </Button>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs="auto">
                    <Avatar sx={{ height: 24, width: 24 }}>
                      {getFirstCharacter(result.createdByName || '')}
                    </Avatar>
                  </Grid>
                  <Grid container item flexDirection="column" wrap="nowrap" flex={1}>
                    <Grid item>
                      <BoxItemContent>
                        <Typography variant="h2" className="result-title">
                          {result.createdByName}
                        </Typography>
                      </BoxItemContent>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className="body"
                        dangerouslySetInnerHTML={{
                          __html: result?.message,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className="body">
                        {timeAgo(result?.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Link onClick={() => handleArrowClick(result)} sx={styles.link}>
                  <ArrowForward />
                </Link>
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={styles.noResults}>
              No results found
            </Typography>
          )}
          {(pagination.hasPreviousPage || pagination.hasNextPage) && (
            <Box sx={styles.footer}>
              <Typography variant="body2">
                Page {pagination.pageNumber} of {pagination.totalPages}
              </Typography>
              <Box sx={styles.pagination}>
                <Button onClick={previousPage} disabled={!pagination.hasPreviousPage}>
                  <KeyboardArrowLeft />
                </Button>
                <Button onClick={nextPage} disabled={!pagination.hasNextPage}>
                  <KeyboardArrowRight />
                </Button>
              </Box>
            </Box>
          )}
        </BoxResults>
      </Box>
    </ClickAwayListener>
  );
};

export default AppNotification;
