import Big from 'big.js';
import OfferPayoffAccount from 'models/Offers/OfferPayoffAccount';

export const NUMBER_OF_BUSINESS_DAYS_PER_MONTH = 21;

const calculateRepaymentAmount = (requestedAmount: number, fee: number, upsell: number): number =>
  Big(requestedAmount)
    .times(upsell)
    .plus(Big(requestedAmount).times(fee))
    .plus(requestedAmount)
    .toNumber();

const calculateTotalRepay = (payoffs: OfferPayoffAccount[]): number =>
  payoffs.reduce((amount, payoff) => Big(amount).plus(payoff.amount), 0);

const calculateNetAmountToMerchant = (
  purchasePrice: number,
  fee: number,
  totalRepays: number
): number => Big(purchasePrice).minus(fee).minus(totalRepays).toNumber();

const calculatePurchaseAmount = (fundedAmount: number, factor: number): number =>
  Big(fundedAmount).times(factor).toNumber();

const maths = {
  calculateRepaymentAmount,
  calculateTotalRepay,
  calculateNetAmountToMerchant,
  calculatePurchaseAmount,
};

export default maths;
