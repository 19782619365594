import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
// services
import BankingService from 'services/BankingService';
import FundingService from 'services/FundingService';
import JournalService from 'services/JournalService';
import AccountingService from 'services/AccountingService';
import TransactionService from 'services/TransactionSevice';
import ExpectedPaymentsService from 'services/ExpectedPaymentsService';
// models
import AccountingContextType from 'models/Accounting/AccountingContextType';
import AccountingDashboardMetrics, {
  defaultAccountingDashboardMetrics,
} from 'models/Accounting/AccountingDashboardMetrics';
import InvestmentCompany from 'models/Funding/InvestmentCompany';
import InvestmentCompanySearch from 'models/Funding/InvestmentCompanySearch';
import JournalType from 'models/Accounting/JournalType';
import JournalActionGroup from 'models/Accounting/JournalActionGroup';
import AccountingDashboardMetricsSearch from 'models/Accounting/AccountingDashboardMetricsSearch';
import TransactionType from 'models/Transactions/TransactionType';
import TransactionEvent from 'models/Transactions/TransactionEvent';
import ExpectedPaymentReason from 'models/ExpectedPayment/ExpectedPaymentReason';
// utils
import { todayRange } from 'utils/date/dateRangeUtils';

export const AccountingContext = React.createContext<AccountingContextType | null>(null);

export const AccountingProvider = ({ children }: { children: ReactNode }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [accountingData, setAccountingData] = useState<any>({});

  const [dashboardMetrics, setDashboardMetrics] = useState<AccountingDashboardMetrics>(
    defaultAccountingDashboardMetrics
  );
  const [investmentCompanyData, setInvestmentCompanyData] = useState<InvestmentCompany[]>([]);
  const [journalTypes, setJournalTypes] = useState<JournalType[]>([]);
  const [journalActionGroups, setJournalActionGroups] = useState<JournalActionGroup[]>([]);

  const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([]);
  const [transactionEvents, setTransactionEvents] = useState<TransactionEvent[]>([]);
  const [expectedPaymentReasons, setExpectedPaymentReasons] = useState<ExpectedPaymentReason[]>([]);

  const journalService = useMemo(JournalService, []);
  const fundingService = useMemo(FundingService, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const bankingService = useMemo(BankingService, []);
  const accountingService = useMemo(() => AccountingService(), []);
  const transactionService = useMemo(TransactionService, []);
  const expectedPaymentsService = useMemo(ExpectedPaymentsService, []);

  const getAccountingData = useCallback(async () => {
    const updatedAccountingData = {};
    setAccountingData(updatedAccountingData);
    return {
      updatedAccountingData,
    };
  }, [setAccountingData]);

  const getDashboardMetrics = useCallback(
    async (search: AccountingDashboardMetricsSearch) => {
      accountingService.getDashboardMetrics(search).then((result) => {
        if (result.success && result.data) {
          setDashboardMetrics(result.data);
        }
      });
    },
    [setDashboardMetrics, accountingService]
  );

  const getInvestmentCompanyData = useCallback(async () => {
    const response = await fundingService.searchInvestmentCompany({} as InvestmentCompanySearch);
    setInvestmentCompanyData(response.data || []);
  }, [fundingService]);

  const getJournalTypes = useCallback(async () => {
    // Note: Journal Types are static and only need to be loaded once
    if (journalTypes.length > 0) return;

    const response = await journalService.getJournalTypes();
    if (response.data) {
      setJournalTypes(response.data);
    }
  }, [setJournalTypes, journalService, journalTypes]);

  const getJournalActionGroups = useCallback(async () => {
    // Note: Journal Action Groups are static and only need to be loaded once
    if (journalActionGroups.length > 0) return;

    const response = await journalService.getJournalActionGroups();
    if (response.data) {
      setJournalActionGroups(response.data);
    }
  }, [setJournalActionGroups, journalService, journalActionGroups]);

  const getTransactionTypes = useCallback(async () => {
    // Note: Journal Types are static and only need to be loaded once
    if (transactionTypes.length > 0) return;

    const response = await transactionService.getTransactionTypes();
    if (response.data) {
      setTransactionTypes(response.data);
    }
  }, [setTransactionTypes, transactionService, transactionTypes]);

  const getTransactionEvents = useCallback(async () => {
    // Note: Journal Types are static and only need to be loaded once
    if (transactionEvents.length > 0) return;

    const response = await transactionService.getTransactionEvents();
    if (response.data) {
      setTransactionEvents(response.data);
    }
  }, [setTransactionEvents, transactionService, transactionEvents]);

  const getExpectedPaymentReasons = useCallback(async () => {
    // Note: Expected Payment Reasons are static and only need to be loaded once
    if (expectedPaymentReasons.length > 0) return;

    const response = await expectedPaymentsService.searchReasons();
    if (response.data) {
      setExpectedPaymentReasons(response.data ?? []);
    }
  }, [setExpectedPaymentReasons, expectedPaymentsService, expectedPaymentReasons]);

  const reloadAccountingData = useCallback(() => {
    getAccountingData();
  }, [getAccountingData]);

  useEffect(() => {
    getAccountingData();
  }, [getAccountingData]);

  const reloadDashboardMetrics = useCallback(
    (search: AccountingDashboardMetricsSearch) => getDashboardMetrics(search),
    [getDashboardMetrics]
  );

  useEffect(() => {
    getInvestmentCompanyData();
  }, [getInvestmentCompanyData]);

  useEffect(() => {
    getJournalTypes();
  }, [getJournalTypes]);

  useEffect(() => {
    getJournalActionGroups();
  }, [getJournalActionGroups]);

  useEffect(() => {
    getDashboardMetrics(todayRange());
  }, [getDashboardMetrics]);

  useEffect(() => {
    getTransactionTypes();
  }, [getTransactionTypes]);

  useEffect(() => {
    getTransactionEvents();
  }, [getTransactionEvents]);

  useEffect(() => {
    getExpectedPaymentReasons();
  }, [getExpectedPaymentReasons]);

  const contextValues = useMemo(
    () => ({
      accountingData,
      dashboardMetrics,
      reloadDashboardMetrics,
      journalTypes,
      journalActionGroups,
      reloadAccountingData,
      investmentCompanyData,
      getJournalTypes,
      getJournalActionGroups,
      transactionTypes,
      transactionEvents,
      expectedPaymentReasons,
    }),
    [
      accountingData,
      dashboardMetrics,
      reloadDashboardMetrics,
      journalTypes,
      journalActionGroups,
      reloadAccountingData,
      investmentCompanyData,
      getJournalTypes,
      getJournalActionGroups,
      transactionTypes,
      transactionEvents,
      expectedPaymentReasons,
    ]
  );

  return <AccountingContext.Provider value={contextValues}>{children}</AccountingContext.Provider>;
};
