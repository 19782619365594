import { formatDistance } from 'date-fns';

/** Formats given date into display format that is a string that
 * is a display of "how long ago" the given date occured.
 * Example: "1 day ago" or "20 minutes ago"
 */
export const formatDateDistance = (date: string | number | Date | undefined): string => {
  if (date) {
    return `${formatDistance(new Date(), new Date(date))} ago`;
  }
  return '';
};

export default formatDateDistance;
