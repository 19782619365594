import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { useLocation } from 'react-router';
import { useEffect } from 'react';

let awsRUM: AwsRum;

const FMS_APP_RUM_GUEST_ROLE_ARN = process.env.REACT_APP_FMS_APP_RUM_GUEST_ROLE_ARN || '';
const FMS_APP_RUM_IDENTITY_POOL_ID = process.env.REACT_APP_FMS_APP_RUM_IDENTITY_POOL_ID || '';
const FMS_APP_RUM_ENDPOINT =
  process.env.REACT_APP_FMS_APP_RUM_ENDPOINT || 'https://dataplane.rum.us-east-1.amazonaws.com';
const FMS_APP_RUM_APPLICATION_ID = process.env.REACT_APP_FMS_APP_RUM_APPLICATION_ID || '';

// Note: This is currently an experiment for RUM in UAT. We will observe and if we're getting
// useful information we'll full bake into IaC and CI/CD.
export const GetRUM = () => {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: FMS_APP_RUM_GUEST_ROLE_ARN,
    identityPoolId: FMS_APP_RUM_IDENTITY_POOL_ID,
    endpoint: FMS_APP_RUM_ENDPOINT,
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: false,
  };

  // Note: if the environment doesn't have RUM config just return null
  if (!FMS_APP_RUM_APPLICATION_ID) return null;
  if (awsRUM) return awsRUM;

  try {
    const APPLICATION_ID: string = FMS_APP_RUM_APPLICATION_ID;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    awsRUM = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    return awsRUM;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const RecordPageView = () => {
  let location = useLocation();
  useEffect(() => {
    if (!awsRUM) GetRUM();
    if (awsRUM) {
      awsRUM.recordPageView(location.pathname);
    }
  }, [location]);
};
