import Big from 'big.js';
// services
import fmsServices from 'services/fmsServices';
// models
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ApiResponse from 'models/API/ApiResponse';
import OffersGenerationRequest from 'models/Offers/OffersGenerationRequest';
import Offer from 'models/Offers/Offer';
import Application from 'models/Application/Application';
import OffersSearch from 'models/Offers/OffersSearch';
import OffersUpsertRequest from 'models/Offers/OffersUpsertRequest';
import PrepaysUpsertRequest from 'models/Offers/PrepaysUpsertRequest';
import OfferResponsesSearch from 'models/Offers/OffersResponseSearch';
import OfferResponse from 'models/Offers/OfferResponse';
import OfferPayoffAccount from 'models/Offers/OfferPayoffAccount';
import BankingService from 'services/BankingService';
import OfferPrepay from 'models/Offers/OfferPrepay';
import OfferStatusResponseUpdateRequest from 'models/Offers/OfferStatusResponseUpdateRequest';
import OfferRemittanceSchedule from 'models/Offers/OfferRemittanceSchedule';
import Account from 'models/Banking/Account';
import OfferExceptionSearch from 'models/Offers/OfferExceptionSearch';
import OfferException from 'models/Offers/OfferException';
import OfferExceptionReasonSearch from 'models/Offers/OfferExceptionReasonSearch';
import OfferExceptionReason from 'models/Offers/OfferExceptionReason';
import OfferExceptionsBulkUpsertRequest from 'models/Offers/OfferExceptionsBulkUpsertRequest';
import VariablePayment from 'models/Offers/VariablePayment';
import GetVariablePaymentDateRangeResponse from 'models/Offers/GetVariablePaymentDateRangeResponse';
import GetVariablePaymentDateRangeRequest from 'models/Offers/GetVariablePaymentDateRangeRequest';
import OffersSearchParticipationManager from 'models/Offers/OffersSearchParticipationManager';
import OfferStatus from 'models/Offers/OfferStatus';
import OfferApprovalNoteUpsert from 'models/Offers/OfferApprovalNoteUpsert';
import OfferPreApprovalNoteUpsert from 'models/Offers/OfferPreApprovalNoteUpsert';
import RemittanceFrequency from 'models/Offers/RemittanceFrequency';
import DiligenceCreditScoring from 'models/Diligence/DiligenceCreditScoring';
// utils
import { generateId } from 'utils/generateId';
import applicationMath from 'utils/math/applicationMath';

const DEFAULT_POSITION = 1;
export const DEFAULT_OFFER_RBF_TERM = 12;
export const DEFAULT_OFFER_TERMLOAN_TERM = 24;
export const DEFAULT_TERMLOAN_START_TERM = 12;
const DAILY_CASH = 15000;
const REPAYMENT_AMOUNT = 0.0;
const FUNDED_AMOUNT = 200000;

// Enum with the possible status of an offer and their ids
export enum OfferStatuses {
  Underwriting = '76653482-adb1-4bc4-b8bf-1f2c75a141ca',
  Preapproval = '9ba22c6a-5e06-4f8e-aacc-cbe1206e5015',
  Approved = '324ff9b9-14e2-4429-8596-8c4c85df92e1',
  Rejected = '220ffb61-1367-47aa-9ed1-ad43c6919216',
  Funded = '440a71d2-abbb-4897-b602-26ca150b00b6',
}

export enum FundingParticipantStatuses {
  RequiresParticipationSetup = 'Requires Participation Setup',
  RequiresSignatures = 'Requires Signatures',
  AwaitsFunding = 'Awaits Funding',
  AwaitsParticipantFunds = 'Awaits Participant Funds',
  ActiveParticipationFunding = 'Active Participation Funding',
  CompletedParticipationFunding = 'Completed Participation Funding',
}

/**
This service provides data for "Offers" and manages the creation and retrieval
of offers for an application.
*/
const OffersService = () => {
  const bankingService = BankingService();

  const initNewOfferRequest = (productId: string) => {
    const offer: OffersGenerationRequest = {
      applicationId: '',
      productId: productId,
      creditTierId: '',
      funderId: '',
      position: DEFAULT_POSITION,
      dailyCashAvailable: DAILY_CASH,
      repayAmount: REPAYMENT_AMOUNT,
      commission: 0.0,
      fee: 0.0,
      buyRateDelta: 0.0,
      fundedAmount: FUNDED_AMOUNT,
      rateId: '',
      term: 0,
    };
    return offer;
  };
  const initNewOfferRequestFromApplication = (
    application: Application,
    productId: string,
    fee: number,
    upsell: number,
    maxTerm: number,
    rateId?: string,
    fundedAmount?: number,
    creditScoring?: DiligenceCreditScoring
  ) => {
    const creditTierId = application.adjustedCreditTierId ?? application.creditTierId;

    const dailyCashAvailable = creditScoring?.dailyCashAvailable
      ? creditScoring.dailyCashAvailable
      : application.dailyCashAvailable;
    const offerReq: OffersGenerationRequest = {
      applicationId: application.applicationId ?? '',
      productId: productId,
      creditTierId: creditTierId ?? '',
      funderId: application.funder?.funderId ?? '',
      position: application.position ? application.position : 1,
      dailyCashAvailable: dailyCashAvailable ?? 0,
      repayAmount: applicationMath.calculateRepaymentAmount(
        application.requestedAmount,
        fee,
        upsell
      ),
      commission: 0.0,
      fee: fee,
      buyRateDelta: 0.0,
      fundedAmount: fundedAmount ?? 0,
      rateId: rateId ?? '',
      term: maxTerm,
    };
    return offerReq;
  };

  const initNewOfferRemittanceSchedule = (offerId?: string): OfferRemittanceSchedule =>
    ({
      offerRemittanceScheduleId: generateId(),
      offerId,
      remittanceAmountPaid: 0,
    }) as OfferRemittanceSchedule;

  const initNewPayoffAccount = (
    offer?: Offer,
    companyId?: string,
    accountType?: string
  ): OfferPayoffAccount => {
    const account = {
      ...bankingService.initNewBankingAccount(companyId, accountType),
      accountOpenedDate: null,
    } as unknown as Account;

    return {
      offerPayoffAccountId: '',
      offerId: offer?.offerId || '',
      accountId: '',
      companyBankStatementAdvanceId: '',
      account: account,
      amount: 0,
    };
  };

  const generateOffers = async (
    request: OffersGenerationRequest
  ): Promise<ApiResponse<Offer[]>> => {
    const createOffersResponse = await fmsServices().post<
      OffersGenerationRequest,
      ApiResponse<Offer[]>
    >('/offers/generate', request);
    return createOffersResponse;
  };

  const upsertOffer = async (offers: OffersUpsertRequest): Promise<ApiResponse<Offer[]>> => {
    const createOffersResponse = await fmsServices().post<OffersUpsertRequest, ApiResponse>(
      '/offers/upsert',
      offers
    );
    return createOffersResponse;
  };

  const removeOffer = async (offerId: string): Promise<ApiResponse> => {
    const removeOffersResponse = await fmsServices().post<{ offerId: string }, ApiResponse>(
      '/offers/remove',
      { offerId }
    );
    return removeOffersResponse;
  };

  const upsertPrepays = async (prepays: PrepaysUpsertRequest): Promise<ApiResponse> => {
    const createOffersResponse = await fmsServices().post<PrepaysUpsertRequest, ApiResponse>(
      '/offers/prepay/upsert',
      prepays
    );
    return createOffersResponse;
  };

  const getOfferById = async (offerId: string): Promise<ApiResponse<Offer>> => {
    const offerDetailsResponse = await fmsServices().post<
      OffersGenerationRequest,
      ApiResponse<Offer>
    >('/offers/details', { offerId } as any);
    return offerDetailsResponse;
  };

  const getOffers = async (request: OffersSearch): Promise<ApiResponsePaged<Offer[]>> => {
    const createOffersResponse = await fmsServices().post<OffersSearch, ApiResponsePaged<Offer[]>>(
      '/offers/search',
      request
    );
    return createOffersResponse;
  };

  const getOffersParticipationManager = async (
    request: OffersSearchParticipationManager
  ): Promise<ApiResponsePaged<Offer[]>> => {
    const createOffersResponse = await fmsServices().post<
      OffersSearchParticipationManager,
      ApiResponsePaged<Offer[]>
    >('/offers/search-participation-manager', request);
    return createOffersResponse;
  };

  const getOffersResponses = async (
    request: OfferResponsesSearch
  ): Promise<ApiResponsePaged<OfferResponse[]>> => {
    const fetchOffersResponse = await fmsServices().post<
      OfferResponsesSearch,
      ApiResponsePaged<OfferResponse[]>
    >('/offers/responses/search', request);
    return fetchOffersResponse;
  };

  const getOfferPayoffAccounts = async (
    request: OffersSearch
  ): Promise<ApiResponsePaged<OfferPayoffAccount[]>> => {
    const createOffersResponse = await fmsServices().post<
      OffersSearch,
      ApiResponsePaged<OfferPayoffAccount[]>
    >('/offers/payoffs/search', request);
    return createOffersResponse;
  };

  const upsertOfferPayoffAccount = async (account: OfferPayoffAccount) => {
    try {
      const response = await fmsServices().post<
        OfferPayoffAccount,
        ApiResponse<OfferPayoffAccount>
      >('/offers/payoffs/upsert', account);
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse<OfferPayoffAccount>;
    }
  };

  const removeOfferPayoffAccount = async (offerPayoffAccountId: string) => {
    try {
      const response = await fmsServices().post<{ offerPayoffAccountId: string }, ApiResponse>(
        '/offers/payoffs/remove',
        { offerPayoffAccountId: offerPayoffAccountId }
      );
      return response;
    } catch (error: any) {
      return { success: false, message: error.messages } as ApiResponse;
    }
  };

  const sendPreApproval = async (params: {
    applicationId: string;
    note?: string; // TODO: rename this param once the endpoint accepts notes.
  }): Promise<ApiResponse> => {
    const sendPreApprovalResponse = await fmsServices().post<OffersSearch, ApiResponse>(
      '/offers/preapproval',
      params
    );
    return sendPreApprovalResponse;
  };

  const sendApproval = async (params: { offerId: string }): Promise<ApiResponse> => {
    const sendPreApprovalResponse = await fmsServices().post<OffersSearch, ApiResponse>(
      '/offers/approval',
      params
    );
    return sendPreApprovalResponse;
  };

  const upsertPreApprovalNote = async (
    offerPreApprovalNoteUpsert: OfferPreApprovalNoteUpsert
  ): Promise<ApiResponse> => {
    const updatePreApprovalNoteResponse = await fmsServices().post<
      OfferPreApprovalNoteUpsert,
      ApiResponse
    >('/offers/preapproval-note/upsert', offerPreApprovalNoteUpsert);
    return updatePreApprovalNoteResponse;
  };

  const upsertApprovalNote = async (
    offerApprovalNoteUpsert: OfferApprovalNoteUpsert
  ): Promise<ApiResponse> => {
    const updateApprovalNoteResponse = await fmsServices().post<
      OfferApprovalNoteUpsert,
      ApiResponse
    >('/offers/approval-note/upsert', offerApprovalNoteUpsert);
    return updateApprovalNoteResponse;
  };

  const recalculatePrepaysData = <TPrepay extends OfferPrepay>(
    prepay: TPrepay,
    offer: Offer
  ): TPrepay => {
    const buyRateDelta = !isNaN(prepay.buyRateDelta) ? prepay.buyRateDelta : 0;
    const commissionDelta = !isNaN(prepay.commissionDelta) ? prepay.commissionDelta : 0;

    const fundedAmount = offer?.fundedAmount ?? 0;

    const sellRate = Number(
      Big(prepay.buyRate)
        .plus(buyRateDelta)
        .plus(prepay.commission)
        .plus(commissionDelta)
        .toFixed(3)
    );
    const totalRepayment = Number(Big(fundedAmount).times(sellRate).toFixed(2));
    // A point is the commission percentage that a partner is making on the deal that they are funding through libertas
    const points = Math.round((prepay.commission + commissionDelta) * 100);

    return {
      ...prepay,
      totalRepayment,
      points,
      sellRate,
    };
  };

  const updateOfferStatusResponse = async (request: OfferStatusResponseUpdateRequest) => {
    const updateResponse = await fmsServices().post<
      OfferStatusResponseUpdateRequest,
      ApiResponse<Offer>
    >('/offers/response', request);
    return updateResponse;
  };

  const searchOfferRemittanceSchedules = async (
    searchParams: OffersSearch
  ): Promise<ApiResponse<OfferRemittanceSchedule[]>> => {
    const searchRemittanceScheduleResponse = await fmsServices().post<
      OffersSearch,
      ApiResponse<OfferRemittanceSchedule[]>
    >('/offers/remittance/search', searchParams);
    return searchRemittanceScheduleResponse;
  };

  const generateRemittanceSchedule = async (
    offerId: string
  ): Promise<ApiResponse<VariablePayment[]>> => {
    const remittanceScheduleResponse = await fmsServices().post<
      any,
      ApiResponse<VariablePayment[]>
    >('/offers/remittance/generate', { offerId });
    return remittanceScheduleResponse;
  };

  const getVariablePaymentDateRange = async (
    startDate: string, // Date in YYYY-MM-DD format
    fromRemittanceNumber: number,
    toRemittanceNumber: number,
    remittanceFrequency: string
  ): Promise<ApiResponse<GetVariablePaymentDateRangeResponse>> => {
    const upsertVariablePaymentsResponse = await fmsServices().post<
      GetVariablePaymentDateRangeRequest,
      ApiResponse<GetVariablePaymentDateRangeResponse>
    >('/offers/remittance/daterange', {
      startDate,
      toRemittanceNumber,
      fromRemittanceNumber,
      remittanceFrequency,
    });
    return upsertVariablePaymentsResponse;
  };

  const calculateVariablePayments = async (
    offerId: string,
    variablePayments: OfferRemittanceSchedule[],
    startDate?: string // Date with format yyyy-MM-dd
  ): Promise<ApiResponse<VariablePayment[]>> => {
    const upsertVariablePaymentsResponse = await fmsServices().post<
      any,
      ApiResponse<VariablePayment[]>
    >('/offers/remittance/calculate', {
      offerId,
      offerRemittanceSchedules: variablePayments,
      startDate,
    });
    return upsertVariablePaymentsResponse;
  };

  const bulkUpsertVariablePayments = async (
    offerId: string,
    variablePayments: OfferRemittanceSchedule[]
  ): Promise<ApiResponse<OfferRemittanceSchedule[]>> => {
    const upsertVariablePaymentsResponse = await fmsServices().put<
      any,
      ApiResponse<OfferRemittanceSchedule[]>
    >('/offers/remittance/upsert/bulk', { offerId, offerRemittanceSchedules: variablePayments });
    return upsertVariablePaymentsResponse;
  };

  const searchOfferExceptions = async (
    options: OfferExceptionSearch
  ): Promise<ApiResponse<OfferException[]>> => {
    const searchOfferExceptionsResponse = await fmsServices().post<
      OfferExceptionSearch,
      ApiResponse<OfferException[]>
    >('/offers/offerexceptions/search', options);
    return searchOfferExceptionsResponse;
  };

  const upsertOfferExceptionsBulk = async (
    request: OfferExceptionsBulkUpsertRequest
  ): Promise<ApiResponse<OfferException[]>> => {
    const searchOfferExceptionsResponse = await fmsServices().put<
      OfferExceptionsBulkUpsertRequest,
      ApiResponse<OfferException[]>
    >('/offers/offerexceptions/upsert/bulk', request);
    return searchOfferExceptionsResponse;
  };

  const searchOfferExceptionReasons = async (
    options: OfferExceptionReasonSearch
  ): Promise<ApiResponse<OfferExceptionReason[]>> => {
    const searchOfferExceptionReasonsResponse = await fmsServices().post<
      OfferExceptionReasonSearch,
      ApiResponse<OfferExceptionReason[]>
    >('/offers/offerexceptionreasons/search', options);
    return searchOfferExceptionReasonsResponse;
  };

  const checkParticipationsExists = async (
    offerId: string
  ): Promise<ApiResponse<OfferException[]>> => {
    const response = await fmsServices().get<any, ApiResponse>(
      `/offers/check-participations-exists/${offerId}`
    );
    return response;
  };

  const checkContractsExists = async (offerId: string): Promise<ApiResponse<OfferException[]>> => {
    const response = await fmsServices().get<any, ApiResponse>(
      `/offers/check-contracts-exists/${offerId}`
    );
    return response;
  };

  const changeParticipationEligibility = async (
    offerId: string
  ): Promise<ApiResponse<OfferException[]>> => {
    const response = await fmsServices().put<any, ApiResponse>(
      `/offers/change-participation-eligibility/${offerId}`
    );
    return response;
  };

  const getOfferStatuses = async (): Promise<ApiResponse<OfferStatus[]>> => {
    const response = await fmsServices().get<null, ApiResponse<OfferStatus[]>>('/offers/statuses');

    return response;
  };

  const getRemittanceAmountByPaymentFrequency = (offer: Offer) => {
    switch (offer.remittanceFrequency) {
      case RemittanceFrequency.Monthly:
        return offer.monthly;
      case RemittanceFrequency.BiWeekly:
        return offer.biWeekly;
      case RemittanceFrequency.Weekly:
        return offer.weekly;
      case RemittanceFrequency.Daily:
      default:
        return offer.daily;
    }
  };

  return {
    initNewOfferRequest,
    initNewOfferRequestFromApplication,
    initNewOfferRemittanceSchedule,
    initNewPayoffAccount,
    generateOffers,
    upsertOffer,
    removeOffer,
    getOfferById,
    getOffers,
    getOffersParticipationManager,
    getOffersResponses,
    getOfferPayoffAccounts,
    upsertOfferPayoffAccount,
    removeOfferPayoffAccount,
    upsertPrepays,
    recalculatePrepaysData,
    sendPreApproval,
    sendApproval,
    upsertPreApprovalNote,
    upsertApprovalNote,
    updateOfferStatusResponse,
    searchOfferRemittanceSchedules,
    generateRemittanceSchedule,
    getVariablePaymentDateRange,
    calculateVariablePayments,
    bulkUpsertVariablePayments,
    searchOfferExceptions,
    upsertOfferExceptionsBulk,
    searchOfferExceptionReasons,
    checkParticipationsExists,
    checkContractsExists,
    changeParticipationEligibility,
    getOfferStatuses,
    getRemittanceAmountByPaymentFrequency,
  };
};

export default OffersService;
