import CompanyBankStatementTransactionType from 'models/Banking/CompanyBankStatementTransactionType';

export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_CREDIT =
  'f40f3bce-076a-43ed-ada7-0c4e02062c7f';
export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_DEBIT =
  '90f585ae-9464-4f80-b62f-1a27418de840';
export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_ADVANCE =
  '56f60446-4d4e-4cdb-925c-2d2edfb39258';
export const COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_TRADITIONAL_LIABILITY =
  'd652c932-6509-4ce7-9b26-fcf4406fe371';

export const companyBankStatementTransactionTypes: CompanyBankStatementTransactionType[] = [
  {
    bankStatementTransactionTypeId: COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_CREDIT,
    transactionTypeName: 'Credit',
    transactionTypeDescription: 'Deposit into Account',
  },
  {
    bankStatementTransactionTypeId: COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_DEBIT,
    transactionTypeName: 'Debit',
    transactionTypeDescription: 'Withdraw from Account',
  },
  {
    bankStatementTransactionTypeId: COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_ADVANCE,
    transactionTypeName: 'Advance',
    transactionTypeDescription: 'Advance',
  },
  {
    bankStatementTransactionTypeId:
      COMPANY_BANK_STATEMENT_TRANSACTION_TYPE_ID_TRADITIONAL_LIABILITY,
    transactionTypeName: 'Traditional Liability',
    transactionTypeDescription: 'Traditional Liability',
  },
];
