import fmsServices from 'services/fmsServices';
// models
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import ExpectedPaymentReason from 'models/ExpectedPayment/ExpectedPaymentReason';
import ExpectedPayments from 'models/ExpectedPayment/ExpectedPayments';
import ExpectedPaymentSearch from 'models/ExpectedPayment/ExpectedPaymentSearch';

export const INCOMING_CASH_FOR_FUNDED_DEAL_FROM_MERCHANT_OTHER_ID =
  '8b5b3058-7120-2149-e6cc-78fa6b63087f';

export const INCOMING_CASH_FOR_PARTICIPATION_ID = 'fec14c2b-bacb-8c85-c237-dab8a8ff6fe9';

export const INCOMING_CASH_FOR_COMMISSION_CLAWBACK_ID = 'a14d204b-1c8e-0635-8771-b362fb5520bc';

export const CASH_FROM_SECURITIZATION = 'f15d3b58-58ed-4bda-3bf9-0ab0240f69e2';

export const CASH_FROM_SVB = '298c4aca-7f91-8bc0-77b8-3f49d12402a3';

export const INCOMING_FUNDS_THAT_ARE_NOT_FMS_RELATED = '90f98b97-201d-3094-6357-effbd2f25aa9';

export const expectedPaymentReasonRequiresAssociatedFunding = (
  expectedPaymentReasonId?: string
) => {
  if (!expectedPaymentReasonId) return false;

  return ![
    CASH_FROM_SECURITIZATION,
    CASH_FROM_SVB,
    INCOMING_FUNDS_THAT_ARE_NOT_FMS_RELATED,
  ].includes(expectedPaymentReasonId);
};

const ExpectedPaymentsService = () => {
  const searchReasons = async (): Promise<ApiResponse<ExpectedPaymentReason[]>> => {
    const response = await fmsServices().get<null, ApiResponse<ExpectedPaymentReason[]>>(
      '/expected-payments/search/reasons'
    );

    return response;
  };

  const search = async (
    searchParams: ExpectedPaymentSearch
  ): Promise<ApiResponsePaged<ExpectedPayments[]>> => {
    const response = await fmsServices().post<
      ExpectedPaymentSearch,
      ApiResponsePaged<ExpectedPayments[]>
    >('/expected-payments/search', searchParams);

    return response;
  };

  const upsert = async (expectedPayment: ExpectedPayments) => {
    const response = await fmsServices().post<ExpectedPayments, ApiResponse>(
      '/expected-payments/upsert',
      expectedPayment
    );

    return response;
  };

  const remove = async (id: string) => {
    const response = await fmsServices().remove(`/expected-payments/delete/${id}`);

    return response;
  };

  return {
    searchReasons,
    search,
    upsert,
    remove,
  };
};

export default ExpectedPaymentsService;
