import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import { enUS } from 'date-fns/locale';
import * as yup from 'yup';
import {
  Autocomplete,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Alert,
} from '@mui/material';
import Button from '@mui/material/Button';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
// components
import PageTitle from 'components/Shared/PageTitle';
// services
import JournalService from 'services/JournalService';
import FundingService from 'services/FundingService';
// models
import Journal, { defaultJournal } from 'models/Accounting/Journal';
import Funding from 'models/Funding/Funding';
// hooks and utils
import { useAccountingData } from 'hooks/useAccountingData';
import { capitalize } from 'utils/capitalize';
import formatDateTimeWithoutTimezone from 'utils/date/formatDateTimeWithoutTimezone';

const journalValidationSchema = yup.object({
  journalTypeId: yup.string().required(),
  journalGroupId: yup.string().required(),
  fundingId: yup.string().required('Journals must be associated to a Funding'),
  identifier: yup.string().required(), //funding.identifier
  transactionId: yup.string().required('Journals must be associated to a Transaction'),
  modelId: yup.string().required(),
  creditOrDebit: yup.string().required(),
  eedDate: yup.date().required('Entry Effective Date is required'),
  notes: yup.string().required('A note is required'),
});

const JournalEditor = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [journal, setJournal] = useState<Journal | undefined>();
  const [fundingSearchResults, setFundingSearchResults] = useState<Funding[]>([]);
  const { journalTypes } = useAccountingData();

  const journalService = useMemo(JournalService, []);
  const fundingService = useMemo(FundingService, []);

  const initialValues = useMemo(() => {
    let values = {
      ...defaultJournal,
      transactionId: searchParams.get('transactionId') ?? '',
      journalId: searchParams.get('journalId') ?? '',
      fundingId: searchParams.get('fundingId') ?? '',
      identifier: searchParams.get('fundingIdentifier') ?? '',
      funding: {
        fundingId: searchParams.get('fundingId') ?? '',
        identifier: searchParams.get('fundingIdentifier') ?? '',
      },
    };

    if (journal) {
      // @ts-ignore
      values = {
        ...values,
        ...journal,
      };
    }

    return values;
  }, [searchParams, journal]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: journalValidationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = formik;

  const getFundingSearchResponse = useCallback(
    async ({
      offerIdentifier,
      fundingIdentifier,
      fundingId,
    }: {
      offerIdentifier?: string;
      fundingIdentifier?: string;
      fundingId?: string;
    }) => {
      const fundingSearchResponse = await fundingService.searchFunding({
        offerIdentifier,
        identifier: fundingIdentifier,
        pageNumber: 1,
        pageSize: 10,
      });
      if (fundingSearchResponse?.data?.length) {
        setFundingSearchResults(fundingSearchResponse.data);

        if (fundingSearchResponse.data.length === 1) {
          const [funding] = fundingSearchResponse.data;

          setFieldValue('fundingId', funding?.fundingId);
          setFieldValue('identifier', funding?.identifier);
        }
      }
    },
    [fundingService, setFieldValue]
  );

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const updatedJournal = {
          ...formData,
          signedAmount:
            formData.creditOrDebit === 'credit' && formData.absoluteAmount > 0
              ? formData.absoluteAmount * -1
              : formData.absoluteAmount,
          transactionId: formData?.transactionId,
          journalMonth: formData?.eedDate ? new Date(formData.eedDate).getMonth() : undefined,
          journalYear: formData?.eedDate ? new Date(formData.eedDate).getFullYear() : undefined,
        };

        const upsertResponse = await journalService.upsert(updatedJournal);
        if (upsertResponse.success) {
          navigate(-1);
          enqueueSnackbar('Journal Saved Successfully', { variant: 'success' });
        } else {
          let errorMessage = `Error saving journals. ${upsertResponse.message}`;
          if (upsertResponse.errors) {
            errorMessage = upsertResponse.message + `. ${JSON.stringify(upsertResponse.errors)}`;
          }
          enqueueSnackbar(errorMessage, { variant: 'error', persist: true });
        }
      } catch (error: any) {
        enqueueSnackbar(`Error saving journals. ${error.message}`, {
          variant: 'error',
          persist: true,
        });
      }
    },
    [enqueueSnackbar, navigate, journalService]
  );

  useEffect(() => {
    if (!initialValues.identifier) {
      return;
    }

    getFundingSearchResponse({ fundingIdentifier: initialValues.identifier });
  }, [initialValues.identifier, getFundingSearchResponse]);

  useEffect(() => {
    const journalId = searchParams.get('journalId') ?? '';
    const offerIdentifier = searchParams.get('offerIdentifier') ?? '';
    const fundingIdentifier = searchParams.get('fundingIdentifier') ?? '';
    const fundingId = searchParams.get('fundingId') ?? '';

    if (journalId) {
      journalService.search({ journalId }).then((result) => {
        if (result.success && result.data && result.data.length) {
          setJournal(result.data[0]);
        }
      });
    }

    if (fundingId) {
      // Search by fundingId take precedence
      getFundingSearchResponse({ fundingId });
    } else {
      if (fundingIdentifier || offerIdentifier) {
        getFundingSearchResponse({ fundingIdentifier, offerIdentifier });
      }
    }
  }, [journalService, searchParams, getFundingSearchResponse, setJournal]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Container maxWidth={false}>
            <PageTitle title="Journal Editor" />
            <Grid container spacing={2} my={1}>
              <Grid item xs={6}>
                <Alert severity="info">
                  Use this editor with great care. It is the responsibility of the user to ensure
                  proper Journals are created.
                </Alert>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <FormControl variant="standard" required fullWidth>
                  <Autocomplete
                    disablePortal
                    autoSelect
                    id="identifier"
                    options={fundingSearchResults}
                    getOptionLabel={(funding) => `${funding?.identifier}`}
                    // @ts-ignore
                    value={values.funding}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Funding Identifier"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          getFundingSearchResponse({ fundingIdentifier: e.target.value })
                        }
                      />
                    )}
                    onChange={(_, value: Funding | null) =>
                      setFieldValue('identifier', value?.identifier)
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.identifier === value?.identifier
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="EED Date"
                    format="MM/dd/yyyy"
                    value={
                      values.eedDate
                        ? new Date(formatDateTimeWithoutTimezone(values.eedDate))
                        : undefined
                    }
                    onChange={(value) => {
                      setFieldValue('eedDate', value, true);
                    }}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        id: 'eedDate',
                        name: 'eedDate',
                        onBlur: handleBlur,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  required
                  id="absoluteAmount"
                  name="absoluteAmount"
                  label="Absolute Amount"
                  variant="standard"
                  value={values.absoluteAmount}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={touched.absoluteAmount && Boolean(errors.absoluteAmount)}
                  helperText={touched.absoluteAmount && errors.absoluteAmount}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-direction-group-label" shrink={true} required={true}>
                    Direction
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    name="creditOrDebit"
                    variant="standard"
                    id="select-journal-action-group"
                    label="Direction"
                    value={values.creditOrDebit}
                    onChange={handleChange}
                  >
                    <MenuItem value={''} key={'empty-option'}>
                      <em>&nbsp;</em>
                    </MenuItem>
                    {['credit', 'debit'].map((direction) => (
                      <MenuItem key={direction} value={direction}>
                        {capitalize(direction)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-journalTypeId-label" shrink={true} required={true}>
                    Journal Type
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    name="journalTypeId"
                    variant="standard"
                    id="select-journalTypeId-label"
                    label="Journal Type"
                    value={values.journalTypeId ?? ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={''} key={'empty-option'}>
                      <em>&nbsp;</em>
                    </MenuItem>
                    {journalTypes.map(({ journalTypeId, journalTypeName }) => (
                      <MenuItem key={journalTypeId} value={journalTypeId}>
                        {journalTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  id="journalGroupId"
                  name="journalGroupId"
                  value={values.journalGroupId ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Group Id"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={touched.journalGroupId && Boolean(errors.journalGroupId)}
                  helperText={touched.journalGroupId && errors.journalGroupId}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="transactionId"
                  name="transactionId"
                  value={values.transactionId ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Transaction Id"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={touched.transactionId && Boolean(errors.transactionId)}
                  helperText={touched.transactionId && errors.transactionId}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={true}
                  id="notes"
                  name="notes"
                  value={values.notes ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Notes"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={touched.notes && Boolean(errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={values.description ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Description"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6} display="flex" justifyContent="right">
                <Button type="submit" variant="contained" size={'small'} disabled={!isValid}>
                  Save
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  size={'small'}
                  type="reset"
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant={'text'}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormikProvider>
    </LocalizationProvider>
  );
};

export default React.memo(JournalEditor);
