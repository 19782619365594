import { createTheme } from '@mui/material/styles';
import { appBrand, appSecondary, appGrey, semanticColors } from 'styling/colors';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { PaletteMode } from '@mui/material';

const DEFAULT_THEME_SETTING = 'light';

export const appPrimaryColor = appBrand;
export const appPrimaryLight = appPrimaryColor[600];
export const appPrimaryDark = appPrimaryColor[800];
export const appPrimaryDarkest = appPrimaryColor[900];
export const appPrimaryVeryLight = appPrimaryColor[50];
export const appVeryLightestGrey = appGrey[50];
export const appLightestGrey = appGrey[100];
export const appLighterGrey = appGrey[400];
export const appLightGrey = appGrey[600];
export const appMedGrey = appGrey[800];
export const appDarkestGrey = appGrey[900];

export const getThemeSetting = () => DEFAULT_THEME_SETTING;

export const getPrimaryColor = () => appPrimaryDark;

export const getActivePrimaryColor = () => appPrimaryDark;

export const getActivePrimaryColorText = () => appLightGrey;

export const theme = (mode: PaletteMode = DEFAULT_THEME_SETTING) =>
  createTheme({
    typography: {
      fontFamily: "'Public Sans', sans-serif",
      fontSize: 13,
    },
    palette: {
      mode: mode,
      primary: appPrimaryColor,
      secondary: appSecondary,
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h2' } /* component props */,
            style: {
              color: appPrimaryDark,
            },
          },
          {
            props: { variant: 'subtitle2' } /* component props */,
            style: {
              color: appPrimaryDarkest,
              marginTop: 1,
              fontSize: 16,
            },
          },
        ],
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              // @ts-ignore
              // color: DEFAULT_THEME_SETTING === 'light' ? appMedGrey : appLightestGrey,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              // @ts-ignore
              color: mode === 'light' ? appMedGrey : appLightestGrey,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&.Mui-disabled': {
              // @ts-ignore
              color: mode === 'light' ? appMedGrey : appLightestGrey,
              // @ts-ignore
              WebkitTextFillColor: mode === 'light' ? appMedGrey : appLightestGrey,
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              borderColor: appPrimaryDark,
              backgroundColor: 'transparent',
              // @ts-ignore
              color: mode === 'light' ? appPrimaryDark : appLightestGrey,
              '&:hover': {
                borderColor: appPrimaryDarkest,
                color: appLightestGrey,
                backgroundColor: appPrimaryDark,
              },
              '&:focus': {
                borderColor: appPrimaryDarkest,
                color: appLightestGrey,
              },
            },
          },
          {
            props: { variant: 'text', color: 'primary' },
            style: {
              // @ts-ignore
              color: mode === 'light' ? appPrimaryDark : appLightestGrey,
              '&:hover': {
                color: appMedGrey,
                backgroundColor: appVeryLightestGrey,
              },
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              borderColor: 'transparent',
              backgroundColor: appPrimaryDark,
              color: appLightestGrey,
              '&:hover': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
              '&:focus': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
            },
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: {
              borderColor: 'transparent',
              backgroundColor: appPrimaryVeryLight,
              color: appPrimaryDark,
            },
          },
          {
            props: { variant: 'outlined', color: 'secondary' },
            style: {
              borderColor: appPrimaryDark,
              color: appPrimaryDark,
            },
          },
        ],
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            color: appPrimaryDark,
            borderRadius: 20,
            fontSize: '1em',
            lineHeight: '2em',
            '&:hover': {
              backgroundColor: appPrimaryDark,
              color: appLightestGrey,
            },
            '&:focus': {
              backgroundColor: appPrimaryDark,
              color: appLightestGrey,
            },
            textPrimary: {
              '&:hover': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
              '&:focus': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
            },
            textSecondary: {
              '&:hover': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
              '&:focus': {
                backgroundColor: appPrimaryDark,
                color: appLightestGrey,
              },
            },
          },
        },
      },
      // MuiListItemButton: {
      //   styleOverrides: {
      //     root: {
      //       color: appDarkestGrey,
      //     },
      //   },
      // },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: 'none',
            backgroundImage: 'none',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 10,
            paddingTop: 10,
            paddingRight: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            '&:last-child': {
              paddingBottom: 10,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          avatar: {
            marginLeft: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: appPrimaryDark,
            },
          },
          wrapped: {
            flexDirection: 'row',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '3px',
            '&:before': {
              height: 0,
            },
          },
          rounded: {
            borderRadiusBottomLeft: 0,
            borderRadiusBottomRight: 0,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
          },
        },
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: 'labeled' } as any,
            style: {
              textTransform: 'none',
              border: `2px dashed blue`,
            },
          },
        ],
      },
      MuiSwitch: {
        styleOverrides: {
          colorPrimary: {
            '&.Mui-checked': {
              color: appBrand[800],
            },
          },
          track: {
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.4,
              backgroundColor: appBrand[800],
            },
          },
        },
      },
      MuiDesktopDatePicker: {
        defaultProps: {
          slotProps: {
            openPickerButton: { style: { padding: 8, borderRadius: 50 } },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            color: mode === 'dark' ? '#fff' : undefined,
            backgroundColor: mode === 'dark' ? semanticColors.info['800'] : undefined,
          },
          standardWarning: {
            color: mode === 'dark' ? '#fff' : undefined,
            backgroundColor: mode === 'dark' ? semanticColors.warn['800'] : undefined,
          },
          standardError: {
            color: mode === 'dark' ? '#fff' : undefined,
            backgroundColor: mode === 'dark' ? semanticColors.alert['800'] : undefined,
          },
          standardSuccess: {
            color: mode === 'dark' ? '#fff' : undefined,
            backgroundColor: mode === 'dark' ? semanticColors.success['800'] : undefined,
          },
        },
      },
    },
  });

export const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: 0,
    zIndex: 1,
    flexGrow: 1,
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    padding: 0,
    height: 'calc(100%)',
    marginTop: 0,
  },
  typography: {
    button: {
      'border-radius': 2,
    },
  },
});
