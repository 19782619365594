// services
import fmsServices from 'services/fmsServices';
import { PHYSICAL_ADDRESS_TYPE_ID } from 'services/AddressesService';
// models
import Person from 'models/Person/Person';
import PersonSearch from 'models/Person/PersonsSearch';
import ApiResponse from 'models/API/ApiResponse';
import ApiResponsePaged from 'models/API/ApiResponsePaged';
import PersonCompanyItem from 'models/Person/PersonCompanyItem';
import PersonAddress from 'models/Person/PersonAddress';
import PersonAddressesSearch from 'models/Person/PersonAddressesSearch';
import PersonCompaniesSearch from 'models/Person/PersonsCompaniesSearch';

export const contact = 'contact';
export const owner = 'owner';
export const nonOwner = 'non-owner';

/** 
This service provides data for "Persons" which is a core entity 
within the system that is associated to a company and the combination of 
Owners, Gaurantors, and Obligors.
*/
const PersonsService = () => {
  const getPersonTypeTitle = (personType: string) => {
    switch (personType) {
      case owner:
        return 'Owner';
      case nonOwner:
        return 'Non-Owner';
      default:
        return '';
    }
  };

  const initNewPerson = () => {
    const personObject = {
      firstName: '',
      lastName: '',
      SSN: '',
      phoneNumber: '',
      doNotContact: false,
      addresses: [
        {
          addressTypeId: PHYSICAL_ADDRESS_TYPE_ID,
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          modelType: '',
          modelId: '',
        },
      ],
    } as unknown as Person;
    return personObject;
  };

  const initPersonCompanyItem = (companyId: string, personType: string = 'owner') => {
    const initialIsAuthorizedSigner = personType === owner;
    const initialIsPerformanceGuarantor = personType === owner;

    const newPersonCompanyItem: PersonCompanyItem = {
      companyId,
      person: initNewPerson(),
      personType: personType,
      percentOwnership: 0,
      isPrimaryContact: true,
      includeOnContract: false,
      isAuthorizedSigner: initialIsAuthorizedSigner,
      isPerformanceGuarantor: initialIsPerformanceGuarantor,
      isControlPerson: false,
      isNonBLSASigner: false,
    };
    return newPersonCompanyItem;
  };

  const initCompanyPersonsItemList = (companyId: string) => {
    const initOwner = initPersonCompanyItem(companyId);
    return [{ ...initOwner }];
  };

  const upsertPersonCompanies = async (
    companyId: string,
    personCompanyItems: PersonCompanyItem[]
  ): Promise<ApiResponse> => {
    const upsertPersonCompanyItemsResponse = await fmsServices().post<any, ApiResponse>(
      '/persons/company/upsert/bulk',
      { companyId, personCompanyItems: personCompanyItems }
    );
    return upsertPersonCompanyItemsResponse;
  };

  const upsertPersonCompanyItem = async (
    companyId: string,
    personCompany: PersonCompanyItem
  ): Promise<ApiResponse<ApiResponse<Person>[]>> => {
    const upsertPersonCompanyItemsResponse = await fmsServices().post<
      any,
      ApiResponse<ApiResponse<Person>[]>
    >('/persons/company/upsert/item', { ...personCompany, companyId });
    return upsertPersonCompanyItemsResponse;
  };

  const removePersonCompanies = async (
    companyId: string,
    personId: string
  ): Promise<ApiResponse> => {
    const upsertPersonCompanyItemsResponse = await fmsServices().post<any, ApiResponse>(
      '/persons/company/remove',
      { companyId, personId: personId }
    );
    return upsertPersonCompanyItemsResponse;
  };

  const upsertPerson = async (person: Person): Promise<ApiResponse> => {
    if (person.driversLicenseNumber === null) {
      delete person.driversLicenseNumber;
    }
    if (person.driversLicenseState === null) {
      delete person.driversLicenseState;
    }
    if (person.driversLicenseExpirationDate === null) {
      delete person.driversLicenseExpirationDate;
    }
    const upsertPersonResponse = await fmsServices().post<Person, ApiResponse>(
      '/persons/upsert',
      person
    );
    return upsertPersonResponse;
  };

  const searchPersons = async (personsSearch: PersonSearch): Promise<ApiResponsePaged> => {
    const searchResponsePersons = await fmsServices().post<PersonSearch, ApiResponsePaged>(
      '/persons/search',
      personsSearch
    );
    return searchResponsePersons;
  };

  const searchPersonCompanies = async (
    searchRequest: PersonCompaniesSearch
  ): Promise<ApiResponse<PersonCompanyItem[]>> => {
    const searchPersonCompaniesResponse = await fmsServices().post<
      PersonCompaniesSearch,
      ApiResponse<PersonCompanyItem[]>
    >('/persons/company/search', searchRequest);
    return searchPersonCompaniesResponse;
  };

  const upsertPersonAddress = async (personAddress: PersonAddress): Promise<ApiResponse> => {
    const upsertAddressResponse = await fmsServices().post<PersonAddress, ApiResponse>(
      '/persons/addresses/upsert',
      personAddress
    );
    return upsertAddressResponse;
  };

  const searchPersonAddresses = async (
    searchRequest: PersonAddressesSearch
  ): Promise<ApiResponsePaged> => {
    const searchAddressResponse = await fmsServices().post<PersonAddressesSearch, ApiResponsePaged>(
      '/persons/addresses/search',
      searchRequest
    );
    return searchAddressResponse;
  };

  const getCompanyPersonsDetails = async (
    companyId: string
  ): Promise<ApiResponsePaged<PersonCompanyItem[]>> => {
    const getCompanyPersonsDetails = await fmsServices().post<
      any,
      ApiResponsePaged<PersonCompanyItem[]>
    >('/persons/company/details', { companyId });
    return getCompanyPersonsDetails;
  };

  return {
    initNewPerson,
    initPersonCompanyItem,
    initCompanyPersonsItemList,
    upsertPersonCompanies,
    upsertPersonCompanyItem,
    removePersonCompanies,
    upsertPerson,
    searchPersons,
    searchPersonCompanies,
    upsertPersonAddress,
    searchPersonAddresses,
    getCompanyPersonsDetails,
    getPersonTypeTitle,
  };
};

export default PersonsService;
