export const DILIGENCE_PROFILE_LIBERTAS_ID = 'b1ca3b88-50fb-47a6-1deb-ecac38086ec3';
export const DILIGENCE_PROFILE_LIGHTSPEED_ID = '0e3a66e8-4978-08c9-d95c-c81bfd64115f';

export default interface DiligenceProfile {
  diligenceProfileId: string;
  diligenceProfileName: string;
  diligenceProfileCode: string;
  createdBy?: string;
  createdByName?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedByName?: string;
  updatedAt?: Date;
  deletedAt?: Date;
}

export const defaultDiligenceProfile = {
  diligenceProfileId: '',
  diligenceProfileName: '',
  diligenceProfileCode: '',
};
