import React from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useMuiThemeToggle from 'hooks/useMuiThemeToggle';

const ThemeModeToggle = () => {
  const muiThemeToggle = useMuiThemeToggle();
  const theme = useTheme();

  return (
    <IconButton
      onClick={() => {
        muiThemeToggle.toggleTheme();
      }}
    >
      {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default React.memo(ThemeModeToggle);
