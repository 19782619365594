import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
// models
import ApplicationsContextType from 'models/Applications/ApplicationsContextType';
import Application from 'models/Application/Application';
import ApplicationStatusSummary from 'models/Applications/ApplicationStatusSummary';
import ApplicationSearch from 'models/Application/ApplicationSearch';
import BroadcastNotificationEvent from 'models/Shared/BroadcastNotificationEvent';
// services
import ApplicationsService, {
  PRE_SUBMISSION_APPLICATION_STATUS_ID,
} from 'services/ApplicationsService';
import BroadcastNotificationService from 'services/BroadcastNotificationService';
// config
import { SMALL_PAGE_SIZE } from 'config';
// models
import ApplicationEventEnum from 'models/Applications/ApplicationEventEnum';

export const ApplicationsContext = React.createContext<ApplicationsContextType | null>(null);

const initialSearchFilters = {
  searchFacet: 'companyName',
  statusId: PRE_SUBMISSION_APPLICATION_STATUS_ID,
  allPending: undefined,
  partnerId: undefined,
  partnerManagerUserId: undefined,
  funderId: undefined,
  fromDate: null,
  toDate: null,
  priority: '',
  productId: '',
  assignedTo: undefined,
  searchTerm: undefined,
  pageNumber: 1,
  pageSize: SMALL_PAGE_SIZE,
};

export const ApplicationsProvider = ({ children }: { children: ReactNode }) => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [applicationDuplicatesCount, setApplicationDuplicatesCount] = useState<
    { applicationId: string; count: string }[]
  >([]);
  const [applicationStatusSummary, setApplicationStatusSummary] = useState<
    ApplicationStatusSummary[]
  >([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<ApplicationSearch>(initialSearchFilters);

  const applicationsService = useMemo(ApplicationsService, []);
  const broadcastNotificationService = useMemo(BroadcastNotificationService, []);

  const searchApplications = useCallback(
    async (searchCriteria: ApplicationSearch) => {
      const applicationSearch: ApplicationSearch = {
        searchTerm: searchCriteria.searchTerm ? searchCriteria.searchTerm : undefined,
        identifier: searchCriteria.identifier ? searchCriteria.identifier : undefined,
        channelId: searchCriteria.channelId ? searchCriteria.channelId : undefined,
        companyId: searchCriteria.companyId ? searchCriteria.companyId : undefined,
        companyName: searchCriteria.companyName ? searchCriteria.companyName : undefined,
        ownerName: searchCriteria.ownerName ? searchCriteria.ownerName : undefined,
        statusId: searchCriteria.statusId ? searchCriteria.statusId : undefined,
        allPending: searchCriteria.allPending ? searchCriteria.allPending : undefined,
        partnerId: searchCriteria.partnerId ? searchCriteria.partnerId : undefined,
        partnerManagerUserId: searchCriteria.partnerManagerUserId
          ? searchCriteria.partnerManagerUserId
          : undefined,
        partnerName: searchCriteria.partnerName ? searchCriteria.partnerName : undefined,
        funderId: searchCriteria.funderId ? searchCriteria.funderId : undefined,
        funderName: searchCriteria.funderName ? searchCriteria.funderName : undefined,
        fundingIdentifier: searchCriteria.fundingIdentifier
          ? searchCriteria.fundingIdentifier
          : undefined,
        productId: searchCriteria.productId ? searchCriteria.productId : undefined,
        priority: searchCriteria.priority ? searchCriteria.priority : undefined,
        assignedTo: searchCriteria.assignedTo ? searchCriteria.assignedTo : undefined,
        pageNumber: searchCriteria.pageNumber,
        pageSize: searchCriteria.pageSize,
        fromDate: searchCriteria.fromDate ? searchCriteria.fromDate : undefined,
        toDate: searchCriteria.toDate ? searchCriteria.toDate : undefined,
        applicationType: searchCriteria.applicationType
          ? searchCriteria.applicationType
          : undefined,
      };

      if (Object.values(searchCriteria)?.filter((criteria) => criteria)?.length > 2) {
        // has more filters than pageNumber and pageSize
        return await applicationsService.searchApplications(applicationSearch);
      }
      return {
        data: [],
        total: 0,
      };
    },
    [applicationsService]
  );

  const fetchApplications = useCallback(
    async (searchCriteria = filters) => {
      try {
        // get applications
        const searchResultsRequest = await searchApplications(searchCriteria);
        const applicationData = searchResultsRequest.data;
        setTotalRows(searchResultsRequest.total);
        setApplications(applicationData ?? []);
        // get duplicates
        if (!applicationData) return;
        const applicationIds: string[] = applicationData.map((item) => item.applicationId);
        const duplicatesCountResponse =
          await applicationsService.fetchDuplicatesCountByApplicationIds(applicationIds);
        setApplicationDuplicatesCount(duplicatesCountResponse.data);
      } catch (error) {
        console.error(error);
      }
    },
    [setTotalRows, setApplications, searchApplications, filters, applicationsService]
  );

  const fetchStatusSummary = useCallback(async () => {
    const applicationStatusSummaryResponse =
      await applicationsService.getApplicationStatusSummary();

    const statusSummary = applicationStatusSummaryResponse.data ?? [];

    setApplicationStatusSummary(statusSummary);
    return { statusSummary };
  }, [applicationsService]);

  const reloadApplications = useCallback(
    (searchCriteria?: ApplicationSearch) => {
      fetchApplications(searchCriteria);
      fetchStatusSummary();
    },
    [fetchApplications, fetchStatusSummary]
  );

  useEffect(() => {
    reloadApplications();
  }, [reloadApplications]);

  useEffect(() => {
    const events = [
      ApplicationEventEnum.ApplicationCreated,
      ApplicationEventEnum.ApplicationRejectReasonUpserted,
      ApplicationEventEnum.ApplicationTeamMemberUpserted,
      ApplicationEventEnum.ApplicationTeamMemberRemoved,
      ApplicationEventEnum.ApplicationTeamAutoAssigned,
      ApplicationEventEnum.ApplicationTeamMemberCreated,
      ApplicationEventEnum.ApplicationTeamMemberUpdated,
      ApplicationEventEnum.ApplicationUpdated,
      ApplicationEventEnum.ApplicationStatusUpdated,
      ApplicationEventEnum.ApplicationAdjustedCreditTierIdUpdated,
      ApplicationEventEnum.ApplicationSubStatusUpdated,
      ApplicationEventEnum.ApplicationFunderUpdated,
      ApplicationEventEnum.ApplicationApprovalUpserted,
      ApplicationEventEnum.ApplicationAccountingMethodUpdated,
    ];

    function handleApplicationEvents(e: BroadcastNotificationEvent) {
      reloadApplications();
    }

    broadcastNotificationService.subscribeMany(events, handleApplicationEvents);

    return () => {
      broadcastNotificationService.unsubscribeMany(events, handleApplicationEvents);
    };
  }, [broadcastNotificationService, reloadApplications]);

  const contextValues = useMemo(
    () => ({
      applicationsFilters: filters,
      applications,
      applicationDuplicatesCount,
      totalApplications: totalRows,
      applicationStatusSummary,
      reloadApplications,
      setApplicationsFilters: setFilters,
    }),
    [
      filters,
      applications,
      applicationDuplicatesCount,
      totalRows,
      applicationStatusSummary,
      reloadApplications,
    ]
  );

  return (
    <ApplicationsContext.Provider value={contextValues}>{children}</ApplicationsContext.Provider>
  );
};
